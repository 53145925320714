import { ActionType, createAction } from "typesafe-actions";
import { LoanApplicationRequest } from "@opr-finance/api-definitions";
import {
    E_EnableBankingActionConstants,
    T_EnableBankingInitializerPayload,
    T_EnableBankingBank,
    T_EnableBankingRequest,
} from "../";
import { T_EarlyReturnFromFrends } from "../types/types";

export const enableBankingActions = {
    enableBankingInitializer: createAction(
        E_EnableBankingActionConstants.ENABLE_BANKING_INITIALIZER
    )<T_EnableBankingInitializerPayload>(),
    enableBankingGetBanksTrigger: createAction(
        E_EnableBankingActionConstants.ENABLE_BANKING_GET_BANKS_TRIGGER
    )(),
    enableBankingGetBanksSuccess: createAction(
        E_EnableBankingActionConstants.ENABLE_BANKING_GET_BANKS_SUCCESS
    )<T_EnableBankingBank[]>(),
    enableBankingGetBanksError: createAction(
        E_EnableBankingActionConstants.ENABLE_BANKING_GET_BANKS_ERROR
    )(),
    sendDataToEnableBankingTrigger: createAction(
        E_EnableBankingActionConstants.SEND_DATA_TO_ENABLE_BANKING_TRIGGER
    )<{ applicationData: LoanApplicationRequest; bankName: string; bankCountry: string }>(),
    sendDataToEnableBankingSuccess: createAction(
        E_EnableBankingActionConstants.SEND_DATA_TO_ENABLE_BANKING_SUCCESS
    )<T_EnableBankingRequest>(),
    sendDataToEnableBankingError: createAction(
        E_EnableBankingActionConstants.SEND_DATA_TO_ENABLE_BANKING_ERROR
    )<T_EnableBankingRequest>(),
    sendCodeToFrendsTrigger: createAction(
        E_EnableBankingActionConstants.SEND_CODE_TO_FRENDS_TRIGGER
    )<{ code: string }>(),
    sendApplicationCompleted: createAction(
        E_EnableBankingActionConstants.SEND_APPLICATION_COMPLETED
    )(),
    earlyReturnFromFrendsTrigger: createAction(
        E_EnableBankingActionConstants.EARLY_RETURN_FROM_FRENDS_TRIGGER
    )<T_EarlyReturnFromFrends>(),
};

export type EnableBankingAction = ActionType<typeof enableBankingActions>;
