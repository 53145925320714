import React from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 60vh;
    overflow: auto;
    font-size: 12px;
    color: #444;
    flex: 1 0 auto;
`;

const ContractTitle = styled.div`
    border-left: 1px solid #000;
    border-top: 1px solid #000;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    margin: 0 0 16px 0;
    padding: 8px;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 16px 0 0 0;
    border-bottom: 1px solid #000;
    flex: 1 0 auto;
`;

const TitleNumber = styled.div`
    border-left: 1px solid #000;
    border-top: 1px solid #000;
    border-right: 1px solid #000;
    border-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6%;
    flex-shrink: 0;
`;

const TitleContent = styled.div`
    border-left: 0;
    border-top: 1px solid #000;
    border-right: 1px solid #000;
    border-bottom: 0;
    padding: 8px;
    width: 94%;
    flex-shrink: 0;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #000;
    flex: 1 0 auto;
`;

const ContentSide = styled.div`
    border-left: 1px solid #000;
    border-top: 0;
    border-right: 1px solid #000;
    border-bottom: 0;
    width: 6%;
`;

const Left = styled.div`
    border-left: 0;
    border-top: 0;
    border-right: 1px solid #000;
    border-bottom: 0;
    padding: 8px;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    width: 47%;
    flex-shrink: 0;
`;

const Right = styled.div`
    border-left: 0;
    border-top: 0;
    border-right: 1px solid #000;
    border-bottom: 0;
    padding: 8px;
    width: 47%;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex: 1 0 auto;
`;

const LeftCreditInfo = styled.span`
    padding: 0 0 8px 0;
    flex-shrink: 0;
    flex: 1 0 auto;
`;

const RightCreditInfo = styled.span`
    padding: 0 0 8px 0;
    flex-shrink: 0;
    flex: 1 0 auto;
`;

export function Sekki() {
    return (
        <Container>
            <ContractTitle>STANDARDISERAD EUROPEISK KONSUMENTKREDITINFORMATION</ContractTitle>
            <TitleContainer>
                <TitleNumber>1</TitleNumber>
                <TitleContent>
                    Kreditgivarens / kreditförmedlarens namn och kontaktuppgifter
                </TitleContent>
            </TitleContainer>
            <ContentContainer>
                <ContentSide />
                <Left>
                    <LeftCreditInfo>Kreditgivare</LeftCreditInfo>
                    <LeftCreditInfo>Adress</LeftCreditInfo>
                    <LeftCreditInfo>Telefon</LeftCreditInfo>
                    <LeftCreditInfo>E-post</LeftCreditInfo>
                    <LeftCreditInfo>Webbadress</LeftCreditInfo>
                </Left>
                <Right>
                    <RightCreditInfo>OPR-Finance AB, org. nr. 556707-7044</RightCreditInfo>
                    <RightCreditInfo>Kungsbroplan 1, 112 27 Stockholm</RightCreditInfo>
                    <RightCreditInfo>+46 (0)8 225 115</RightCreditInfo>
                    <RightCreditInfo>kundservice@everydayplus.se</RightCreditInfo>
                    <RightCreditInfo>https://www.everydayplus.se</RightCreditInfo>
                </Right>
            </ContentContainer>
            <ContentContainer>
                <ContentSide />
                <Left>
                    <LeftCreditInfo>Kreditförmedlare </LeftCreditInfo>
                    <LeftCreditInfo>Adress</LeftCreditInfo>
                    <LeftCreditInfo>Telefon</LeftCreditInfo>
                    <LeftCreditInfo>E-post</LeftCreditInfo>
                    <LeftCreditInfo>Webbadress</LeftCreditInfo>
                </Left>
                <Right>
                    <RightCreditInfo> </RightCreditInfo>
                    <RightCreditInfo> </RightCreditInfo>
                    <RightCreditInfo> </RightCreditInfo>
                    <RightCreditInfo> </RightCreditInfo>
                    <RightCreditInfo> </RightCreditInfo>
                </Right>
            </ContentContainer>
            <TitleContainer>
                <TitleNumber>2</TitleNumber>
                <TitleContent>Beskrivning av huvuddragen i kreditprodukten</TitleContent>
            </TitleContainer>
            <ContentContainer>
                <ContentSide />
                <Left>Typ av kredit</Left>
                <Right>EverydayPlus kontokredit.</Right>
            </ContentContainer>
            <ContentContainer>
                <ContentSide />
                <Left>
                    Det sammanlagda kreditbeloppet
                    <br />
                    <br />
                    <i>
                        Detta är den övre kreditgräns eller det totala belopp som ställs till
                        förfogande genom kreditavtalet.
                    </i>
                </Left>
                <Right>
                    Det sammanlagda kreditbeloppet kan uppgå till ett belopp i intervallet 3 000 –
                    25 000 kronor och preciseras i kreditavtalet på Mina Sidor.
                </Right>
            </ContentContainer>
            <ContentContainer>
                <ContentSide />
                <Left>
                    Villkoren för kreditutnyttjandet
                    <br />
                    <br />
                    <i>Här anges när och hur pengarna kan lyftas.</i>
                </Left>
                <Right>
                    Kredittagaren kan utnyttja krediten eller delar av den omedelbart efter
                    erhållande av godkänt kreditbeslut. Pengarna överförs till det av Kredittagaren
                    bekräftade bankkontot under kundservice öppettider. Överföring sker genast då
                    Kredittagaren skickat sms eller begärt överföring till sitt bankkonto via Mina
                    Sidor (online tjänst) eller redan i ansökan.
                </Right>
            </ContentContainer>
            <ContentContainer>
                <ContentSide />
                <Left>Kreditavtalets löptid</Left>
                <Right>
                    Krediten löper tills vidare, till dess den är återbetald. Kredittagaren har rätt
                    att säga upp kreditavtalet med en månads uppsägningstid. Kreditgivaren har rätt
                    att säga upp avtalet med två månaders uppsägningstid.
                    <br />
                    <br />
                    Kontokrediten får inte användas om det finns fordringar som förfallit till
                    betalning, vilket också kan föranleda förtidsbetalning, se Allmänna villkor för
                    EverydayPlus Kredit 12/2020.
                    <br />
                    <br />
                    Kreditgivaren förbehåller sig rätten att omedelbart sänka, begränsa eller säga
                    upp en outnyttjad kredit efter 12 månader från kreditens beviljande.
                    Kreditgivaren äger vidare med omedelbar verkan säga upp avtalet om Kredittagaren
                    visar sig vara på obestånd, avlider eller om förvaltare förordnas enligt 11 kap.
                    7 § föräldrabalken. Vid dödsfall övergår samtliga kvarstående förpliktelser till
                    dödsboet. Vid uppsägning ska Kredittagaren omedelbart underrättas om ändringen
                    samt skälen därtill.
                </Right>
            </ContentContainer>
            <ContentContainer>
                <ContentSide />
                <Left>
                    Avbetalningar och, i förekommande fall, i vilken ordning dessa ska fördelas
                </Left>
                <Right>
                    Du måste betala följande:
                    <br />
                    <br />
                    Krediten återbetalas i månatliga återbetalningar och i enlighet med de generella
                    villkoren. Minimiamorteringen uppgår till 4 % av den kapitalskulden, dock lägst
                    200 kr. Den totala skulden kan även avbetalas på en gång. Betalningen avräknas i
                    följande ordning: avgifter, ränta och kapital.
                </Right>
            </ContentContainer>
            <ContentContainer>
                <ContentSide />
                <Left>
                    Det totala belopp som du ska betala
                    <br />
                    <br />
                    <i>
                        Detta är det lånade kapitalet plus ränta och eventuella kostnader i samband
                        med krediten
                    </i>
                </Left>
                <Right>
                    Den totala summan av kredit beror på användningen av kredit och återbetalningen
                    av krediten. Den estimerade totala summan som ska betalas om kreditbeloppet är 3
                    000 kr, blir med ränta och avgifter 4 654 kr när betalningen är gjord på tolv
                    månaders återbetalningstid.
                </Right>
            </ContentContainer>
            <TitleContainer>
                <TitleNumber>3</TitleNumber>
                <TitleContent>Kreditkostnader</TitleContent>
            </TitleContainer>
            <ContentContainer>
                <ContentSide />
                <Left>
                    Kreditränta eller i tillämpliga fall de olika krediträntor som gäller för
                    kreditavtalet
                </Left>
                <Right>
                    Krediträntan är rörlig och uppgår för närvarande enligt kreditavtalet till en
                    månadsränta på 3,25 % och en nominell årsränta på 39 %.
                </Right>
            </ContentContainer>
            <ContentContainer>
                <ContentSide />
                <Left>
                    Effektiv ränta
                    <br />
                    <br />
                    <i>
                        Detta är totalkostnaden uttryckt som den effektiva räntan av det samlade
                        kreditbeloppet.
                    </i>
                    <br />
                    <br />
                    <i>
                        Den effektiva räntan anges för att hjälpa dig att jämföra olika erbjudanden
                    </i>
                </Left>
                <Right>
                    Den effektiva räntan styrs av hur stor kredit Kredittagaren fått beviljad.
                    <br />
                    <br />
                    Ett representativt exempel: 3 000 kronor uttag på 12 månader med 39 % nominell
                    ränta. Om man betalar det minsta beloppet (4 % av totala kapitalskulden eller
                    200 kronor, vilket som är högst) samt tillkommande avgifter och räntan varje
                    månad i 11 månader och 865 kronor i sista månaden, blir effektiv räntan 113,46 %
                    (beräknat per den 1 juni 2020, beräknat i enlighet med konsumentkreditlagen).
                </Right>
            </ContentContainer>
            <ContentContainer>
                <ContentSide />
                <Left>
                    Är det, för att erhålla krediten eller för att erhålla den enligt villkoren i
                    marknadsföringen, obligatoriskt att ingå avtal om
                    <br />
                    <br />
                    — en försäkring som säkrar krediten, eller <br />
                    — någon annan kompletterande tjänst? <br />
                    <br />
                    <i>
                        Om kostnaderna för dessa tjänster inte är kända av Kreditgivaren ska de inte
                        inkluderas i den effektiva räntan.{" "}
                    </i>
                </Left>
                <Right>Krediten erhålls utan krav på tilläggstjänster.</Right>
            </ContentContainer>
            <ContentContainer>
                <ContentSide />
                <Left>Andra kostnader i samband med kreditavtalet</Left>
                <Right>
                    Uppläggningsavgift tillkommer om 395 kronor. Administrationsavgift tillkommer om
                    39 kronor vid varje faktura. Uttagsavgift om 50 kronor tillkommer vid varje
                    uttag.
                </Right>
            </ContentContainer>
            <ContentContainer>
                <ContentSide />
                <Left>
                    Kostnader i samband med försenade betalningar
                    <br />
                    <br />
                    Uteblivna betalningar kan få allvarliga konsekvenser (t.ex. tvångsförsäljning)
                    och försvåra möjligheterna till att erhålla kredit.
                </Left>
                <Right>
                    Om betalning inte sker i rätt tid, ska Kredittagaren betala en påminnelseavgift
                    på 60 kr. Kreditgivaren har också rätt att ta ut dröjsmålsräntan på det
                    förfallna beloppet till dess full betalning av förfallen skuld sker.
                    Dröjsmålsränta utgår med högst 40 % över referensräntan. Utebliven betalning
                    medför att Kreditgivaren överför skulden till ett inkassoföretag alternativt
                    Kronofogdemyndigheten. Ärendet kan även överföras till domstol för juridisk
                    prövning. Alla kostnader som uppkommer hos kronofogdemyndigheten eller domstol
                    debiteras Kredittagaren enligt rådande lag. Kreditgivaren har rätt att överföra
                    fordringen till tredje man efter meddelande till Kredittagaren.
                </Right>
            </ContentContainer>
            <TitleContainer>
                <TitleNumber>4</TitleNumber>
                <TitleContent>Andra viktiga rättsliga aspekter</TitleContent>
            </TitleContainer>
            <ContentContainer>
                <ContentSide />
                <Left>
                    Ångerrätt
                    <br />
                    <br />
                    <i>Ni har rätt att frånträda kreditavtalet inom 14 kalenderdagar.</i>
                </Left>
                <Right>Ja</Right>
            </ContentContainer>
            <ContentContainer>
                <ContentSide />
                <Left>
                    Förtidsåterbetalning
                    <br />
                    <br />
                    <i>Hela eller delar av krediten kan återbetalas i förtid när som helst.</i>
                </Left>
                <Right>Ja</Right>
            </ContentContainer>
            <ContentContainer>
                <ContentSide />
                <Left>Kreditgivaren har rätt till kompensation vid förtids­återbetalning</Left>
                <Right>
                    Kreditgivaren tillgodoräknas enbart kompensation i form av ränta från den dag
                    Kredittagaren fick tillgång till krediten t.o.m. den dag krediten är återbetald.
                </Right>
            </ContentContainer>
            <ContentContainer>
                <ContentSide />
                <Left>
                    Sökning i en databas
                    <br />
                    <br />
                    <i>
                        Om en kreditansökan avvisas på grundval av en sökning i en databas måste
                        Kreditgivaren genast och kostnadsfritt meddela dig resultatet av sökningen.
                        Detta gäller inte om utlämnandet av sådan information är förbjudet enligt
                        gemen­skapslagstiftningen eller strider mot allmän ordning och säkerhet.
                    </i>
                </Left>
                <Right>
                    Kreditkontroll görs genom Kreditgivarens interna register och/eller externt
                    kreditupplysningsföretag. Om kreditkontroll sker genom externt
                    kreditupplysningsföretag kommer Kredittagaren/Kredittagarna få information om
                    vilket bolag uppgifterna lämnats från.
                </Right>
            </ContentContainer>
            <ContentContainer>
                <ContentSide />
                <Left>
                    Rätt att få ett utkast till ett kreditavtal
                    <br />
                    <br />
                    <i>
                        Du har rätt att på begäran kostnadsfritt få en kopia av ett utkast till ett
                        kreditavtal. Denna bestämmelse är inte tillämplig om Kreditgivaren vid
                        tidpunkten för begäran är ovillig att ingå kreditavtalet med dig.
                    </i>
                </Left>
                <Right>Ja</Right>
            </ContentContainer>
            <TitleContainer>
                <TitleNumber>5</TitleNumber>
                <TitleContent>
                    Ytterligare information när det gäller distansförsäljning av finansiella
                    tjänster
                </TitleContent>
            </TitleContainer>
            <ContentContainer>
                <ContentSide />
                <Left>a) Beträffande Kreditgivaren</Left>
                <Right />
            </ContentContainer>
            <ContentContainer>
                <ContentSide />
                <Left>Registrering</Left>
                <Right>
                    OPR-Finance AB, org. nr. 556707-7044. OPR-Finance AB är registrerad hos
                    Bolagsverket.
                </Right>
            </ContentContainer>
            <ContentContainer>
                <ContentSide />
                <Left>Den berörda tillsynsmyndigheten</Left>
                <Right>
                    Finansinspektionen.
                    <br />
                    <br />
                    Tillsyn över konsumentkunder utövas också av konsumentverket.
                </Right>
            </ContentContainer>
            <ContentContainer>
                <ContentSide />
                <Left>b) Beträffande kreditavtalet</Left>
                <Right />
            </ContentContainer>
            <ContentContainer>
                <ContentSide />
                <Left>Utnyttjande av ångerrätten</Left>
                <Right>
                    Kredittagaren har rätt att frånträda låneavtalet inom fjorton (14) dagar från
                    den dag då avtalet ingåtts eller från den senare dag då Kredittagaren erhållit
                    dokumentation om krediten och villkoren för låneavtalet. Kredittagaren har
                    därvid rätt till återbetalning av uppläggningsavgiften som utgått. Kredittagaren
                    ska snarast och senast inom 30 dagar från den dag då Kredittagaren lämnade eller
                    sände sitt meddelande om att avtalet frånträds betala tillbaka hela
                    kreditbeloppet med tillägg för upplupen ränta. Räntan ska utgå från den dag
                    Kredittagaren fick tillgång till krediten t.o.m. den dag den återbetalats till
                    Kreditgivaren.
                    <br />
                    <br />
                    Uppsägningen skall helst göras skriftligen per mejl till
                    kundservice@everydayplus.se eller skickas till Kreditgivaren på följande adress:
                    OPR-Finance AB, Kungsbroplan 1, 112 27 Stockholm.
                    <br />
                    <br />
                    Meddelande ska innehålla minst följande information: Namn och personnummer på
                    Kredittagaren, bekräftelse att avtalet ångras, plats och datum och
                    Kredittagarens underskrift.
                </Right>
            </ContentContainer>
            <ContentContainer>
                <ContentSide />
                <Left>
                    Den lagstiftning som Kreditgivaren använt som grundval för att upprätta
                    förbindelser med dig innan kreditavtalet ingicks
                </Left>
                <Right>Svensk lag</Right>
            </ContentContainer>
            <ContentContainer>
                <ContentSide />
                <Left>
                    Klausul om tillämplig lagstiftning för kreditavtalet och/ eller behörig domstol
                </Left>
                <Right>
                    Om tvist uppkommer med anledning av detta låneavtal och denna tvist inte kan
                    lösas genom förhandlingar mellan parterna kan Kredittagaren hänskjuta tvisten
                    till Allmänna Reklamationsnämnden, box 174, 101 23 Stockholm, telefon 08 508 860
                    00.
                    <br />
                    <br />
                    För det fall inte tvist kan lösas genom ovanstående förfarande ska tvist avgöras
                    vid allmän domstol. Svensk lag gäller för dessa villkor. Utförlig information
                    avseende krediten tillhandahålls i enlighet med svensk lag. Kontaktuppgifter:
                    OPR-Finance AB, Kungsbroplan 1, 112 27 Stockholm.
                </Right>
            </ContentContainer>
            <ContentContainer>
                <ContentSide />
                <Left>Språkordning</Left>
                <Right>
                    Information och avtalsvillkor lämnas på svenska. Med ditt samtycke har vi för
                    avsikt att under kreditavtalets löptid kommunicera på svenska.
                </Right>
            </ContentContainer>
            <ContentContainer>
                <ContentSide />
                <Left>c) Beträffande prövning</Left>
                <Right />
            </ContentContainer>
            <ContentContainer>
                <ContentSide />
                <Left>
                    Förekomst av och tillgång till en mekanism för klagomål och prövning utanför
                    domstol
                </Left>
                <Right>
                    Är Kredittagaren inte nöjd med det beslut denna har fått gällande klagomål eller
                    känner att han eller hon vill ta frågan vidare, kan Kredittagaren vända sig till
                    Kundombudsman på OPR-Finance AB. Meddelande ska helst vara skriftligt och
                    beskriva vad som gick fel med hanteringen av ärendet.
                    <br />
                    <br />
                    Email: kundombudsman@opr-finance.se
                    <br />
                    <br />
                    Telefon: +46 (0) 8 225 115 (EverydayPlus)
                    <br />
                    <br />
                    Om tvist uppkommer med anledning av detta låneavtal och denna tvist inte kan
                    lösas genom förhandlingar mellan parterna kan Kredittagaren hänskjuta tvisten
                    till Allmänna Reklamationsnämnden, box 174, 101 23 Stockholm, telefon 08 508 860
                    00.
                </Right>
            </ContentContainer>
        </Container>
    );
}
