import { all, fork } from "redux-saga/effects";
import { watchInitializers } from "@opr-finance/feature-initializer";
import { watchSendApplicationTrigger } from "./sagas/pipeline";
import {
    watchCheckLoginStatus,
    watchCompleteLogin,
    watchLogout,
    watchStartLogin,
} from "@opr-finance/feature-luvittaja-login";
import { watchFetchBankListTrigger } from "@opr-finance/feature-enable-banking";
import { watchCompletedPageTrigger } from "./sagas/completed";
import { watchLoginPageTrigger } from "./sagas/login";
import { watchApplicationPageTrigger } from "./sagas/application";
import { watchPreviewPageTrigger } from "./sagas/preview";
import { watchSendingPageTrigger } from "./sagas/sending";
import { watchChooseBankPageTrigger } from "./sagas/chooseBank";
import { watchSendToEnableBankingTrigger } from "@opr-finance/feature-enable-banking";

export function* rootSaga() {
    yield all([
        fork(watchApplicationPageTrigger),
        fork(watchLoginPageTrigger),
        fork(watchCompletedPageTrigger),
        fork(watchSendApplicationTrigger),
        fork(watchPreviewPageTrigger),
        fork(watchInitializers),
        fork(watchCheckLoginStatus),
        fork(watchCompleteLogin),
        fork(watchLogout),
        fork(watchStartLogin),
        fork(watchSendingPageTrigger),
        fork(watchFetchBankListTrigger),
        fork(watchChooseBankPageTrigger),
        fork(watchSendToEnableBankingTrigger),
    ]);
}
