import { T_EnableBankingBank, T_EnableBankingRequest } from "../";
import * as Leandev from "@opr-finance/api-definitions";
import { resultsAriaMessage } from "react-select/src/accessibility";
import { T_DataToEb } from "../types/types";

type T_EbResponse = {
    url?: string;
    authorization_id?: string;
    code?: number;
    message?: string;
};

export async function getBanksList(data: T_EnableBankingRequest): Promise<T_EnableBankingBank[]> {
    const url = `${data.gwUrl}/eb/banks`;
    const method = "POST";

    const result: T_EnableBankingBank[] = await fetch(url, {
        method,
    })
        .then((res) => res.json())
        .then((data) => data.aspsps);

    return result;
}

export async function sendToEnableBanking(dataToEb: T_DataToEb): Promise<T_EbResponse> {
    try {
        const data = await fetch(`${process.env.REACT_APP_FRENDS_URL}/eb/start-authentication`, {
            method: "POST",
            body: JSON.stringify(dataToEb),
        });

        const response = await data.json();
        const result = response;

        return result;
    } catch (e: any) {
        return e.message;
    }
}

export async function sendCodeAndUuid(code) {
    const url = `${process.env.REACT_APP_FRENDS_URL}/eb/check-and-send`;
    const method = "POST";
    const item = localStorage.getItem("applicationUuid");

    const result = await fetch(url, {
        method,
        body: JSON.stringify({
            uuid: item,
            code,
        }),
    });

    const statusCode = result.status;
    const parsedResponse = await result.json();

    return {
        statusCode,
        status: parsedResponse.Status,
        message: parsedResponse.Message,
    };
}
