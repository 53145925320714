import styled from "styled-components";

export const Root = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 400px;
    align-items: center;
    justify-content: center;
`;
