import styled, { createGlobalStyle } from "styled-components";
import {
    space,
    layout,
    flexbox,
    position,
    SpaceProps,
    LayoutProps,
    FlexboxProps,
    PositionProps,
} from "styled-system";
import { FunctionComponent } from "react";
import { AppThemeProps } from "@opr-finance/themes";

export type BaseLayoutProps = SpaceProps & LayoutProps & FlexboxProps & PositionProps;
export type BaseLayoutPropsWithTheme = BaseLayoutProps & AppThemeProps;

export const GlobalStyles = createGlobalStyle`
    html {
        box-sizing: border-box;
    }
    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }
    html,
    body,
    #root {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
    }
    body {
        background-color: rgb(18, 164, 223);
        background-attachment: fixed;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
    }
    textarea,
    select,
    input,
    button {
        outline: 0;
    }
`;

export const BaseLayout: FunctionComponent<BaseLayoutProps> = styled.div<BaseLayoutProps>`
    ${space}
    ${layout}
    ${flexbox}
    ${position}
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
`;
