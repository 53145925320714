import { Box } from "@opr-finance/component-box-container";
import { Font } from "@opr-finance/component-fonts";
import { FlexColumn, Grid, GridContainer, GridContent } from "@opr-finance/component-grid";
import { Link } from "@opr-finance/component-link-to";
import { Logo } from "@opr-finance/component-logo";
import { Layout } from "@opr-finance/layout-everyday-application";
import { SystemStyleObject } from "@styled-system/css";
import React from "react";
import { Header } from "../components/Header";
import { PAGE_TITLE } from "../constants";
import logo from "../images/logo.svg";

type ErrorPageProps = {
    styleConfig: {
        headerTitle: SystemStyleObject;
        headerContainer: SystemStyleObject;
        headerContent: SystemStyleObject;
        box: SystemStyleObject;
        pageTitle: SystemStyleObject;
        body: SystemStyleObject;
        link: SystemStyleObject;
    };
};

export function ErrorPage(props: ErrorPageProps) {
    return (
        <Layout
            header={
                <Header
                    logo={
                        <Logo
                            logoSrc={logo}
                            width={165}
                            height={35}
                            onClick={() => {
                                window.location.href = process.env
                                    .REACT_APP_MARKETING_PAGE_URL as string;
                            }}
                        />
                    }
                    title={
                        <Font styleConfig={{ root: props.styleConfig.headerTitle }}>
                            {PAGE_TITLE}
                        </Font>
                    }
                    styleConfig={{
                        container: props.styleConfig.headerContainer,
                        content: props.styleConfig.headerContent,
                    }}
                />
            }
        >
            <FlexColumn>
                <GridContainer>
                    <Grid width={7 / 10}>
                        <GridContent>
                            <Box styleConfig={{ root: props.styleConfig.box }}>
                                <Font styleConfig={{ root: props.styleConfig.pageTitle }}>404</Font>
                                <Font styleConfig={{ root: props.styleConfig.pageTitle }}>
                                    <br />
                                    <br />
                                    Oj, sidan du försöker besöka verkar inte existera
                                </Font>
                                <ul>
                                    <Font styleConfig={{ root: props.styleConfig.body }}>
                                        <li>Dubbelkolla att URL:en är korrekt</li>
                                    </Font>
                                    <Font styleConfig={{ root: props.styleConfig.body }}>
                                        <li>Testa vår sökfunktion</li>
                                    </Font>
                                    <Font styleConfig={{ root: props.styleConfig.body }}>
                                        <li>
                                            Besök vår{" "}
                                            <Link
                                                href={"/"}
                                                styleConfig={{ root: props.styleConfig.link }}
                                            >
                                                startsida
                                            </Link>
                                        </li>
                                    </Font>
                                </ul>
                            </Box>
                        </GridContent>
                    </Grid>
                    <Grid width={3 / 10} />
                </GridContainer>
            </FlexColumn>
        </Layout>
    );
}
