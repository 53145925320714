import React, { ReactNode } from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    margin: 0 0 16px 0;
`;

const Left = styled.div`
    height: 100%;
    width: 85%;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    @media (min-width: 786px) {
        width: 240px;
    }
`;

const Right = styled.div`
    height: 100%;
    padding: 10px 0 0 16px;
    margin: 0;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    flex: 1;
    @media (min-width: 786px) {
        width: 15%;
    }
`;

export type FieldItemProps = {
    left: ReactNode;
    right: ReactNode;
};

export function FieldItem(props: FieldItemProps) {
    return (
        <Container>
            <Left>{props.left}</Left>
            <Right>{props.right}</Right>
        </Container>
    );
}
