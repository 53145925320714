import React from "react";

import { T_PageInitializerProps } from "../types";
import { PageProvider, PageConsumer } from "./index";

export function PageInitializer(props: T_PageInitializerProps) {
    return (
        <>
            <PageProvider>
                <PageConsumer
                    id={props.id}
                    successfulActions={props.successfulActions}
                    pageInitAction={props.pageInitAction}
                >
                    {props.children}
                </PageConsumer>
            </PageProvider>
        </>
    );
}
