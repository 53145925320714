import React, { useState, useEffect, FunctionComponent, ReactNode } from "react";
import styled from "styled-components";
import { flexbox, space, layout, position, typography, border, color } from "styled-system";
import Select, { ValueType } from "react-select";
import { InputStyleProps } from "@opr-finance/utils";
import { css, SystemStyleObject } from "@styled-system/css";
import { Font } from "@opr-finance/component-fonts";

export type SelectProps = {
    name?: string;
    defaultValue?: string;
    value?: string;
    ref?: () => void;
    key?: string;
    children?: ReactNode;
    onFocus?: () => void;
    onBlur?: () => void;
    onChange?: (value: ValueType<SelectOption, false>) => void;
    onKeyPress?: (e: KeyboardEvent) => void;
    placeholder?: string;
    styleConfig?: InputStyleProps;
};

export const Option: FunctionComponent<InputStyleProps & SelectProps> = styled.option<
    InputStyleProps & SelectProps
>``;

export type SelectFieldProps = {
    name: string;
    icon: ReactNode;
    inputConfig: {
        select: SelectProps;
        option?: SelectProps;
        validationError?: boolean;
        validationErrorMessage?: string;
        validationErrorIcon?: ReactNode;
    };
    value: SelectOption;
    options: SelectOption[];
    styleConfig: {
        select: any;
        value?: any;
        singleValue?: any;
        option?: any;
        menu?: any;
        placeholder?: any;
        textFieldContainer?: SystemStyleObject;
        validationFieldContainer?: SystemStyleObject;
        validationIconContainer?: SystemStyleObject;
        validationMessage?: SystemStyleObject;
    };
};

export type SelectOption = {
    value: string;
    label: string;
};

type CommonTextFieldStyleProp = {
    styleConfig?: SystemStyleObject;
};

const RowContainer: FunctionComponent<CommonTextFieldStyleProp> = styled.div<CommonTextFieldStyleProp>`
    display: flex;
    flex-direction: row;
    ${(props: CommonTextFieldStyleProp) => {
        return css(props.styleConfig);
    }}
`;

const ColumnContainer: FunctionComponent<CommonTextFieldStyleProp> = styled.div<CommonTextFieldStyleProp>`
    display: flex;
    flex-direction: column;
    ${(props: CommonTextFieldStyleProp) => {
        return css(props.styleConfig);
    }}
`;

export function SelectField(props: SelectFieldProps) {
    const validationEnabled: boolean = props.inputConfig.validationError !== undefined;
    const isValid: boolean = validationEnabled && props.inputConfig.validationError !== true;

    const showErrorText: boolean =
        !isValid && props.inputConfig.validationErrorMessage !== undefined;

    if (validationEnabled) {
        return (
            <ColumnContainer styleConfig={props.styleConfig.validationFieldContainer}>
                <RowContainer styleConfig={props.styleConfig.textFieldContainer}>
                    <Select
                        value={props.value}
                        isSearchable={false}
                        onChange={(value: ValueType<SelectOption, false>) => {
                            if (props.inputConfig.select.onChange) {
                                props.inputConfig.select.onChange(value);
                            }
                        }}
                        onFocus={() => {
                            if (props.inputConfig.select.onFocus) {
                                props.inputConfig.select.onFocus();
                            }
                        }}
                        onBlur={() => {
                            if (props.inputConfig.select.onBlur) {
                                props.inputConfig.select.onBlur();
                            }
                        }}
                        styles={{
                            valueContainer: (styles) => ({
                                ...styles,
                                ...{
                                    fontFamily: "Roboto",
                                    padding: "0",
                                    margin: "0",
                                },
                                ...props.styleConfig.value,
                            }),
                            singleValue: (styles) => ({
                                ...styles,
                                ...{
                                    fontFamily: "Roboto",
                                    padding: "0",
                                    margin: "0",
                                },
                                ...props.styleConfig.singleValue,
                            }),
                            control: (styles) => ({ ...styles, ...props.styleConfig.select }),
                            container: (styles) => ({
                                ...styles,
                                ...{
                                    width: "100%",
                                },
                            }),
                            option: (styles) => ({
                                ...styles,
                                ...{
                                    width: "100%",
                                    cursor: "pointer",
                                    fontFamily: "Roboto",
                                    fontSize: "16px",
                                },
                                ...props.styleConfig.option,
                            }),
                            menu: (styles) => ({
                                ...styles,
                                ...{
                                    width: "100%",
                                    cursor: "pointer",
                                },
                                ...props.styleConfig.menu,
                            }),
                            placeholder: (styles) => ({
                                ...styles,
                                ...props.styleConfig.placeholder,
                            }),
                        }}
                        placeholder={props.options[0].label}
                        components={{
                            DropdownIndicator: () => {
                                return <>{props.icon}</>;
                            },
                            IndicatorSeparator: () => {
                                return null;
                            },
                        }}
                        options={props.options}
                    />
                    {!isValid && props.inputConfig.validationErrorIcon && (
                        <RowContainer styleConfig={props.styleConfig.validationIconContainer}>
                            {props.inputConfig.validationErrorIcon}
                        </RowContainer>
                    )}
                </RowContainer>
                {showErrorText && (
                    <Font styleConfig={{ root: props.styleConfig.validationMessage }}>
                        {props.inputConfig.validationErrorMessage}
                    </Font>
                )}
            </ColumnContainer>
        );
    }
    return (
        <Select
            value={props.value}
            isSearchable={false}
            onChange={(value: ValueType<SelectOption, false>) => {
                if (props.inputConfig.select.onChange) {
                    props.inputConfig.select.onChange(value);
                }
            }}
            onFocus={() => {
                if (props.inputConfig.select.onFocus) {
                    props.inputConfig.select.onFocus();
                }
            }}
            onBlur={() => {
                if (props.inputConfig.select.onBlur) {
                    props.inputConfig.select.onBlur();
                }
            }}
            styles={{
                valueContainer: (styles) => ({
                    ...styles,
                    ...{
                        fontFamily: "Roboto",
                        padding: "0",
                        margin: "0",
                    },
                    ...props.styleConfig.value,
                }),
                singleValue: (styles) => ({
                    ...styles,
                    ...{
                        fontFamily: "Roboto",
                        padding: "0",
                        margin: "0",
                    },
                    ...props.styleConfig.singleValue,
                }),
                control: (styles) => ({ ...styles, ...props.styleConfig.select }),
                container: (styles) => ({
                    ...styles,
                    ...{
                        width: "100%",
                    },
                }),
                option: (styles) => ({
                    ...styles,
                    ...{
                        width: "100%",
                        cursor: "pointer",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                    },
                    ...props.styleConfig.option,
                }),
                menu: (styles) => ({
                    ...styles,
                    ...{
                        width: "100%",
                        cursor: "pointer",
                    },
                    ...props.styleConfig.menu,
                }),
                placeholder: (styles) => ({ ...styles, ...props.styleConfig.placeholder }),
            }}
            placeholder={props.options[0].label}
            components={{
                DropdownIndicator: () => {
                    return <>{props.icon}</>;
                },
                IndicatorSeparator: () => {
                    return null;
                },
            }}
            options={props.options}
        />
    );
}
