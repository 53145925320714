import { createReducer } from "typesafe-actions";
import { produce } from "immer";
import { InitializerAction, initializerActions } from "../actions/initializer";
import { InitializerReducerState } from "../types/initializer";

export const initialState = {
    initializers: ["init"],
    initialized: [],
};

export const initializerReducer = createReducer<InitializerReducerState, InitializerAction>(
    initialState
)
    .handleAction(initializerActions.initApplication, (state, action) => {
        return produce(state, (draftState) => {
            draftState.initializers = action.payload.initializers;
        });
    })
    .handleAction(initializerActions.completeInitializer, (state, action) => {
        return produce(state, (draftState) => {
            draftState.initialized = [...draftState.initialized, action.payload.action];
        });
    })
    .handleAction(initializerActions.initApplicationSuccess, (state, action) => {
        return produce(state, (draftState) => {
            draftState.initializers = [];
            draftState.initialized = [];
        });
    });
