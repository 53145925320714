import React from "react";

import { StyledGridProps, EmptyStyledGridProps } from "./types";
import { RootElement, EmptyRootElement } from "./RootElement.styled";

export function StyledGrid(props: StyledGridProps) {
    return (
        <RootElement {...props} onClick={props.onClick} styleConfig={props.styleConfig?.root}>
            {props.children}
        </RootElement>
    );
}

export function EmptyStyledGrid(props: EmptyStyledGridProps) {
    return <EmptyRootElement {...props} styleConfig={props.styleConfig?.root} />;
}
