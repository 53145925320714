import React, { FunctionComponent } from "react";

import { IconProps } from "./types";
import { BasicStyledIcon } from "./Icon.styled";

export const Icon: FunctionComponent<IconProps> = (props) => {
    const { icon, size, color, transform, active, ...rest } = props;
    return (
        <BasicStyledIcon
            icon={icon}
            active={active}
            size={size}
            transform={transform}
            color={color}
            {...rest}
        />
    );
};
