import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { css } from "@styled-system/css";

import { BaseStyleProps, ContainerProps } from "./types";

/**
 * Wraps child elements inside one element to give a chance to set children in row or column
 * Example css:
 * display: "flex",
 * flexDirection: "row",
 * height: "100%",
 * width: "100%",
 * margin: "0 0 16px 0"
 */
export const StyledContainer: FunctionComponent<ContainerProps> = styled.div<ContainerProps>`
    ${(props: BaseStyleProps) => props.styleConfig && css(props.styleConfig.root)}
`;

export const StyledLabel: FunctionComponent<BaseStyleProps> = styled.div<BaseStyleProps>`
    ${(props: BaseStyleProps) => props.styleConfig && css(props.styleConfig.root)};
`;

export const StyledField: FunctionComponent<BaseStyleProps> = styled.div<BaseStyleProps>`
    ${(props: BaseStyleProps) => props.styleConfig && css(props.styleConfig.root)}
`;
