import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { store } from "./store";
import { ThemeProvider } from "styled-components";
import { theme } from "@opr-finance/theme-everyday-application";
import { BrowserRouter as Router } from "react-router-dom";

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <Router>
                <App />
            </Router>
        </Provider>
    </ThemeProvider>,
    document.getElementById("root")
);

serviceWorker.unregister();
