import React, { ReactNode } from "react";
import { SystemStyleObject } from "@styled-system/css";
import styled from "styled-components";
import { Font } from "@opr-finance/component-fonts";
import { Tooltip } from "./Tooltip";
import { IsValidForm } from "./IsValidForm";

const Container = styled.div<{
    children: ReactNode;
    styleConfig: {
        root: SystemStyleObject;
    };
}>``;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const IconContainer = styled.div`
    height: 26px;
    svg {
        margin: 0 0 8px 0;
    }
`;

export type FormContainerProps = {
    children: ReactNode;
    title: string;
    validForms: string[];
    blurredForms: string[];
    currentForm: string;
    name: string;
    tooltip?: ReactNode;
    styleConfig: {
        title: SystemStyleObject;
    };
};

export function FormContainer(props: FormContainerProps) {
    return (
        <div id={`form-${props.name}`}>
            <Container
                styleConfig={{
                    root: {},
                }}
            >
                <TitleContainer>
                    <Font styleConfig={{ root: props.styleConfig.title }}>{props.title}</Font>
                    <Font styleConfig={{ root: props.styleConfig.title }}>
                        <IconContainer>
                            <IsValidForm
                                blurredForms={props.blurredForms}
                                currentForm={props.currentForm}
                                validForms={props.validForms}
                                name={props.name}
                            />
                        </IconContainer>
                    </Font>
                    {props.tooltip && <Tooltip>{props.tooltip}</Tooltip>}
                </TitleContainer>
                <div>{props.children}</div>
            </Container>
        </div>
    );
}
