import { takeEvery, put, call, select } from "redux-saga/effects";
import { getUnixTime } from "date-fns";
import {
    UserLoginActionConstants,
    FeatureUserLoginState,
    UserReducerState,
    UserTokenData,
} from "../types/general";
import { loginVerifyToken } from "../api/login";
import { ActionType } from "typesafe-actions";
import { userLoginActions } from "../actions/login";
import jwt_decode from "jwt-decode";

export function* watchStartLogin(): Generator {
    yield takeEvery(UserLoginActionConstants.USER_LOGIN_START, handleStartLogin);
    yield takeEvery(UserLoginActionConstants.USER_LOGIN_START_WITH_BANK, handleStartLoginWithBank);
}

export function* handleStartLogin(
    action: ActionType<typeof userLoginActions.loginStart>
): Generator {
    const userConfig = (yield select((state: FeatureUserLoginState) => {
        return state.user;
    })) as UserReducerState;

    console.log("start login, redirect to", userConfig.loginUrl);

    if (userConfig.mockLogin) {
        const token = "random.test.token";
        window.location.href = `${userConfig.loginUrl}?jwt=${token}`;
    } else {
        window.location.href = userConfig.loginUrl;
    }
}

export function* handleStartLoginWithBank(
    action: ActionType<typeof userLoginActions.loginStartWithBank>
): Generator {
    const userConfig = (yield select((state: FeatureUserLoginState) => {
        return state.user;
    })) as UserReducerState;

    console.log("start login, redirect to", userConfig.loginUrl, "with bank", action.payload.bank);

    if (userConfig.mockLogin) {
        const token = "random.test.token";
        window.location.href = `${userConfig.loginUrl}?jwt=${token}`;
    } else {
        window.location.href = `${userConfig.loginUrl}?bank=${action.payload.bank}`;
    }
}

export function* watchCompleteLogin(): Generator {
    yield takeEvery(UserLoginActionConstants.USER_LOGIN_COMPLETE, handleCompleteLogin);
}

export function* handleCompleteLogin(): Generator {
    try {
        const query = new URLSearchParams(window.location.search);
        const jwt = query.get("jwt") as string;
        const userConfig = (yield select((state: FeatureUserLoginState) => {
            return state.user;
        })) as UserReducerState;

        console.log("got jwt token", jwt, userConfig);

        const result = (yield call(loginVerifyToken, {
            url: userConfig.verifyUrl,
            token: jwt,
            mockApiCalls: userConfig.mockLogin,
        })) as boolean;

        if (userConfig.mockLogin) {
            console.log("path a", userConfig.mockLogin);

            localStorage.setItem("user_token", jwt);
            localStorage.setItem("mock_login", userConfig.mockLogin ? "true" : "false");

            window.location.href = userConfig.successUrl;
        } else {
            console.log("path b", userConfig.mockLogin);

            if (!result) {
                console.log("bad login result");
                return (window.location.href = userConfig.errorUrl);
            }

            localStorage.setItem("user_token", jwt);

            console.log("login success!", userConfig);

            window.location.href = userConfig.successUrl;
        }
    } catch (e) {
        console.log("login failed!", e);
    }
}

export function* watchCheckLoginStatus(): Generator {
    yield takeEvery(UserLoginActionConstants.USER_LOGIN_CHECK_STATUS, handleCheckLoginStatus);
}

export function* handleCheckLoginStatus(): Generator {
    try {
        const userConfig = (yield select((state: FeatureUserLoginState) => {
            return state.user;
        })) as UserReducerState;

        console.log("check login status with configs", userConfig);

        yield put(userLoginActions.updateIsValidatingToken(true));

        const token: string | null = localStorage.getItem("user_token");

        const mockLogin: boolean =
            localStorage.getItem("mock_login") === "true" ? true : userConfig.mockLogin;

        console.log("got token", token);

        if (!token) {
            console.log("no token found?", token, userConfig.errorUrl);
            window.location.href = userConfig.errorUrl;
            return;
        }

        let isValidToken: boolean = false;
        let tokenData: UserTokenData | null = null;

        isValidToken = (yield call(loginVerifyToken, {
            token,
            url: userConfig.verifyUrl,
            mockApiCalls: mockLogin,
        })) as boolean;

        console.log("is valid token?", isValidToken);

        if (mockLogin) {
            tokenData = {
                aud: "",
                exp: getUnixTime(new Date()) + 15 * 60 * 1000, // add 15 minutes to unix timestamp
                iat: 0,
                iss: "",
                jti: "",
                name: "Testoman Tester",
                ssn: "12341431",
                nbf: 0,
                sub: "12341431",
            };
        } else {
            tokenData = jwt_decode<UserTokenData>(token);
        }

        yield put(userLoginActions.updateIsValidToken(isValidToken));
        yield put(userLoginActions.updateToken(token));
        yield put(userLoginActions.updateTokenData(tokenData));

        if (!isValidToken) {
            console.log("bad token!");
            yield put(userLoginActions.updateToken(""));
        }

        yield put(userLoginActions.updateIsValidatingToken(false));
        yield put(userLoginActions.updateIsInitialized(true));
        yield put(userLoginActions.loginCheckStatusSuccess());
    } catch (e) {
        console.log("validation failed", e);

        yield put(userLoginActions.updateToken(""));
        yield put(userLoginActions.updateIsValidToken(false));
    }
}

export function* watchLogout(): Generator {
    yield takeEvery(UserLoginActionConstants.USER_LOGOUT, handleLogout);
}

export function* handleLogout(): Generator {
    try {
        console.log("HANDLE LOGOUT!");

        const userConfig = (yield select((state: FeatureUserLoginState) => {
            return state.user;
        })) as UserReducerState;

        yield put(userLoginActions.updateIsValidatingToken(true));

        const token: string | null = localStorage.getItem("user_token");
        localStorage.removeItem("mock_login");

        console.log("got token for logout", token);

        if (!token) {
            console.log("no token found?", token);
            yield put(userLoginActions.updateIsValidToken(false));
            yield put(userLoginActions.updateToken(""));
            yield put(userLoginActions.updateIsValidatingToken(false));
            window.location.href = userConfig.errorUrl;
            return;
        }

        yield put(userLoginActions.updateIsValidToken(false));
        yield put(userLoginActions.updateToken(""));
        yield put(userLoginActions.updateIsValidatingToken(false));

        window.location.href = userConfig.logoutUrl;
    } catch (e) {
        console.log("validation failed", e);

        yield put(userLoginActions.updateToken(""));
        yield put(userLoginActions.updateIsValidToken(false));
    }
}
