import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import CookieBot from "react-cookiebot";
import { iconLibrary } from "@opr-finance/feature-icon-library";
import { ApplicationPage } from "./pages/ApplicationPage";
import { Sending } from "./pages/Sending";
import { CompletedPage } from "./pages/CompletedPage";
import {
    body,
    bodyTitle,
    bodySubmitError,
    pageTitle,
    textField,
    select,
    checkbox,
    checkboxDisabled,
    checkboxText,
    button,
    buttonText,
    formError,
    link,
    inlineBox,
    box,
    banksContainer,
    banksContent,
    banksImage,
    formItem,
    formItemLabel,
    formItemField,
    sideTitle,
    breadcrumbTitle,
    headerTitle,
    buttonInfo,
    buttonCancel,
    buttonBack,
    buttonSubmit,
    buttonRejected,
    headerContainer,
    headerContent,
    buttonSubmitDisabled,
    sideLink,
    sideBox,
    bodyWarning,
    bodyLabel,
    mobilePageTitle,
    breadcrumbBox,
    textFieldsContainer,
    multipleTextField,
    bankName,
    imageContainer,
} from "@opr-finance/theme-everyday-application";
import { LoginPage } from "./pages/LoginPage";
import { UserLoginActionConstants } from "@opr-finance/feature-luvittaja-login";
import { AppInitializer } from "@opr-finance/feature-initializer";
import { E_EnableBankingActionConstants } from "@opr-finance/feature-enable-banking";
import { AppActionConstants } from "./actions/actions";
import { ErrorPage } from "./pages/ErrorPage";
import { SubmitErrorPage } from "./pages/SubmitErrorPage";
import { PreviewPage } from "./pages/PreviewPage";
import { ChooseBank } from "./pages/ChooseBank";
import { Application, QueryParams } from "./types/general";
import { T_EarlyReturnFromFrends } from "@opr-finance/feature-enable-banking/src/types/types";
import { StartPage } from "./pages/StartPage";
import { useQuery } from "@opr-finance/utils";

iconLibrary.initFixza();

const domainGroupId = process.env.REACT_APP_COOKIEBOT_DOMAING_GROUP_ID as string;
const isOpen: boolean = (process.env.REACT_APP_PIPELINE_IS_OPEN as string) === "1";

const App: React.FC = () => {
    const { code, error } = useQuery<QueryParams>();

    return (
        <div>
            <CookieBot domainGroupId={domainGroupId} />
            <Switch>
                <Route
                    path="/"
                    exact
                    render={() => {
                        return isOpen ? (
                            <Redirect to="/application" />
                        ) : (
                            <StartPage
                                styleConfig={{
                                    headerTitle: headerTitle(),
                                    headerContainer: headerContainer(),
                                    headerContent: headerContent(),
                                    body: body(),
                                    mobilePageTitle: mobilePageTitle(),
                                    box: box(),
                                    bodyLabel: bodyLabel(),
                                }}
                            />
                        );
                    }}
                />
                <Route
                    path="/application"
                    render={() => {
                        return isOpen ? (
                            <AppInitializer
                                actionsRequired={[UserLoginActionConstants.USER_LOGIN_INITIALIZE]}
                                initializerAction={AppActionConstants.APPLICATION_PAGE_TRIGGER}
                            >
                                <ApplicationPage
                                    styleConfig={{
                                        headerTitle: headerTitle(),
                                        headerContainer: headerContainer(),
                                        headerContent: headerContent(),
                                        body: body(),
                                        bodyLabel: bodyLabel(),
                                        bodyWarning: bodyWarning(),
                                        bodyTitle: bodyTitle(),
                                        pageTitle: pageTitle(),
                                        textField: textField(),
                                        select: select(),
                                        checkbox: checkbox(),
                                        checkboxDisabled: checkboxDisabled(),
                                        checkboxText: checkboxText(),
                                        button: button(),
                                        buttonText: buttonText(),
                                        formError: formError(),
                                        link: link(),
                                        inlineBox: inlineBox(),
                                        box: box(),
                                        sideBox: sideBox(),
                                        sideTitle: sideTitle(),
                                        sideLink: sideLink(),
                                        breadcrumbTitle: breadcrumbTitle(),
                                        breadcrumbBox: breadcrumbBox(),
                                        buttonCancel: buttonCancel(),
                                        buttonInfo: buttonInfo(),
                                        buttonSubmit: buttonSubmit(),
                                        buttonSubmitDisabled: buttonSubmitDisabled(),
                                        mobilePageTitle: mobilePageTitle(),
                                        textFieldsContainer: textFieldsContainer(),
                                        multipleTextField: multipleTextField(),
                                    }}
                                />
                            </AppInitializer>
                        ) : (
                            <Redirect to="/" />
                        );
                    }}
                />
                <Route
                    path="/login"
                    render={() => {
                        return isOpen ? (
                            <Redirect to="/application" />
                        ) : (
                            <AppInitializer
                                actionsRequired={[UserLoginActionConstants.USER_LOGIN_INITIALIZE]}
                                initializerAction={AppActionConstants.LOGIN_PAGE_TRIGGER}
                            >
                                <LoginPage
                                    styleConfig={{
                                        headerTitle: headerTitle(),
                                        headerContainer: headerContainer(),
                                        headerContent: headerContent(),
                                    }}
                                />
                            </AppInitializer>
                        );
                    }}
                />
                <Route
                    path="/error"
                    render={() => {
                        return isOpen ? (
                            <Redirect to="/application" />
                        ) : (
                            <ErrorPage
                                styleConfig={{
                                    headerTitle: headerTitle(),
                                    headerContainer: headerContainer(),
                                    headerContent: headerContent(),
                                    body: body(),
                                    pageTitle: pageTitle(),
                                    box: box(),
                                    link: link(),
                                }}
                            />
                        );
                    }}
                />
                <Route
                    path="/preview"
                    render={() => {
                        return isOpen ? (
                            <Redirect to="/application" />
                        ) : (
                            <AppInitializer
                                actionsRequired={[
                                    UserLoginActionConstants.USER_LOGIN_INITIALIZE,
                                    UserLoginActionConstants.USER_LOGIN_CHECK_STATUS_SUCCESS,
                                ]}
                                initializerAction={AppActionConstants.PREVIEW_PAGE_TRIGGER}
                            >
                                <PreviewPage
                                    styleConfig={{
                                        headerTitle: headerTitle(),
                                        headerContainer: headerContainer(),
                                        headerContent: headerContent(),
                                        body: body(),
                                        bodyLabel: bodyLabel(),
                                        bodyWarning: bodyWarning(),
                                        bodyTitle: bodyTitle(),
                                        pageTitle: pageTitle(),
                                        textField: textField(),
                                        select: select(),
                                        checkbox: checkbox(),
                                        checkboxDisabled: checkboxDisabled(),
                                        checkboxText: checkboxText(),
                                        button: button(),
                                        buttonText: buttonText(),
                                        formError: formError(),
                                        link: link(),
                                        inlineBox: inlineBox(),
                                        box: box(),
                                        sideBox: sideBox(),
                                        sideTitle: sideTitle(),
                                        sideLink: sideLink(),
                                        breadcrumbTitle: breadcrumbTitle(),
                                        breadcrumbBox: breadcrumbBox(),
                                        buttonCancel: buttonCancel(),
                                        buttonInfo: buttonInfo(),
                                        buttonSubmit: buttonSubmit(),
                                        buttonSubmitDisabled: buttonSubmitDisabled(),
                                        mobilePageTitle: mobilePageTitle(),
                                    }}
                                />
                            </AppInitializer>
                        );
                    }}
                />
                <Route
                    path="/choose-bank"
                    render={() => {
                        return isOpen ? (
                            <Redirect to="/application" />
                        ) : (
                            <AppInitializer
                                actionsRequired={[
                                    E_EnableBankingActionConstants.ENABLE_BANKING_INITIALIZER,
                                    E_EnableBankingActionConstants.ENABLE_BANKING_GET_BANKS_SUCCESS,
                                ]}
                                initializerAction={AppActionConstants.CHOOSE_BANK_TRIGGER}
                            >
                                <ChooseBank
                                    styleConfig={{
                                        headerTitle: headerTitle(),
                                        headerContainer: headerContainer(),
                                        headerContent: headerContent(),
                                        body: body(),
                                        pageTitle: pageTitle(),
                                        box: box(),
                                        link: link(),
                                        banksContainer: banksContainer(),
                                        banksContent: banksContent(),
                                        banksImage: banksImage(),
                                        textField: textField(),
                                        formItem: formItem(),
                                        formItemLabel: formItemLabel(),
                                        formItemField: formItemField(),
                                        breadCrumbBox: breadcrumbBox(),
                                        buttonBack: buttonBack(),
                                        bankName: bankName(),
                                        imageContainer: imageContainer(),
                                    }}
                                />
                            </AppInitializer>
                        );
                    }}
                />
                <Route
                    path="/sending"
                    render={() => {
                        if (error || !code) return <Redirect to="/error" />;
                        return isOpen ? (
                            <Redirect to="/application" />
                        ) : (
                            <AppInitializer
                                initializerAction={AppActionConstants.SENDING_PAGE_TRIGGER}
                                actionPayload={code}
                            >
                                <Sending />
                            </AppInitializer>
                        );
                    }}
                />
                <Route
                    path="/completed"
                    render={() => {
                        return isOpen ? (
                            <Redirect to="/application" />
                        ) : (
                            <CompletedPage
                                styleConfig={{
                                    headerTitle: headerTitle(),
                                    headerContainer: headerContainer(),
                                    headerContent: headerContent(),
                                    body: body(),
                                    pageTitle: pageTitle(),
                                    box: box(),
                                    breadcrumbTitle: breadcrumbTitle(),
                                    breadcrumbBox: breadcrumbBox(),
                                }}
                            />
                        );
                    }}
                />
                <Route
                    path="/submit-error"
                    render={() => {
                        return isOpen ? (
                            <Redirect to="/application" />
                        ) : (
                            <SubmitErrorPage
                                styleConfig={{
                                    headerTitle: headerTitle(),
                                    headerContainer: headerContainer(),
                                    headerContent: headerContent(),
                                    body: bodySubmitError(),
                                    bodyTitle: bodyTitle(),
                                    pageTitle: pageTitle(),
                                    link: link(),
                                    box: box(),
                                    breadcrumbTitle: breadcrumbTitle(),
                                    breadcrumbBox: breadcrumbBox(),
                                }}
                            />
                        );
                    }}
                />
            </Switch>
        </div>
    );
};

export default App;
