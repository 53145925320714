import { UserTokenData } from "@opr-finance/feature-luvittaja-login";
import React from "react";
import styled from "styled-components";
import { Application } from "../../types/general";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 60vh;
    overflow: auto;
    font-size: 12px;
    color: #444;
`;

const ContractTitle = styled.div`
    margin: 0 0 32px 0;
    font-size: 18px;
    font-weight: 700;
`;

const Title = styled.div`
    font-size: 14px;
    font-weight: 700;
`;

const Data = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 0 8px 0;
    flex: 1 0 auto;
`;

const Left = styled.div`
    width: 50%;
    flex-shrink: 0;
`;

const Right = styled.div`
    width: 50%;
    flex-shrink: 0;
`;

const Table = styled.table`
    border-collapse: collapse;
    border: 1px solid #000;
`;

const Tr = styled.tr`
    border-bottom: 1px solid #000;
`;

const Td = styled.td`
    border-right: 1px solid #000;
    padding: 4px;
    width: 33.3%;
    font-size: 12px;
    @media (max-width: 786px) {
        font-size: 10px;
    }
`;

type CreditAgreementProps = {
    application: Application;
    tokenData: UserTokenData;
};

type Price = {
    creditLimit: string;
    effectiveInterestRate: string;
};

function getPriceData(): Price[] {
    return [
        {
            creditLimit: "3 000",
            effectiveInterestRate: "113,46%",
        },
        {
            creditLimit: "4 000",
            effectiveInterestRate: "87,09%",
        },
        {
            creditLimit: "5 000",
            effectiveInterestRate: "74,74%",
        },
        {
            creditLimit: "6 000",
            effectiveInterestRate: "67,99%",
        },
        {
            creditLimit: "7 000",
            effectiveInterestRate: "63,70%",
        },
        {
            creditLimit: "8 000",
            effectiveInterestRate: "60,65%",
        },
        {
            creditLimit: "9 000",
            effectiveInterestRate: "58,31%",
        },
        {
            creditLimit: "10 000",
            effectiveInterestRate: "56,48%",
        },
        {
            creditLimit: "11 000",
            effectiveInterestRate: "54,99%",
        },
        {
            creditLimit: "12 000",
            effectiveInterestRate: "53,77%",
        },
        {
            creditLimit: "13 000",
            effectiveInterestRate: "52,74%",
        },
        {
            creditLimit: "14 000",
            effectiveInterestRate: "51,86%",
        },
        {
            creditLimit: "15 000",
            effectiveInterestRate: "51,11%",
        },
        {
            creditLimit: "16 000",
            effectiveInterestRate: "50,46%",
        },
        {
            creditLimit: "17 000",
            effectiveInterestRate: "49,88%",
        },
        {
            creditLimit: "18 000",
            effectiveInterestRate: "49,37%",
        },
        {
            creditLimit: "19 000",
            effectiveInterestRate: "48,92%",
        },
        {
            creditLimit: "20 000",
            effectiveInterestRate: "48,51%",
        },
        {
            creditLimit: "21 000",
            effectiveInterestRate: "48,14%",
        },
        {
            creditLimit: "22 000",
            effectiveInterestRate: "47,81%",
        },
        {
            creditLimit: "23 000",
            effectiveInterestRate: "47,51%",
        },
        {
            creditLimit: "24 000",
            effectiveInterestRate: "47,23%",
        },
        {
            creditLimit: "25 000",
            effectiveInterestRate: "46,98%",
        },
    ];
}

function CreditAgreementPricesTable() {
    return (
        <Table>
            <thead>
                <Tr>
                    <Td>Kreditgräns</Td>
                    <Td>Effektiv ränta</Td>
                </Tr>
            </thead>
            <tbody>
                {getPriceData().map((data, index) => {
                    return (
                        <Tr key={`price-${index}`}>
                            <Td>{data.creditLimit}</Td>
                            <Td>{data.effectiveInterestRate}</Td>
                        </Tr>
                    );
                })}
            </tbody>
        </Table>
    );
}

export function CreditAgreement(props: CreditAgreementProps) {
    return (
        <Container>
            <ContractTitle>Kreditavtal</ContractTitle>
            <Data>
                <Left>
                    <Title>Kreditgivare</Title>
                </Left>
                <Right>
                    <Title>Kredittagare</Title>
                </Right>
            </Data>
            <Data>
                <Left>OPR-Finance AB</Left>
                <Right>Namn: {props.tokenData.name}</Right>
            </Data>
            <Data>
                <Left>Org. nr. 556707–7044</Left>
                <Right>Personnummer: {props.tokenData.ssn}</Right>
            </Data>
            <Data>
                <Left>Kungsbroplan 1</Left>
                <Right>Mobilnummer: {props.application.applicantPhone}</Right>
            </Data>
            <Data>
                <Left>112 27 Stockholm</Left>
                <Right>E-post: {props.application.applicantEmail}</Right>
            </Data>
            <Data>
                <Left>Tel. +46 (0)8 225 115</Left>
                <Right>Bankkontonummer: {props.application.bankAccount}</Right>
            </Data>
            <Data>
                <Left>E-post: kundservice@everydayplus.se</Left>
                <Right />
            </Data>
            <Data>
                <Left>Webbadress: http://www.everydayplus.se</Left>
            </Data>
            <br />
            <Title>Kreditöversikt</Title>
            <br />
            En kopia på ditt slutgiltiga avtal kommer att finnas på Mina Sidor.
            <br />
            <br />
            <Data>
                <Left>
                    Ansökt kreditgräns: 3 000 – 25 000 kronor
                    <br />
                    <br />
                    Månadsränta: 3,25 %
                    <br />
                    Effektiv ränta: Se pristabell
                    <br />
                    Uppläggningsavgift: 395 kronor
                    <br />
                    Uttagsavgift: 50 kronor
                    <br />
                    Administrationsavgift: 39 kronor
                </Left>
                <Right>
                    <CreditAgreementPricesTable />
                </Right>
            </Data>
            <br />
            <br />
            <i>
                * Beräkningen grundas på att krediten är utnyttjad till fullo och att krediten är
                återbetald på 12 månader.
            </i>
            <br />
            <br />
            Minsta belopp att betala varje månad: 4 % av den totala kapitalskulden, dock lägst 200
            kronor samt tillkommande avgifter och ränta.
            <br />
            <br />
            Kreditgivaren kommer att göra en sedvanlig kreditprövning vari ingår inhämtande av
            kreditupplysning. Det beviljade kreditbeloppet är beroende av kreditprövningen avseende
            Kredittagaren.
            <br />
            <br />
            Jag, benämnd Kredittagaren, bekräftar att information om gällande räntesats, effektiv
            ränta och avgifter har lämnats till mig innan detta avtal träffats och att jag har tagit
            del av de Allmänna- och autogirovillkoren för EverydayPlus Kredit. Jag förbinder mig
            att, om kredit beviljas, solidariskt betala kreditbeloppet jämte ränta, avgifter och
            kostnader till Kreditgivaren.
            <br />
            <br />
            Jag intygar härmed att uppgifter lämnade på ansökan är riktiga och samtycker till att
            dessa behandlas i enlighet med kreditavtalet och Allmänna villkor för EverydayPlus
            Kredit. Detta avtal består av Allmänna villkor för EverydayPlus Kredit och
            Standardiserad europeisk konsumentkreditinformation. Jag bekräftar vidare att jag har
            tagit del av Kreditavtal, OPR-Finance ABs Allmänna villkor för EverydayPlus Kredit
            12/2020 samt av formuläret Standardiserad europeisk konsumentkreditinformation.
            <br />
            <br />
            Jag medger att uttag får göras från mitt bankkonto, enligt villkoren som anges under
            punkt 5.1 i Allmänna villkor för EverydayPlus Kredit, på begäran av Kreditgivaren för
            betalning via autogiro.
        </Container>
    );
}
