import React, { FunctionComponent } from "react";

import { ClickableIconProps } from "./types";
import { ClickableIconContainer, BasicStyledIcon } from "./Icon.styled";

export const ClickableIcon: FunctionComponent<ClickableIconProps> = (props) => {
    const { icon, size, color, transform, active, onClick, styleConfig, ...rest } = props;
    return (
        <ClickableIconContainer
            styleConfig={{ container: styleConfig.container }}
            onClick={() => onClick()}>
            <BasicStyledIcon
                icon={icon}
                active={active}
                size={size}
                transform={transform}
                color={color}
                {...rest}
            />
        </ClickableIconContainer>
    );
};
