import { ActionType, createAction } from "typesafe-actions";
import { UserLoginActionConstants, UserTokenData } from "../types/general";

export type LoginStartPayload = {
    bank: string;
};

export type UserLoginInitializePayload = {
    mockLogin: boolean;
    loginUrl: string;
    verifyUrl: string;
    errorUrl: string;
    successUrl: string;
    logoutUrl: string;
};

export const userLoginActions = {
    loginInitializer: createAction(
        UserLoginActionConstants.USER_LOGIN_INITIALIZE
    )<UserLoginInitializePayload>(),
    loginStart: createAction(UserLoginActionConstants.USER_LOGIN_START)(),
    loginStartWithBank: createAction(
        UserLoginActionConstants.USER_LOGIN_START_WITH_BANK
    )<LoginStartPayload>(),
    loginSuccess: createAction(UserLoginActionConstants.USER_LOGIN_START_SUCCESS)(),
    loginComplete: createAction(UserLoginActionConstants.USER_LOGIN_COMPLETE)(),
    loginCompleteSuccess: createAction(UserLoginActionConstants.USER_LOGIN_COMPLETE_SUCCESS)(),
    loginCheckStatus: createAction(UserLoginActionConstants.USER_LOGIN_CHECK_STATUS)(),
    loginCheckStatusSuccess: createAction(
        UserLoginActionConstants.USER_LOGIN_CHECK_STATUS_SUCCESS
    )(),
    logout: createAction(UserLoginActionConstants.USER_LOGOUT)(),
    validateToken: createAction(UserLoginActionConstants.USER_VALIDATE_TOKEN)(),
    updateToken: createAction(UserLoginActionConstants.USER_UPDATE_TOKEN)<string>(),
    updateIsValidatingToken: createAction(
        UserLoginActionConstants.USER_UPDATE_IS_VALIDATING_TOKEN
    )<boolean>(),
    updateIsValidToken: createAction(
        UserLoginActionConstants.USER_UPDATE_IS_VALID_TOKEN
    )<boolean>(),
    updateIsInitialized: createAction(
        UserLoginActionConstants.USER_UPDATE_IS_INITIALIZED
    )<boolean>(),
    updateTokenData: createAction(UserLoginActionConstants.USER_UPDATE_TOKEN_DATA)<UserTokenData>(),
};

export type UserLoginAction = ActionType<typeof userLoginActions>;
