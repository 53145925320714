import * as React from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";

import { T_DatePickerInputProps } from "./types";

export function DatePickerInput(props: T_DatePickerInputProps) {
    const [value, setValue] = React.useState<Date | null>(null);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={props.inputConfig.locale}>
            <DesktopDatePicker
                mask={"__-__-____"}
                value={value}
                onChange={(newValue) => {
                    setValue(newValue);
                    props.inputConfig.onChange(newValue?.toString() || "");
                }}
                renderInput={(params) => {
                    return (
                        <TextField {...params} helperText={"DD-MM-JJJJ"} sx={props.styleConfig} />
                    );
                }}
            />
        </LocalizationProvider>
    );
}
