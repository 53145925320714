import React from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 60vh;
    overflow: auto;
    font-size: 12px;
    color: #444;
`;

const Title = styled.div`
    margin: 0 0 32px 0;
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 32px 0;
    flex: 1 0 auto;
    flex-shrink: 0;
`;

const InfoTitle = styled.div`
    font-weight: bold;
    margin: 0 0 18px 0;
    flex-shrink: 0;
`;

const SubInfoTitle = styled.div`
    font-weight: bold;
    margin: -6px 0 18px 0;
    font-size: 10px;
`;

const InfoContent = styled.div`
    flex-shrink: 0;
`;

const Table = styled.table`
    border-collapse: collapse;
    border: 1px solid #000;
`;

const Tr = styled.tr`
    border-bottom: 1px solid #000;
`;

const Td = styled.td`
    border-right: 1px solid #000;
    padding: 4px;
    width: 33.3%;
    font-size: 12px;
    @media (max-width: 786px) {
        font-size: 10px;
    }
`;

const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

/*

Avi	Månadsbetalning	Skuld 	Kapital	Ränta	Amortering	Avgifter
*/

type TermsAndConditionsPrice = {
    month: string;
    monthlyPayment: string;
    debt: string;
    capital: string;
    interest: string;
    amortization: string;
    fees: string;
};

function getTermsAndConditionsPrices(): TermsAndConditionsPrice[] {
    return [
        {
            month: "1",
            monthlyPayment: "782",
            debt: "3582",
            capital: "3000",
            interest: "98",
            amortization: "200",
            fees: "484",
        },
        // 2	330	2930	2800	91	200	39
        {
            month: "2",
            monthlyPayment: "330",
            debt: "2930",
            capital: "2800",
            interest: "91",
            amortization: "200",
            fees: "39",
        },
        // 3	324	2724	2600	85	200	39
        {
            month: "3",
            monthlyPayment: "324",
            debt: "2724",
            capital: "2600",
            interest: "85",
            amortization: "200",
            fees: "39",
        },
        // 4	317	2517	2400	78	200	39
        {
            month: "4",
            monthlyPayment: "317",
            debt: "2517",
            capital: "2400",
            interest: "78",
            amortization: "200",
            fees: "39",
        },
        // 5	311	2311	2200	72	200	39
        {
            month: "5",
            monthlyPayment: "311",
            debt: "2311",
            capital: "2200",
            interest: "72",
            amortization: "200",
            fees: "39",
        },
        // 6	304	2104	2000	65	200	39
        {
            month: "6",
            monthlyPayment: "304",
            debt: "2104",
            capital: "2000",
            interest: "65",
            amortization: "200",
            fees: "39",
        },
        // 7	298	1898	1800	59	200	39
        {
            month: "7",
            monthlyPayment: "298",
            debt: "1898",
            capital: "1800",
            interest: "59",
            amortization: "200",
            fees: "39",
        },
        // 8	291	1691	1600	52	200	39
        {
            month: "8",
            monthlyPayment: "291",
            debt: "1691",
            capital: "1600",
            interest: "52",
            amortization: "200",
            fees: "39",
        },
        // 9	285	1485	1400	46	200	39
        {
            month: "9",
            monthlyPayment: "285",
            debt: "1485",
            capital: "1400",
            interest: "46",
            amortization: "200",
            fees: "39",
        },
        // 10	278	1278	1200	39	200	39
        {
            month: "10",
            monthlyPayment: "278",
            debt: "1278",
            capital: "1200",
            interest: "39",
            amortization: "200",
            fees: "39",
        },
        // 11	272	1072	1000	33	200	39
        {
            month: "11",
            monthlyPayment: "272",
            debt: "1072",
            capital: "1000",
            interest: "33",
            amortization: "200",
            fees: "39",
        },
        // 12	265	865	800	26	200	39
        {
            month: "12",
            monthlyPayment: "265",
            debt: "865",
            capital: "800",
            interest: "26",
            amortization: "200",
            fees: "39",
        },
        // 13	259	659	600	20	200	39
        {
            month: "13",
            monthlyPayment: "259",
            debt: "659",
            capital: "600",
            interest: "20",
            amortization: "200",
            fees: "39",
        },
        // 14	252	452	400	13	200	39
        {
            month: "14",
            monthlyPayment: "252",
            debt: "452",
            capital: "400",
            interest: "13",
            amortization: "200",
            fees: "39",
        },
        // 15	246	246	200	7	200	39
        {
            month: "15",
            monthlyPayment: "246",
            debt: "246",
            capital: "200",
            interest: "7",
            amortization: "200",
            fees: "39",
        },
    ];
}

export function TermsAndConditions() {
    return (
        <Container>
            <Title>Allmänna villkor för EverydayPlus Kredit(12/2021)</Title>
            <InfoContainer>
                <InfoTitle>1. Definitioner / Parter</InfoTitle>
                <InfoContent>
                    <strong>Kreditgivare:</strong> OPR-Finance AB, organisationsnummer 556707–7044.
                    <br />
                    <strong>Kredittagare/Kontohavare:</strong> Den person som har sökt eller
                    beviljats en kredit av Kreditgivaren.
                    <br />
                    <strong>Kredit:</strong> Kredit med rätt för Kredittagaren att utnyttja ett
                    beviljat kreditutrymme.
                    <br />
                    <strong>Kreditutrymme:</strong> Det belopp som står till Kredittagarens
                    förfogande.
                    <br />
                    <strong>Kreditgräns:</strong> Det maximala kreditbelopp som Kreditgivaren har
                    beviljat Kredittagaren att utnyttja.
                    <br />
                    <strong>Kreditavtal:</strong> Avtal om kredit mellan Kreditgivaren och
                    Kredittagaren jämte från tid till annan gällande Allmänna villkor för
                    EverydayPlus kredit.
                    <br />
                    <strong>Mina sidor:</strong> Kredittagarens personliga inloggningssida på
                    www.everydayplus.se där Kredittagaren kan få tillgång till information om
                    Krediten samt möjligheter för hantering av krediten.
                    <br />
                </InfoContent>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>2. Allmänt</InfoTitle>
                <InfoContent>
                    I enlighet med dessa villkor, OPR-Finance AB, organisationsnummer 556707-7044,
                    (”Kreditgivaren”) tillhandahåller EverydayPlus krediten till den person som
                    ansöker om denna (”Kredittagaren”).
                    <br />
                    <br />
                    Kreditgivaren har tillstånd från Finansinspektionen enligt lagen (2014:275) om
                    Konsumentkreditgivning. Kommunikation mellan Kreditgivaren och Kredittagaren kan
                    ske genom brev, e-post, telefon, sms, Mina Sidor eller genom
                    www.everydayplus.se. Kredittagaren är medveten om och accepterar att information
                    om krediten, såsom saldo, uppgift om förfallet belopp, betalningspåminnelse m.m,
                    kan komma att sändas ut via SMS till av Kredittagaren uppgivet mobilnummer
                    och/eller via e-post till av Kredittagaren uppgivet e-postadress. Om mobilnumret
                    och e-postadressen ändras ska Kredittagaren omedelbart underrätta Kreditgivaren
                    om det nya numret/e-postadressen. Vid var tid gällande Allmänna villkor samt
                    aktuella räntor och avgifter finns tillgängliga på www.everydayplus.se, Mina
                    Sidor samt genom Kreditgivarens kundservice.
                </InfoContent>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>3. Ansökan om Kredit</InfoTitle>
                <InfoContent>
                    Innan kreditbeslut fattas och till underlag vid individuell kreditprövning
                    kommer Kreditgivaren att använda de uppgifter som lämnas i ansökan om kredit,
                    uppgifter som finns tillgängliga vid det kreditupplysningsinstitut som
                    Kreditgivaren samarbetar med samt utdrag från folkbokföringsregistret.
                    <br />
                    <br />
                    Kreditgivaren förbehåller sig rätten att genomföra verifieringssamtal för att
                    slutföra ansökan och inhämta kompletterande information och dokumentation om
                    Kredittagarens ekonomiska situation samt rätten att utföra ytterligare och
                    upprepade kreditbedömningar, förutsatt att en sådan bedömning är berättigad
                    genom lämplig rättslig grund.
                    <br />
                    <br />
                    I samband med kreditbedömning, kan en tredjepartstjänst användas om
                    Kredittagaren specifikt godkänner användningen av tjänsten och bemyndigar
                    tredjepartstjänsten att samla informationen från Kredittagarens bankkonto och
                    dela den samlade informationen med Kreditgivaren. Härutöver kan uppgifter om
                    krediten komma att lämnas till ett kreditupplysningsföretag i syfte att skydda
                    legitima intressen hos Kreditgivaren och andra Kreditgivare på marknaden. Ett
                    sådant kreditupplysningsföretag kan komma att föra in uppgifterna om krediten i
                    ett kreditregister för att uppfylla avtalsenliga överenskommelser och för att
                    skydda Kreditgivarens rättigheter. Övriga upplysningar om uppgiftsöverföring
                    till kreditupplysningsföretag kan erhållas hos Kreditgivaren.
                    <br />
                    <br />
                    Det är enbart OPR-Finance AB som beslutar om en person kan beviljas ett lån. Om
                    Kreditgivaren godkänner Kredittagarens ansökan, beviljar Kreditgivaren den
                    högsta kreditgränsen inom spannet som kan beviljas efter kreditbedömningen. Om
                    Kreditgivaren inte beviljar krediten och orsaken till detta beror på uppgifter
                    hämtade från det kreditupplysningsinstitut som Kreditgivaren samarbetar med
                    kommer den som ansökt om krediten att upplysas om de uppgifter som legat till
                    grund för att krediten inte beviljats.
                    <br />
                    <br />
                    Förutsatt att krediten beviljas bekräftar Kredittagaren på ansökan att
                    information om gällande räntesats, effektiv ränta och avgifter har lämnats till
                    Kredittagaren innan avtal träffats och att Kredittagaren har tagit del av dessa
                    allmänna villkor. Det beviljade kreditbeloppet är beroende av kreditprövningen
                    avseende Kredittagaren. Kreditavtalet samt Allmänna villkor och SEKKI signeras i
                    samband med slutförande av ansökan. En kopia på avtalet kommer finnas
                    tillgänglig på Mina Sidor.
                </InfoContent>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>4. Kreditgräns och användningen av EverydayPlus Krediten</InfoTitle>
                <InfoContent>
                    Beviljad kredit är en s.k. revolverande kontokredit. Det maximala belopp som
                    Kredittagaren kan nyttja framgår av det individuella kreditavtalet. Krediten
                    löper på obestämd tid. Beviljad kreditgräns kan höjas efter en ny ansökan och
                    särskild kreditprövning.
                    <br />
                    <br />
                    Kredittagaren har rätt att med de begränsningar som anges i dessa villkor fritt
                    nyttja krediten upp till ett belopp motsvarande kreditutrymmet. Pengarna
                    överförs till Kredittagarens uppgivna bankkonto under kundservices öppettider.
                    Utbetalningen sker genast då Kredittagaren skickat sms eller begärt överföring
                    till sitt bankkonto. När Kredittagaren begär att ett visst belopp överförs
                    minskar kreditutrymmet med motsvarande belopp. När Kredittagaren amorterar
                    krediten med visst belopp ökar kreditutrymmet med motsvarande belopp.
                    <br />
                    <br />
                    Vid ansökan om kredit ska Kredittagaren ange ett svenskt bankkonto till vilken
                    kreditöverföringarna ska ske och ett svenskt mobiltelefonnummer genom vilket
                    överföringarna från EverydayPlus Krediten kan göras.
                    <br />
                    <br />
                    Instruktionen för uttag och överföring av EverydayPlus krediten samt öppettider
                    finns att läsa på www.everydayplus.se hemsida.
                </InfoContent>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>
                    5. Återbetalning och kostnader relaterade till användandet av Krediten
                </InfoTitle>
            </InfoContainer>
            <InfoContainer>
                <SubInfoTitle>5.1 Återbetalning och autogiro</SubInfoTitle>
                <InfoContent>
                    Krediten skall, med sådant belopp som följer av dessa allmänna villkor,
                    återbetalas i månatliga återbetalningar genom autogiro. Kreditgivaren genom Svea
                    Bank AB (bankgironummer 5466-3844) debiterar Kredittagarens konto med lägsta
                    belopp att betala på aviserad förfallodag. Om förfallodagen infaller på en
                    helgdag görs uttag nästkommande bankdag. Täckningskontroll görs bankdagen innan
                    förfallodagen. Kontoförande bank är inte skyldig att pröva behörigheten av
                    gjorda uttag eller särskilt avisera dessa. Kredittagaren förbinder sig att hålla
                    tillräckligt belopp tillgängligt på kontot senast dagen före förfallodagen.
                    <br />
                    <br />
                    Har autogiro inte kunnat anslutas, ansvarar Kredittagaren själv för inbetalning
                    av lägsta belopp. Kredittagaren medger att betalning av krediten skall ske via
                    autogiro från i ansökan angivet bankkonto till bankgironummer 5466-3844.
                    <br />
                    <br />
                    Kredittagaren befullmäktigar Kreditgivaren att hos kontoförande bank kontrollera
                    att Kredittagaren är innehavare till i ansökan angivet bankkonto. Betalaren
                    samtycker till att behandling av personuppgifter som lämnats i detta medgivande
                    behandlas av betalarens betaltjänstleverantör, betalningsmottagaren,
                    betalningsmottagarens betaltjänstleverantör och Bankgirocentralen BGC AB för
                    administration av tjänsten.
                    <br />
                    <br />
                    Personuppgiftsansvariga för denna personuppgiftsbehandling är betalarens
                    betaltjänstleverantör, betalningsmottagaren samt betalningsmottagarens
                    betaltjänstleverantör. Betalaren kan när som helst begära att få tillgång till
                    eller rättelse av personuppgifterna genom att kontakta betalarens
                    betaltjänstleverantör. Ytterligare information om behandling av personuppgifter
                    i samband med betalningar kan finnas i villkoren för kontot och i avtalet med
                    betalningsmottagaren.
                    <br />
                    <br />
                    Betalaren kan när som helst återkalla sitt samtycke, vilket medför att tjänsten
                    i sin helhet avslutas.
                    <br />
                    <br />
                    Inför varje förfallodag skickar Kreditgivaren en faktura till Kreditgivarens
                    EverydayPlus Mina Sidor. Det minsta belopp som Kredittagaren är skyldig att
                    betala är 4 % av den totala kapitalskulden dock aldrig mindre än 200 kronor,
                    samt tillkommande avgifter och ränta vid fakturatillfället. Om kvarstående
                    kapitalskuld är lika med eller lägre än 200 kronor så skall kapital, avgifter
                    och ränta slutbetalas på förfallodagen.
                    <br />
                    <br />
                    Belopp som återbetalas skall först anses utgöra återbetalning av avgifter för
                    EverydayPlus Krediten, därefter betalning av ränta och därefter betalning av
                    kapitalbelopp.
                    <br />
                    <br />
                    Kredittagaren har när som helst rätt att förtidsbetala kvarvarande krediten helt
                    eller delvis utan avgift. Vid förtidslösen ombeds Kredittagaren kontakta
                    Kreditgivaren för upplysning om aktuellt kreditbelopp.
                </InfoContent>
            </InfoContainer>
            <InfoContainer>
                <SubInfoTitle>5.2 Medgivande till autogiro</SubInfoTitle>
                <InfoContent>
                    Jag, i dessa allmänna villkor och ovannämnd Kredittagare, medger att uttag får
                    göras från mitt bankkonto, enligt ovan villkor (5.1), på begäran av
                    Kreditgivaren för betalning via autogiro.
                </InfoContent>
            </InfoContainer>
            <InfoContainer>
                <SubInfoTitle>5.3 Ränta och avgifter</SubInfoTitle>
                <InfoContent>
                    Räntan för krediten är rörlig och framgår av kreditavtalet. Månadsräntan
                    beräknas från den dag då krediten utnyttjades till den dag då återbetalning
                    sker. Utöver ränta är Kredittagaren skyldig att betala uppläggningsavgift på
                    första fakturan. Varje uttag har en uttagsavgift som faktureras efter varje
                    uttag. Vid varje fakturering adderas en administrationsavgift. Kreditgivaren får
                    när som helst under avtalstiden besluta om att tillämpa eller höja avgifter samt
                    ränta i den mån Kreditgivarens kostnader för den åtgärd avgiften avser att täcka
                    har ökat. Om så sker kommer Kredittagaren meddelas om detta via e-post, Mina
                    sidor eller www.everydayplus.se senast när förändringen träder i kraft.
                    <br />
                    <br />
                    Kredittagaren måste också betala SMS-avgiften på hans eller hennes
                    telefonräkning som uppkommit vid överföringen.
                    <br />
                    <br />
                    Exempel beräknat på vanligaste förekommande uttag:
                    <br />
                    <br />
                    <strong>Återbetalningstid 12 månader:</strong>
                    <br />
                    <br />
                    3 000 kronor uttag på 12 månader med 39 % nominell ränta. Om man betalar det
                    minsta beloppet (4 % av totala kapitalskulden eller 200 kronor, vilket som är
                    högst) samt tillkommande avgifter och räntan varje månad i 11 månader och 865
                    kronor i sista månaden, blir effektiv räntan 113,46 % (beräknat per den 1 juni
                    2020, beräknat i enlighet med konsumentkreditlagen).
                    <br />
                    <br />
                    Att återbetala: 4 654 kronor inkl. alla avgifter. Inkluderade avgifter avser
                    uppläggningsavgift, uttagsavgift samt administrationsavgift.
                    <br />
                    <br />
                    Nedan finns ett exempel på ett uttag om 3 000 kronor där man betalar minsta
                    belopp (4 % av totala kapitalskulden eller 200 kronor, vilket som är högst) samt
                    tillkommande avgifter och ränta varje månad. Aviseringsplanen nedan är
                    preliminär och kan variera beroende på när i månaden man gör ett uttag.
                    <br />
                    <br />
                    I nedan exempel gör vi antagandet att man endast gör ett uttag och att det
                    alltid är 30 dagar mellan betalningstillfällena. Nedan skall endast ses som ett
                    exempel för att förstå hur lånet återbetalas. För den första månaden debiteras
                    det en uppläggningsavgift samt uttagsavgift. Varje månad debiteras en
                    administrationsavgift.
                    <br />
                    <br />
                    <Table>
                        <thead>
                            <Tr>
                                <Td>Avi</Td>
                                <Td>Månadsbetalning</Td>
                                <Td>Skuld</Td>
                                <Td>Kapital</Td>
                                <Td>Ränta</Td>
                                <Td>Amortering</Td>
                                <Td>Avgifter</Td>
                            </Tr>
                        </thead>
                        <tbody>
                            {getTermsAndConditionsPrices().map((data, index) => {
                                return (
                                    <Tr key={`price-${index}`}>
                                        <Td>{data.month}</Td>
                                        <Td>{data.monthlyPayment}</Td>
                                        <Td>{data.debt}</Td>
                                        <Td>{data.capital}</Td>
                                        <Td>{data.interest}</Td>
                                        <Td>{data.amortization}</Td>
                                        <Td>{data.fees}</Td>
                                    </Tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    <br />
                    <br />
                    Kredittagaren har möjlighet att återbetala ett högre belopp än det minsta belopp
                    som stadgas i kreditavtalet utan att åsamkas kostnader för denna
                    förtidsåterbetalning. Denna betalning kan dock inte vara större än den
                    utestående skulden. Det belopp som överskrider återbetalningsbeloppet som
                    stadgas i kreditavtalet används till att amortera Kredittagarens utestående
                    skuld.
                </InfoContent>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>6. Dröjsmålsränta och påminnelseavgifter</InfoTitle>
                <InfoContent>
                    Om betalning inte sker i tid, ska Kredittagaren betala en påminnelseavgift om 60
                    kronor. Kreditgivaren har också rätt att ta ut dröjsmålsränta på det förfallna
                    beloppet till dess full betalning av förfallen skuld sker. Dröjsmålsränta utgår
                    med högst 40 % över referensräntan. Utebliven betalning medför att Kreditgivaren
                    överför skulden till ett inkassoföretag alternativt Kronofogdemyndigheten. Alla
                    inkassokostnader debiteras från Kredittagaren.
                    <br />
                    <br />
                    Ärendet kan även komma att överföras till domstol för juridisk prövning. Alla
                    kostnader som uppkommer hos kronofogdemyndigheten eller domstol debiteras
                    Kredittagaren enligt rådande lag. Kreditgivaren har rätt att överföra fordringen
                    till tredje man efter meddelande till Kredittagaren. Kredittagaren har inte rätt
                    att överlåta lånet, rättigheterna eller skyldigheterna i enlighet med
                    Kreditavtalet.
                    <br />
                    <br />
                    Utebliven betalning trots inkassokrav kan komma att registreras i ett
                    kreditupplysningsregister.
                </InfoContent>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>7. Avtalets giltighetstid</InfoTitle>
                <InfoContent>Avtalet om kontokredit gäller tills vidare.</InfoContent>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>8. Uppsägning av kreditavtalet</InfoTitle>
                <InfoContent>
                    Uppsägningstiden för kreditavtalet är en (1) månad för Kredittagaren och två (2)
                    månader för Kreditgivaren. Uppsägning skall ske skriftligen per brev eller
                    e-post. När uppsägning skett skall utestående skuld återbetalas (i enlighet med
                    vad som anges i det fullständiga avtalet mellan Kredittagaren och Kreditgivaren,
                    inklusive upplupna räntor och avgifter) till Kreditgivaren. Kredittagaren är
                    skyldig att betala ränta och avgifter för den använda lånetiden. Kreditgivaren
                    har rätt att ta ut den faktiska årliga räntan för perioden krediten varit
                    Kredittagaren till handa. Kredittagaren förbinder sig att återlämna till
                    Kreditgivaren, utan dröjsmål och senast 30 dagar efter att uppsägningen skickats
                    till Kreditgivaren, de medel som han eller hon uppburit från Kreditgivaren. Om
                    Kredittagaren inte återbetalar krediten inom ovan stadgad tidsperiod förfaller
                    uppsägningen.
                    <br />
                    <br />
                    Kontokrediten får inte användas om det finns fordringar som förfallit till
                    betalning, vilket också kan föranleda förtidsbetalning, se punkt 9 nedan.
                    Kreditgivaren förbehåller sig rätten att omedelbart sänka, begränsa eller säga
                    upp en outnyttjad kredit efter 12 månader från kreditens beviljande.
                    Kreditgivaren äger vidare med omedelbar verkan säga upp avtalet om Kredittagaren
                    visar sig vara på obestånd, avlider eller om förvaltare förordnas enligt 11 kap.
                    7 § föräldrabalken. Vid dödsfall övergår samtliga kvarstående förpliktelser till
                    dödsboet. Vid uppsägning ska Kredittagaren omedelbart underrättas om ändringen
                    samt skälen därtill.
                </InfoContent>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>9. Särskilda förfallovillkor</InfoTitle>
                <InfoContent>
                    Kredittagarens totala utestående skuld förfaller till omedelbar förtida
                    återbetalning, efter skriftlig begäran av Kreditgivaren, om Kredittagaren inte
                    fullföljer sin skyldighet att återbetala EverydayPlus krediten i enlighet med
                    vad som följer av det fullständiga kreditavtalet. Detta förutsätter dock att
                    Kredittagaren är minst en månad försenad med betalningen och det förfallna
                    beloppet måste, om det förfallna beloppet inte avser hela den utestående
                    skulden, uppgå till minst 10 %, eller om det inkluderar mer än en återbetalning,
                    minst 5 % av kreditutrymmet.
                    <br />
                    <br />
                    Kredittagarens totala utestående skuld kan även förfalla till omedelbar förtida
                    återbetalning, efter skriftlig begäran av Kreditgivaren, om bedömning skett att
                    väsentligt dröjsmål med betalning föreligger utöver vad som nämns i första
                    meningen, alternativt om risk bedöms föreligga för att Kredittagaren avviker,
                    risk bedöms föreligga för undanskaffande av egendom eller på något annat sätt
                    risk föreligger för att Kredittagaren undandrar sig sitt skuldåtagande.
                    <br />
                    <br />
                    Den förtida återbetalningen förfaller inom fyra veckor eller om Kredittagaren
                    tidigare erhållit betalningspåminnelse eller på annat sätt upplysts om att han
                    eller hon brutit mot kreditavtalet, inom två veckor från den dag då
                    betalningspåminnelsen skickats till Kredittagaren. För det fall Kredittagaren
                    betalar det förfallna beloppet inom nämnda period upphör kravet på förtida
                    återbetalning. Kreditgivaren följer vad som stadgas i 8 § räntelagen (1975:635)
                    om jämkning av dröjsmålsränta vid sjukdom, arbetslöshet, dödsfall eller annan
                    liknande omständighet. Hela den utestående skulden faller omedelbart till
                    betalning, efter meddelande från Kreditgivaren, för det fall Kredittagaren
                    avlider eller försätts i konkurs.
                </InfoContent>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>10. Kontokredithavarens skyldighet att lämna uppgifter </InfoTitle>
                <InfoContent>
                    Kredittagaren förbinder sig att på begäran ge Kreditgivaren nödvändiga uppgifter
                    om sin ekonomiska ställning och omständigheter som påverkar kontokredithavarens
                    betalningsförmåga.
                </InfoContent>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>11. Restriktioner i användandet av EverydayPlus Krediten</InfoTitle>
                <InfoContent>
                    För det fall Kredittagaren underlåter att betala vid förfallodagen eller på
                    annat sätt handlar i strid med dessa villkor har Kreditgivaren rätt att begränsa
                    Kredittagarens rätt att nyttja Krediten genom att förhindra att nya överföringar
                    eller genom att reducera Kredittagarens beviljade kreditgräns. Kreditgivaren har
                    även denna möjlighet om Kredittagaren ådrar sig andra betalningsanmärkningar,
                    begår annat avtalsbrott eller inte fullgör annan betalning.
                </InfoContent>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>12. Behandling av personuppgifter </InfoTitle>
                <InfoContent>
                    Grunden för behandlingen av personuppgifter är att företaget har ett berättigat
                    intresse på grund av en kundrelation och för att genomföra ett kontrakt. Behovet
                    av att behandla personuppgifter är baserat på:
                    <br />
                    <br />
                    - leveransen och utvecklingen av våra produkter och tjänster
                    <br />
                    - uppfyllande av våra kontraktsenliga och andra löften och skyldigheter
                    <br />
                    - skötseln av kundrelationen
                    <br />
                    - analys och profilering av beteendet hos en kund eller någon annan registrerad
                    <br />
                    - elektronisk och direkt marknadsföring
                    <br />
                    - inriktad annonsering i våra och andras onlinetjänster
                    <br />
                    <br />
                    Vi kan använda automatiserat beslutsfattande (inkl. profilering) för att
                    identifiera till exempel den registrerades personprofil, beteende online, ålder,
                    konsumentvanor. Vi använder denna information till exempel i beslutsfattande,
                    inriktad marknadsföring och utveckling av tjänster.
                    <br />
                    <br />
                    OPR-Finance AB tar din integritet på största allvar och gör allt för att skydda
                    dina personuppgifter. För detaljer, se vår OPR-Finance AB Integritetspolicy som
                    finns på www.everydayplus.se.
                </InfoContent>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>13. Marknadsföring</InfoTitle>
                <InfoContent>
                    Kreditgivaren, andra bolag i den koncern till vilken Kreditgivaren tillhör samt
                    andra samarbetspartners till Kreditgivaren är berättigade att använda
                    informationen i kundregistret i marknadsföringssyfte.
                    <br />
                    <br />
                    Kreditgivaren behandlar kredituppgifter genom att samla in, spara, bearbeta och
                    analysera användandet av Kreditgivarens tjänster i syfte att skräddarsy
                    erbjudanden. Marknadsföringen sker via t.ex. brev, telefon, sms och e-post.
                    Kreditgivaren behandlar kunduppgifter, exempelvis genom att analysera och
                    bearbeta statistik, för att kunna erbjuda Kredittagaren relevanta erbjudanden
                    avseende våra tjänster.
                    <br />
                    <br />
                    Kreditgivaren är enligt marknadsföringsregler skyldiga att se till att
                    Kredittagaren kan spärra sig om denna inte längre vill ha vår marknadsföring.
                    Det kan Kredittagaren göra genom att kontakta kundservice@everydayplus.se eller
                    ringa Kreditgivaren på 08 225 115 och be om hjälp med att lägga in en spärr.
                </InfoContent>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>14. Övriga villkor</InfoTitle>
                <InfoContent>
                    Kreditgivaren har rätt att överlåta/pantsätta kreditavtalet och samtliga
                    relaterade rättigheter och skyldigheter till tredje man, helt eller delvis,
                    efter att meddelat Kredittagaren därom. Kredittagaren är ansvarig för att de
                    uppgifter han eller hon lämnar är korrekta. Kredittagaren måste omedelbart
                    informera Kreditgivaren vid namnbyte eller vid adressbyte. Kreditgivaren är inte
                    ansvarig gentemot Kredittagaren om Kredittagaren är förhindrad att nyttja medlen
                    som överförts anvisat bankkonto, på grund av avsaknad av rättighet till kontot,
                    avsaknad av kontoskuld, avsaknad av betalningsmedel etc. eller liknande orsak
                    oavsett om denna är inom eller utom Kredittagarens kontroll. Den överförda
                    krediten betalas in till det konto som Kredittagaren angivit. Kredittagaren
                    måste försäkra sig om att hans eller hennes personliga ekonomiska situation
                    lever upp till villkoren enligt kreditavtalet. Kreditavtalet är pantsatt till
                    Svea Bank AB. Betalning med befriande verkan kan därför endast ske till Svea
                    Bank ABs konton som anges vid den månatliga aviseringen.
                </InfoContent>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>15. Information om distansavtal och ångerrätt</InfoTitle>
                <InfoContent>
                    Distansavtalslagen gäller endast för konsumenter. Ett distansavtal är när kund
                    och ett företag inte träffas personligen innan avtalet ingås. Några exempel på
                    distansavtal är avtal via internet, telefonförsäljning eller svar på
                    brevutskick/annons.
                    <br />
                    <br />
                    Om Kredittagaren ångrar sig och inte längre önskar krediten har Kredittagaren
                    rätt att frånträda avtalet om krediten. Kredittagaren har så kallad ångerrätt.
                    Ångerrätter gäller enbart själva avtalet och inte de transaktioner, tjänster
                    eller motsvarande som utförts under avtalet.
                    <br />
                    <br />
                    Om Kredittagaren vill utöva sin ångerrätt så ska Kredittagaren lämna eller sända
                    ett meddelande till Kreditgivaren inom 14 dagar från den dagen då avtalet
                    ingicks och Kredittagaren godkände de allmänna villkoren. Kredittagaren ska
                    snarast och senast inom 30 dagar från den dag då Kredittagaren lämnade eller
                    sände sitt meddelande om att avtalet frånträds betala tillbaka hela
                    kreditbeloppet med tillägg för upplupen ränta. Räntan ska utgå från den dag
                    Kredittagaren fick tillgång till krediten t.o.m. den dag den återbetalats till
                    Kreditgivaren. Kreditgivaren ska senast inom 30 dagar från den dagen då
                    Kreditgivaren tog emot Kredittagarens meddelande om att avtalet frånträds
                    återbetala de avgifter som kredittagaren erlagt med anledning av krediten med
                    undantag för de utgifter som Kreditgivaren kan ha erlagt till det allmänna.
                    <br />
                    <br />
                    Från det belopp som ska återbetalas får dock Kreditgivaren avräkna belopp som
                    motsvarar den del av den finansiella tjänst som har tillhandhållits och även
                    skäliga kostnader för tiden före det Kreditgivaren tog emot Kredittagarens
                    meddelande om att avtalet frånträtts. Om pris utgår för krediten återbetalar
                    Kreditgivaren uppläggningsavgiften som är debiterad.
                    <br />
                    <br />
                    Meddelande ska innehålla minst följande information:
                    <br />
                    <br />
                    1. Namn och personnummer på Kredittagaren
                    <br />
                    2. Bekräftelse att avtalet ångras
                    <br />
                    3. Plats och datum
                    <br />
                    4. Kredittagarens underskrift
                    <br />
                    <br />
                    Vid ånger av kredit inom 14 dagar mejlar Kredittagare
                    kundservice@everydayplus.se
                    <br />
                    <br />
                    Begäran kan även skickas till Kreditgivaren på följande adress: OPR-Finance AB,
                    Kungsbroplan 1, 112 27 Stockholm.
                </InfoContent>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>16. Serviceansvar</InfoTitle>
                <InfoContent>
                    Kreditgivaren ansvarar inte för några direkta eller indirekta skador som orsakas
                    av fel eller brister som uppkommit med anledning av den tjänst som Kreditgivaren
                    tillhandahåller och som ligger utom Kreditgivarens kontroll. Kreditgivaren
                    ansvarar inte för några fel eller brister avseende kommunikation, att
                    meddelanden försenas eller försvinner, felaktiga meddelanden etc. oberoende av
                    huruvida felen eller bristerna är att hänföra problem avseende kommunikation med
                    telefonbolag eller andra som tillhandahåller tjänster till Kreditgivaren,
                    upptagna telefonlinjer, strömavbrott, trasig utrustning etc. och kommer inte att
                    ersätta några direkta eller indirekta skador orsakade av sådant fel.
                    Kreditgivaren är inte heller ansvarig för fel eller brister avseende andra
                    tjänsteleverantörer relaterade till nyttjandet av EverydayPlus krediten.
                    Kreditgivaren är inte ansvarig för skador orsakade av force majeure eller
                    liknande händelse.
                </InfoContent>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>17. Tillägg och ändringar</InfoTitle>
                <InfoContent>
                    Kreditgivaren har rätt att ändra kreditvillkoren, så länge ändringarna inte ökar
                    Kredittagarens förpliktelser eller inskränker Kredittagarens rättigheter.
                    Kreditgivaren har alltid rätt att ändra kreditvillkoren när lag eller annat
                    offentligt beslut så påbjuder. Oberoende av vad som anges ovan gäller att om
                    ändringen ökar Kredittagarens skyldigheter eller inskränker Kredittagarens
                    rättigheter är Kreditgivaren skyldig att skriftligen informera Kredittagaren
                    senast tre månader innan ändringen träder i kraft. Om Kredittagaren motsätter
                    sig ändringen av villkoren har han eller hon rätt att säga upp avtalet i
                    enlighet med sektionen ”Uppsägning av kreditavtalet”.
                </InfoContent>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>18. Tvist och klagomål</InfoTitle>
                <InfoContent>
                    Är Kredittagaren inte nöjd med det beslut denna har fått gällande klagomål eller
                    känner att han eller hon vill ta frågan vidare, kan Kredittagaren vända sig till
                    Kundombudsman på OPR-Finance AB. Meddelande ska helst vara skriftligt och
                    beskriva vad som gick fel med hanteringen av ärendet.
                    <br />
                    <br />
                    Email: kundombudsman@opr-finance.se
                    <br />
                    Telefon: +46 (0)8 225 115 (EverydayPlus)
                    <br />
                    <br />
                    Om tvist uppkommer med anledning av detta låneavtal och denna tvist inte kan
                    lösas genom förhandlingar mellan parterna kan Kredittagaren hänskjuta tvisten
                    till Allmänna Reklamationsnämnden, box 174, 101 23 Stockholm, telefon 08 508 860
                    00.
                    <br />
                    <br />
                    För det fall inte tvist kan lösas genom ovanstående förfarande ska tvist avgöras
                    vid allmän domstol. Svensk lag gäller för dessa villkor. Utförlig information
                    avseende krediten tillhandahålls i enlighet med svensk lag. Kontaktuppgifter:
                    OPR-Finance AB, Kungsbroplan 1, 112 27 Stockholm.
                </InfoContent>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>19. Tillsynsmyndighet</InfoTitle>
                <InfoContent>
                    Tillsynsmyndighet för Kreditgivarens verksamhet är Finansinspektionen, Box 7821,
                    103 97 Stockholm.
                </InfoContent>
            </InfoContainer>
            <br />
            <br />
            <br />

            <TitleWrapper>
                <Title>bankgirot.se</Title>
                <Title>Autogiroanmälan, medgivande till kontonummer</Title>
            </TitleWrapper>

            <InfoContainer>
                <InfoTitle>Allmänt</InfoTitle>
                <InfoContent>
                    Autogiro är en betaltjänst som innebär att betalningar utförs från betalarens
                    konto på initiativ av betalningsmottagaren. För att betalaren ska kunna betala
                    via Autogiro, ska betalaren lämna sitt medgivande till betalningsmottagaren om
                    att denne får initiera betalningar från betalarens konto. Dessutom ska
                    betalarens betaltjänstleverantör (t ex bank eller betalningsinstitut) godkänna
                    att kontot kan användas för Autogiro och betalningsmottagaren ska godkänna
                    betalaren som användare av Autogiro.
                    <br />
                    <br />
                    Betalarens betaltjänstleverantör är inte skyldig att pröva behörigheten av eller
                    meddela betalaren i förväg om begärda uttag. Uttag belastas betalarens konto
                    enligt de regler som gäller hos betalarens betaltjänstleverantör. Meddelande om
                    uttag får betalaren från sin betaltjänstleverantör. Medgivandet kan på
                    betalarens begäran överflyttas till annat konto hos betaltjänstleverantören
                    eller till konto hos annan betaltjänstleverantör.
                </InfoContent>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>Definition av bankdag</InfoTitle>
                <InfoContent>
                    Med bankdag avses alla dagar utom lördag, söndag, midsommarafton, julafton eller
                    nyårsafton eller annan allmän helgdag.
                </InfoContent>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>Information om betalning</InfoTitle>
                <InfoContent>
                    Betalaren kommer av betalningsmottagaren att meddelas belopp, förfallodag och
                    betalningssätt senast åtta bankdagar före förfallodagen. Detta kan meddelas
                    inför varje enskild förfallodag eller vid ett tillfälle avseende flera framtida
                    förfallodagar. Om meddelandet avser flera framtida förfallodagar ska meddelandet
                    lämnas senast åtta bankdagar före den första förfallodagen. Detta gäller dock
                    inte fall då betalaren godkänt uttaget i samband med köp eller beställning av
                    vara eller tjänst. I sådant fall får betalaren meddelande av
                    betalningsmottagaren om belopp, förfallodag och betalningssätt i samband med
                    köpet och/eller beställningen. Genom undertecknandet av detta medgivande lämnar
                    betalaren sitt samtycke till att betalningar som omfattas av
                    betalningsmottagarens meddelande enligt denna punkt genomförs.
                </InfoContent>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>Täckning måste finnas på kontot</InfoTitle>
                <InfoContent>
                    Betalaren ska se till att täckning finns på kontot senast kl. 00.01 på
                    förfallodagen. Har betalaren inte täckning på kontot på förfallodagen kan det
                    innebära att betalningar inte blir utförda. Om täckning saknas för betalning på
                    förfallodagen får betalningsmottagaren göra ytterligare uttagsförsök under de
                    kommande bankdagarna. Betalaren kan på begäran få information från
                    betalningsmottagaren om antalet uttagsförsök.
                </InfoContent>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>Stoppa betalning (återkallelse av betalningsorder)</InfoTitle>
                <InfoContent>
                    Betalaren får stoppa en betalning genom att kontakta antingen
                    betalningsmottagaren senast två bankdagar före förfallodagen eller sin
                    betaltjänstleverantör senast bankdagen före förfallodagen vid den tidpunkt som
                    anges av betaltjänstleverantören. Om betalaren stoppar en betalning enligt ovan
                    innebär det att den aktuella betalningen stoppas vid ett enskilt tillfälle. Om
                    betalaren vill att samtliga framtida betalningar som initieras av
                    betalningsmottagaren ska stoppas måste betalaren återkalla medgivandet.
                </InfoContent>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>Medgivandets giltighetstid, återkallelse</InfoTitle>
                <InfoContent>
                    Medgivandet gäller tills vidare. Betalaren har rätt att när som helst återkalla
                    medgivandet genom att kontakta betalningsmottagaren eller sin
                    betaltjänstleverantör. Meddelandet om återkallelse av medgivandet ska för att
                    stoppa ännu inte genomförda betalningar vara betalningsmottagaren tillhanda
                    senast fem bankdagar före förfallodagen alternativt vara betalarens
                    betaltjänstleverantör tillhanda senast bankdagen före förfallodagen vid den
                    tidpunkt som anges av betaltjänstleverantören.
                </InfoContent>
            </InfoContainer>
            <InfoContainer>
                <InfoTitle>
                    Rätten för betalningsmottagaren och betalarens betaltjänstleverantör att avsluta
                    anslutningen till Autogiro
                </InfoTitle>
                <InfoContent>
                    Betalningsmottagaren har rätt att avsluta betalarens anslutning till Autogiro
                    trettio dagar efter det att betalningsmottagaren underrättat betalaren härom.
                    Betalningsmottagaren har dock rätt att omedelbart avsluta betalarens anslutning
                    till Autogiro om betalaren vid upprepade tillfällen inte har haft tillräcklig
                    kontobehållning på förfallodagen eller om det konto som medgivandet avser
                    avslutas eller om betalningsmottagaren bedömer att betalaren av annan anledning
                    inte bör delta i Autogiro.
                    <br />
                    <br />
                    Betalarens betaltjänstleverantör har rätt att avsluta betalarens anslutning till
                    Autogiro i enlighet med de villkor som gäller mellan betalarens
                    betaltjänstleverantör och betalaren.
                </InfoContent>
            </InfoContainer>
        </Container>
    );
}
