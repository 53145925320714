import React, { FunctionComponent } from "react";
import ReactModal from "react-modal";
import ReactMarkdown from "react-markdown";
import { Icon } from "@opr-finance/component-icon";
import { Font, Text } from "@opr-finance/component-fonts";
import {
    StyledModal,
    StyledTitle,
    StyledCloseIcon,
    ModalStyled,
    ModalTitle,
    ModalCloseIcon,
} from "./ModalDialog.styled";
import { ModalDialogProps, ModalDialogOverlayProps, ContnentProps, ModalProps } from "./types";

ReactModal.setAppElement("#root");

const modalStyles: ModalDialogOverlayProps = {
    overlay: {
        backgroundColor: "rgba(0,0,0,0.4)",
        display: "flex",
        zIndex: 1500,
    },
};

export const ModalDialog: FunctionComponent<ModalDialogProps> = (props) => {
    return (
        <StyledModal style={modalStyles} isOpen={props.isOpen} variant={props.dialogVariant}>
            <StyledTitle variant={props.dialogVariant}>
                {props.modalTitle}
                <StyledCloseIcon onClick={props.onClick}>
                    <Icon icon={["fas", "times"]} size={"lg"} />
                </StyledCloseIcon>
            </StyledTitle>
            {props.children}
        </StyledModal>
    );
};

export const RichContent: FunctionComponent<ContnentProps> = (props) => {
    return (
        <>
            {props.createdDate && <Text variant="small">{props.createdDate}</Text>}
            <ReactMarkdown source={props.content} />
        </>
    );
};

// NOTE: New modal with styleConfig
export function Modal(props: ModalProps) {
    return (
        <ModalStyled
            style={{ overlay: props.styleConfig.overlay }}
            isOpen={props.isOpen}
            styleConfig={props.styleConfig}>
            <ModalTitle styleConfig={{ title: props.styleConfig.title }}>
                <Font styleConfig={{ root: props.styleConfig.titleText }}>{props.modalTitle}</Font>
                <ModalCloseIcon
                    onClick={props.onClick}
                    styleConfig={{ closeIcon: props.styleConfig.closeIcon }}>
                    <Icon icon={["fas", "times"]} size="lg" />
                </ModalCloseIcon>
            </ModalTitle>
            {props.children}
        </ModalStyled>
    );
}
