import React, { useState, useEffect } from "react";
import { SystemStyleObject } from "@styled-system/css";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Box } from "@opr-finance/component-box-container";
import { Font } from "@opr-finance/component-fonts";
import { FlexColumn, FlexRow, Grid, GridContainer, GridContent } from "@opr-finance/component-grid";
import { Logo } from "@opr-finance/component-logo";
import { Layout } from "@opr-finance/layout-everyday-application";
import { LabeledField, TextField } from "@opr-finance/component-forms";
import {
    E_EnableBankingActionConstants,
    T_EnableBankingBank,
} from "@opr-finance/feature-enable-banking";
import { breadcrumbTitle } from "@opr-finance/theme-everyday-application";
import { UserTokenData } from "@opr-finance/feature-luvittaja-login";
import { Loader } from "@opr-finance/component-loader";
import { Root } from "@opr-finance/feature-initializer/src/components/AppInitializer.styled";
import { enableBankingActions } from "@opr-finance/feature-enable-banking/src/actions/index.actions";
import { ButtonField } from "@opr-finance/component-forms";

import { AppState } from "../types/general";
import { Header } from "../components/Header";
import { PAGE_TITLE } from "../constants";
import logo from "../images/logo.svg";
import { FilteredBanks } from "../components/FilteredBanks";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { BREADCRUMB_STEPS, BREADCRUMB_STEP_CHOOSE_BANK } from "../constants";
import { Application } from "../types/general";
import { mapApplicationToLeandevRequest } from "../applicationMapper";
import { sortBanks, setMatches } from "../util";
import { Icon } from "@opr-finance/component-icon";
import styled from "styled-components";
import { AppActionConstants, appActions } from "../actions/actions";
import { AppInitializer } from "@opr-finance/feature-initializer";

type ChooseBankPageProps = {
    styleConfig: {
        headerTitle: SystemStyleObject;
        headerContainer: SystemStyleObject;
        headerContent: SystemStyleObject;
        box: SystemStyleObject;
        pageTitle: SystemStyleObject;
        body: SystemStyleObject;
        link: SystemStyleObject;
        banksContainer: SystemStyleObject;
        banksContent: SystemStyleObject;
        banksImage: SystemStyleObject;
        textField: SystemStyleObject;
        formItem: SystemStyleObject;
        formItemLabel: SystemStyleObject;
        formItemField: SystemStyleObject;
        breadCrumbBox: SystemStyleObject;
        buttonBack: SystemStyleObject;
        bankName: SystemStyleObject;
        imageContainer: SystemStyleObject;
    };
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
};

export function ChooseBank(props: ChooseBankPageProps) {
    const banks = useSelector((state: AppState) => state.enableBanking.entities);
    const urlFromFrends = useSelector((state: AppState) => state.enableBanking.config.url);
    const enableBankingError = useSelector(
        (state: AppState) => state.enableBanking.config.enableBankingError
    );
    const tokenData = useSelector((state: AppState) => {
        return state.user.tokenData as UserTokenData;
    });
    const [sparbankenIsChosen, setSparbankenIsChosen] = useState<boolean>(false);
    const [bankIsChosen, setBankIsChosen] = useState<boolean>(false);
    const [focused, setFocused] = useState<boolean>(false);
    const sortedBanks: T_EnableBankingBank[] = sortBanks(banks, sparbankenIsChosen, focused);
    const [filteredBanks, setFilteredBanks] = useState<T_EnableBankingBank[]>([]);
    const dispatch = useDispatch();

    const { state } = useLocation<Application>();

    const onFocus = () => {
        setFocused(true);
        sortBanks(banks, sparbankenIsChosen, focused);
    };

    const onBlur = () => {
        setFocused(false);
        sortBanks(banks, sparbankenIsChosen, focused);
    };

    const onChangeHandler = (e) => {
        let searchText = e.target.value;
        setFilteredBanks(setMatches(sortedBanks, searchText));
    };

    const onClickHandler = (bankName: string, bankCountry: string) => {
        const mappedApplication = mapApplicationToLeandevRequest(state, tokenData);
        dispatch(
            enableBankingActions.sendDataToEnableBankingTrigger({
                applicationData: mappedApplication,
                bankName,
                bankCountry,
            })
        );
        setBankIsChosen(true);
    };

    const onClickHandlerSparbankerna = () => {
        setSparbankenIsChosen(true);
        sortBanks(banks, sparbankenIsChosen, focused);
    };

    useEffect(() => {
        setFilteredBanks(sortedBanks);
    }, [sparbankenIsChosen, focused, banks]);

    useEffect(() => {
        if (urlFromFrends) {
            window.location.href = `${urlFromFrends}`;
        }
        if (enableBankingError) {
            setBankIsChosen(!bankIsChosen);
            console.log("enableBankingError: " + enableBankingError);
        }
    }, [urlFromFrends, enableBankingError]);

    useEffect(() => {
        dispatch(appActions.chooseBankTrigger());
    }, []);

    if (bankIsChosen)
        return (
            <Root>
                <Loader isLoading={true} />
            </Root>
        );

    return (
        <Layout
            header={
                <Header
                    logo={
                        <Logo
                            logoSrc={logo}
                            width={165}
                            height={35}
                            onClick={() => {
                                window.location.href = process.env
                                    .REACT_APP_MARKETING_PAGE_URL as string;
                            }}
                        />
                    }
                    title={
                        <Font styleConfig={{ root: props.styleConfig.headerTitle }}>
                            {PAGE_TITLE}
                        </Font>
                    }
                    styleConfig={{
                        container: props.styleConfig.headerContainer,
                        content: props.styleConfig.headerContent,
                    }}
                />
            }
        >
            <FlexColumn>
                <GridContainer>
                    <Grid width={7 / 10}>
                        <GridContent padding={["0 8px 40px 8px"]}>
                            <Box styleConfig={{ root: props.styleConfig.breadCrumbBox }}>
                                <Breadcrumbs
                                    steps={BREADCRUMB_STEPS}
                                    step={BREADCRUMB_STEP_CHOOSE_BANK}
                                    styleConfig={{
                                        step: breadcrumbTitle(),
                                    }}
                                />
                            </Box>
                        </GridContent>
                    </Grid>
                    <Grid width={3 / 10} />
                </GridContainer>
                <GridContainer>
                    <Grid width={7 / 10}>
                        <FlexColumn>
                            <GridContent>
                                <Box styleConfig={{ root: props.styleConfig.box }}>
                                    {banks.length > 0 ? (
                                        <>
                                            <FlexColumn>
                                                {sparbankenIsChosen && (
                                                    <ButtonField
                                                        styleConfig={{
                                                            root: props.styleConfig.buttonBack,
                                                        }}
                                                        onClick={() => {
                                                            setSparbankenIsChosen(false);
                                                        }}
                                                    >
                                                        <Icon icon={["fa", "caret-left"]} />{" "}
                                                        Tillbaka
                                                    </ButtonField>
                                                )}
                                                <LabeledField
                                                    styleConfig={{
                                                        container: props.styleConfig.formItem,
                                                        label: props.styleConfig.formItemLabel,
                                                        field: props.styleConfig.formItemField,
                                                    }}
                                                    label={""}
                                                    field={
                                                        <>
                                                            <TextField
                                                                styleConfig={{
                                                                    root: props.styleConfig
                                                                        .textField,
                                                                }}
                                                                inputConfig={{
                                                                    onChange: (e) => {
                                                                        onChangeHandler(e);
                                                                    },
                                                                    onFocus: () => {
                                                                        onFocus();
                                                                    },
                                                                    onBlur: () => {
                                                                        onBlur();
                                                                    },
                                                                    name: "searchBank",
                                                                    placeholder:
                                                                        "Sök efter din bank",
                                                                }}
                                                            />
                                                        </>
                                                    }
                                                />
                                            </FlexColumn>

                                            <Box
                                                styleConfig={{
                                                    root: props.styleConfig.banksContainer,
                                                }}
                                            >
                                                <FilteredBanks
                                                    filteredBanks={filteredBanks}
                                                    styleConfig={{
                                                        banksContent:
                                                            props.styleConfig.banksContent,
                                                        banksImage: props.styleConfig.banksImage,
                                                        bankName: props.styleConfig.bankName,
                                                        imageContainer:
                                                            props.styleConfig.imageContainer,
                                                    }}
                                                    onClickHandler={onClickHandler}
                                                    onClickHandlerSparbankerna={
                                                        onClickHandlerSparbankerna
                                                    }
                                                    sparbankenIsChosen={sparbankenIsChosen}
                                                    focused={focused}
                                                />
                                            </Box>
                                        </>
                                    ) : (
                                        <Root>
                                            <Loader isLoading={true} />
                                        </Root>
                                    )}
                                </Box>
                            </GridContent>
                        </FlexColumn>
                    </Grid>
                    <Grid width={3 / 10} />
                </GridContainer>
            </FlexColumn>
        </Layout>
    );
}
