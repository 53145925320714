import { ActionType, createAction } from "typesafe-actions";
import {
    E_PageInitializerActionConstants,
    T_PageInitializerActionCompletePayload,
    T_PageInitializerActionSuccessPayload,
    T_PageInitializerInitPayload,
} from "../types";

export const pageInitializerActions = {
    /* initPageInitializer: createAction(
        E_PageInitializerActionConstants.INIT_INITALIZER
    )<T_PageInitializerInitPayload>(), */
    initPageToPageInitializer: createAction(
        E_PageInitializerActionConstants.INIT_PAGE
    )<T_PageInitializerInitPayload>(),
    completePageInitializerAction: createAction(
        E_PageInitializerActionConstants.INIT_PAGE_ACTION_SUCCESS
    )<T_PageInitializerActionCompletePayload>(),
    initPageSuccess: createAction(
        E_PageInitializerActionConstants.INIT_PAGE_SUCCESS
    )<T_PageInitializerActionSuccessPayload>(),
    resetPageInitializer: createAction(
        E_PageInitializerActionConstants.INIT_PAGE_RESET_STATUS
    )<T_PageInitializerActionSuccessPayload>(),
    /* NOTE: Implement error action */
};

export type PageInitializerAction = ActionType<typeof pageInitializerActions>;
