import React, { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FeatureUserLoginState } from "../types/general";
import { userLoginActions } from "../actions/login";

export type AuthenticationProps = {
    children: ReactNode;
};

export function Authenticator(props: AuthenticationProps) {
    const { isValidating, isValidToken, isInitialized } = useSelector(
        (state: FeatureUserLoginState) => {
            return {
                isValidating: state.user.isValidating,
                isValidToken: state.user.isValidToken,
                isInitialized: state.user.isInitialized,
            };
        }
    );

    if (isValidating && !isInitialized) {
        return <div>Is validating...</div>;
    }

    if (!isValidToken) {
        return <div>Logged out</div>;
    }

    return <>{props.children}</>;
}
