import React, { ReactNode } from "react";
import { Font } from "@opr-finance/component-fonts";
import { SystemStyleObject } from "@styled-system/css";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    @media (max-width: 786px) {
        flex-direction: column;
    }
`;

const Item = styled.div<{
    isActive: boolean;
    children: ReactNode;
}>`
    @media (min-width: 786px) {
        border-bottom: ${(props) => (props.isActive ? "2px solid #2bace2" : "0")};
        padding-bottom: 4px;
    }
    @media (max-width: 786px) {
        font-weight: ${(props) => (props.isActive ? "bold" : "normal")};
    }
`;

export type BreadcrumbsProps = {
    steps: string[];
    step: string;
    styleConfig: {
        step: SystemStyleObject;
    };
};

export function Breadcrumbs(props: BreadcrumbsProps) {
    return (
        <Container>
            {props.steps.map((step, index) => {
                return (
                    <Font
                        key={`breadcrumb-${index}`}
                        styleConfig={{ root: props.styleConfig.step }}
                    >
                        <Item isActive={step === props.step}>
                            {index + 1}. {step}
                        </Item>
                    </Font>
                );
            })}
        </Container>
    );
}
