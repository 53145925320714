import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import { FontStyleProps } from "@opr-finance/utils";
import { Font } from "@opr-finance/component-fonts";
import { FlexColumn, Grid, GridContainer, GridContent } from "@opr-finance/component-grid";
import { Box } from "@opr-finance/component-box-container";
import { Authenticator, UserLoginActionConstants } from "@opr-finance/feature-luvittaja-login";
import { SystemStyleObject } from "@styled-system/css";
import { Layout } from "@opr-finance/layout-everyday-application";
import { Logo } from "@opr-finance/component-logo";

import { Breadcrumbs } from "../components/Breadcrumbs";
import { Header } from "../components/Header";
import logo from "../images/logo.svg";
import { BREADCRUMB_STEPS, BREADCRUMB_STEP_THANK_YOU, PAGE_TITLE } from "../constants";
import { T_EarlyReturnFromFrends } from "@opr-finance/feature-enable-banking/src/types/types";
import { AppState } from "../types/general";
import { AppInitializer } from "@opr-finance/feature-initializer";
import { AppActionConstants } from "../actions/actions";

export type ThankYouPageProps = {
    styleConfig: {
        headerTitle: SystemStyleObject;
        headerContainer: SystemStyleObject;
        headerContent: SystemStyleObject;
        pageTitle: FontStyleProps;
        body: FontStyleProps;
        breadcrumbTitle: FontStyleProps;
        breadcrumbBox: SystemStyleObject;
        box: SystemStyleObject;
    };
};

export function CompletedPage(props: ThankYouPageProps) {
    const history = useHistory();
    const tokenData = useSelector((state: AppState) => {
        return state.user.tokenData;
    });

    const { state } = useLocation<T_EarlyReturnFromFrends>();

    if (state.statusCode !== 200) {
        history.push("/error");
    }

    return (
        <Layout
            header={
                <Header
                    logo={
                        <Logo
                            logoSrc={logo}
                            width={165}
                            height={35}
                            onClick={() => {
                                window.location.href = process.env
                                    .REACT_APP_MARKETING_PAGE_URL as string;
                            }}
                        />
                    }
                    title={
                        <Font styleConfig={{ root: props.styleConfig.headerTitle }}>
                            {PAGE_TITLE}
                        </Font>
                    }
                    styleConfig={{
                        container: props.styleConfig.headerContainer,
                        content: props.styleConfig.headerContent,
                        burgerText: props.styleConfig.body,
                    }}
                />
            }
        >
            <AppInitializer
                actionsRequired={[
                    UserLoginActionConstants.USER_LOGIN_INITIALIZE,
                    UserLoginActionConstants.USER_LOGIN_CHECK_STATUS_SUCCESS,
                ]}
                initializerAction={AppActionConstants.COMPLETED_PAGE_TRIGGER}
            >
                <Authenticator>
                    <FlexColumn>
                        <GridContainer>
                            <Grid width={7 / 10}>
                                <GridContent>
                                    <Box styleConfig={{ root: props.styleConfig.breadcrumbBox }}>
                                        <Breadcrumbs
                                            steps={BREADCRUMB_STEPS}
                                            step={BREADCRUMB_STEP_THANK_YOU}
                                            styleConfig={{
                                                step: props.styleConfig.breadcrumbTitle,
                                            }}
                                        />
                                    </Box>
                                </GridContent>
                            </Grid>
                            <Grid width={3 / 10} />
                        </GridContainer>
                        <GridContainer>
                            <Grid width={7 / 10}>
                                <GridContent>
                                    <Box styleConfig={{ root: props.styleConfig.box }}>
                                        <Font styleConfig={{ root: props.styleConfig.pageTitle }}>
                                            Tack för din ansökan {tokenData && tokenData.name}!
                                        </Font>
                                        <br />
                                        <br />
                                        <Font styleConfig={{ root: props.styleConfig.body }}>
                                            Nu kommer en av våra kunniga handläggare att hantera din
                                            ansökan och ett besked kommer via SMS inom kort.
                                            <br />
                                            <br />
                                            När din kredit är beviljad gör du enklast uttag genom
                                            att skicka ett SMS från ditt angivna telefonnummer med
                                            uttagsbelopp (t.ex. UTTAG 1000) till 076 51 96 255. Det
                                            minsta beloppet för uttag är 1 000 kr.
                                            <br />
                                            <br />
                                            Om du har frågor kring din ansökan kan du alltid ringa
                                            vår kundservice på
                                            <br />
                                            08 225 115.
                                            <br />
                                            <br />
                                            Med vänliga hälsningar,
                                            <br /> EverydayPlus
                                        </Font>
                                    </Box>
                                </GridContent>
                            </Grid>
                            <Grid width={3 / 10} />
                        </GridContainer>
                    </FlexColumn>
                </Authenticator>
            </AppInitializer>
        </Layout>
    );
}
