import React, { ReactNode, FunctionComponent, KeyboardEvent } from "react";
import styled from "styled-components";
import { css, SystemStyleObject } from "@styled-system/css";
import {
    SpaceProps,
    FlexboxProps,
    LayoutProps,
    PositionProps,
    space,
    layout,
    flexbox,
    flex,
    position,
    TypographyProps,
    typography,
    BorderProps,
    border,
    ColorProps,
    color,
    variant,
    boxShadow,
} from "styled-system";
import { AppThemeProps, AppTheme } from "@opr-finance/themes";
import { Font } from "@opr-finance/component-fonts";
import { InputStyleProps } from "@opr-finance/utils";

export type TextInputFieldProps = {
    name?: string;
    defaultValue?: string;
    value?: string;
    ref?: () => void;
    onBlur?: () => void;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyPress?: (e: KeyboardEvent) => void;
    variant?: "default" | "glowing";
    placeholder?: string;
    key?: string;
} & SpaceProps &
    LayoutProps &
    FlexboxProps &
    PositionProps &
    TypographyProps &
    ColorProps &
    BorderProps;
export type FieldPropsWithTheme = TextInputFieldProps & AppThemeProps;

const variants = (theme: AppTheme) => {
    return {
        default: {
            height: "28px",
            fontSize: "16px",
            fontFamily: theme.fonts.basic,
            lineHeight: "1.75",
            color: "#444",
            padding: "8px",
            borderRadius: "8px",
            border: "solid 1px #0c445c",
            boxShadow: "0 3px 4px 0 rgba(0, 0, 0, 0.2)",
            backgroundColor: "#fff",
        },
        glowing: {
            width: "97px",
            fontWeight: "bold",
            color: "primary",
            textAlign: "center",
            border: "1px solid",
            borderColor: "#a9d3e5",
            padding: "8px",
            borderRadius: "8px",
        },
    };
};
export const TextInputField: FunctionComponent<TextInputFieldProps> = styled.input<TextInputFieldProps>`
    ${(props: FieldPropsWithTheme) =>
        variant({
            variants: variants(props.theme),
        })}
    ${space}
  ${layout}
  ${flexbox}
  ${position}
  ${typography}
  ${border}
  ${color}
`;

export type TextFieldProps = {
    styleConfig: {
        root: InputStyleProps;
        textFieldContainer?: SystemStyleObject;
        validationFieldContainer?: SystemStyleObject;
        validationIconContainer?: SystemStyleObject;
        validationMessage?: SystemStyleObject;
    };
    inputConfig: TextFieldConfigProps;
};

type TextFieldConfigProps = {
    type?: string;
    name?: string;
    defaultValue?: string;
    value?: string | number;
    ref?: () => void;
    key?: string;
    onBlur?: (e?: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: () => void;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyPress?: (e: KeyboardEvent) => void;
    placeholder?: string;
    maxLength?: string;
    validationError?: boolean;
    validationErrorMessage?: string;
    validationErrorIcon?: ReactNode;
    disabled?: boolean;
};

type TextFieldRootProps = TextFieldConfigProps & {
    styleConfig: InputStyleProps;
};

const TextFieldRoot: FunctionComponent<TextFieldRootProps> = styled.input.attrs(
    (props: TextFieldRootProps) => ({ type: props.type })
)<TextFieldRootProps>`
    ${(props: TextFieldRootProps) => {
        return css(props.styleConfig);
    }}
`;

type CommonTextFieldStyleProp = {
    styleConfig?: SystemStyleObject;
};

const RowContainer: FunctionComponent<CommonTextFieldStyleProp> = styled.div<CommonTextFieldStyleProp>`
    display: flex;
    flex-direction: row;
    ${(props: CommonTextFieldStyleProp) => {
        return css(props.styleConfig);
    }}
`;

const ColumnContainer: FunctionComponent<CommonTextFieldStyleProp> = styled.div<CommonTextFieldStyleProp>`
    display: flex;
    flex-direction: column;
    ${(props: CommonTextFieldStyleProp) => {
        return css(props.styleConfig);
    }}
`;

export function TextField(props: TextFieldProps) {
    const validationEnabled: boolean = props.inputConfig.validationError !== undefined;
    const isValid: boolean = validationEnabled && props.inputConfig.validationError !== true;

    const showErrorText: boolean =
        !isValid && props.inputConfig.validationErrorMessage !== undefined;

    if (validationEnabled) {
        return (
            <ColumnContainer styleConfig={props.styleConfig.validationFieldContainer}>
                <RowContainer styleConfig={props.styleConfig.textFieldContainer}>
                    <TextFieldRoot styleConfig={props.styleConfig.root} {...props.inputConfig} />
                    {!isValid && props.inputConfig.validationErrorIcon && (
                        <RowContainer styleConfig={props.styleConfig.validationIconContainer}>
                            {props.inputConfig.validationErrorIcon}
                        </RowContainer>
                    )}
                </RowContainer>
                {showErrorText && (
                    <Font styleConfig={{ root: props.styleConfig.validationMessage }}>
                        {props.inputConfig.validationErrorMessage}
                    </Font>
                )}
            </ColumnContainer>
        );
    }

    return <TextFieldRoot styleConfig={props.styleConfig.root} {...props.inputConfig} />;
}
