import React, { useEffect, useState, createContext } from "react";
import { AppLoader } from "@opr-finance/feature-loader";
import { useDispatch, useSelector, useStore } from "react-redux";
import { pageInitializerActions, T_FeaturePageInitializerState } from "..";

type contextProps = {
    pageConfig: {
        id: string;
        isReady: boolean;
    };
    setPageConfig: React.Dispatch<React.SetStateAction<{ id: string; isReady: boolean }>>;
};

const PageContext = createContext({} as contextProps);

export type T_PageConsumerProps = {
    children: React.ReactNode;
    id: string;
    successfulActions: Array<string>;
    pageInitAction: string;
    actionPayload?: string;
};

export function PageProvider({ children }) {
    const [pageConfig, setPageConfig] = useState({ id: "startpage", isReady: false });

    const value = {
        pageConfig,
        setPageConfig,
    };

    return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
}

export function PageConsumer(props: T_PageConsumerProps) {
    const { pageConfig, setPageConfig } = usePage();

    const actionsToComplete = useSelector<T_FeaturePageInitializerState, Array<string>>(
        (state): Array<string> => {
            return state.pageInitializer[props.id]?.actionToComplete;
        }
    );

    const isInitialized = useSelector<T_FeaturePageInitializerState>((state): boolean => {
        return state.pageInitializer[props.id]?.pageInitialized;
    });

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            pageInitializerActions.initPageToPageInitializer({
                actionToComplete: props.successfulActions,
                pageToWatch: props.id,
            })
        );

        dispatch({
            type: props.pageInitAction,
            payload: props?.actionPayload && props?.actionPayload,
        });

        return () => {
            dispatch(
                pageInitializerActions.resetPageInitializer({
                    pageId: props.id,
                })
            );
        };
    }, [actionsToComplete]);

    useEffect(() => {
        setPageConfig({ id: props.id, isReady: isInitialized as boolean });
    }, [isInitialized]);

    return (
        <PageContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error("PageConsumer must be used within PageProvider");
                }
                if (pageConfig.isReady) {
                    return props.children;
                }
                return <AppLoader />;
            }}
        </PageContext.Consumer>
    );
}

export function usePage() {
    const context = React.useContext(PageContext);
    if (context === undefined) throw new Error("usePage must be used within PageProvider");
    return context;
}
