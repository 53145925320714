import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import styled from "styled-components";
import { Grid, GridContainer, GridContent, FlexColumn } from "@opr-finance/component-grid";
import { FontStyleProps, InputStyleProps, add, remove } from "@opr-finance/utils";
import { Font } from "@opr-finance/component-fonts";
import { Box } from "@opr-finance/component-box-container";
import { SystemStyleObject } from "@styled-system/css";
import { breadcrumbTitle } from "@opr-finance/theme-everyday-application";
import { ButtonField } from "@opr-finance/component-forms";
import { Icon } from "@opr-finance/component-icon";
import { Logo } from "@opr-finance/component-logo";
import { Layout } from "@opr-finance/layout-everyday-application";

import { ContractsForm } from "../components/forms/ContractsForm";
import { Application } from "../types/general";
import { applicationFromLocalStorage, initialApplication, sendGAData } from "../util";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { WarningItem } from "../components/WarningItem";
import { SideContentPreview } from "../components/SideContentPreview";
import { Header } from "../components/Header";
import logo from "../images/logo.svg";
import {
    BREADCRUMB_STEPS,
    BREADCRUMB_STEP_PREVIEW,
    FORM_CONTACT_NAME,
    FORM_CONTRACTS_NAME,
    FORM_EMPLOYEMENT_NAME,
    FORM_LIVING_NAME,
    FORM_PEP_NAME,
    FORM_GUARANTOR_NAME,
    FORM_PERMITS_NAME,
    LOCAL_STORAGE_APPLICATION,
    PAGE_TITLE,
    TOTAL_FORMS,
} from "../constants";

const SubmitButtonIconContainer = styled.div`
    padding: 0 4px 0 0;
`;

const SubmitButtonTextContainer = styled.div``;

const WarningIcon = styled.div`
    width: 40px;
    height: 34px;
    color: red;
`;

const SideBoxContainer = styled.div`
    display: flex;
    visibility: visible;
    @media (max-width: 786px) {
        display: none;
        visibility: none;
    }
`;

const MobileTitle = styled.div`
    display: none;
    visibility: none;
    @media (max-width: 786px) {
        display: flex;
        visibility: visible;
    }
`;

export type PreviewPageProps = {
    styleConfig: {
        headerContainer: SystemStyleObject;
        headerContent: SystemStyleObject;
        headerTitle: SystemStyleObject;
        body: FontStyleProps;
        bodyLabel: SystemStyleObject;
        bodyWarning: SystemStyleObject;
        bodyTitle: FontStyleProps;
        pageTitle: FontStyleProps;
        textField: InputStyleProps;
        select: InputStyleProps;
        checkbox: InputStyleProps;
        checkboxDisabled: InputStyleProps;
        checkboxText: FontStyleProps;
        button: InputStyleProps;
        buttonText: FontStyleProps;
        formError: FontStyleProps;
        link: FontStyleProps;
        inlineBox: SystemStyleObject;
        box: SystemStyleObject;
        sideBox: SystemStyleObject;
        sideTitle: SystemStyleObject;
        sideLink: SystemStyleObject;
        breadcrumbTitle: SystemStyleObject;
        breadcrumbBox: SystemStyleObject;
        buttonInfo: SystemStyleObject;
        buttonCancel: SystemStyleObject;
        buttonSubmit: SystemStyleObject;
        buttonSubmitDisabled: SystemStyleObject;
        mobilePageTitle: SystemStyleObject;
    };
};

export function PreviewPage(props: PreviewPageProps) {
    const history = useHistory();
    const [validForms, setValidForms] = useState<string[]>([
        // FORM_BANK_ACCOUNT_NAME,
        FORM_CONTACT_NAME,
        FORM_LIVING_NAME,
        FORM_EMPLOYEMENT_NAME,
        FORM_GUARANTOR_NAME,
        FORM_PEP_NAME,
        FORM_PERMITS_NAME,
    ]);
    const [application, setApplication] = useState<Application>(initialApplication());
    const [currentForm, setCurrentForm] = useState<string>("");
    const [blurredForms, setBlurredForms] = useState<string[]>([""]);
    const [gaEventSent, setGaEventSent] = useState<boolean>(false);

    const showAutoFillButtons =
        (process.env.REACT_APP_SHOW_AUTO_FILL_BUTTONS as string) === "1" ? true : false;

    useEffect(() => {
        const storedApplication = applicationFromLocalStorage();
        if (storedApplication) {
            console.log("got stored application", storedApplication);
            setApplication(storedApplication);
        } else {
            history.push("/error");
        }
    }, [history]);

    function initGA() {
        if (!gaEventSent) {
            const clientApplicationId = (window as any).clientApplicationId;
            sendGAData({
                clientApplicationId: clientApplicationId,
                transactionId: clientApplicationId,
                event: "preview",
            });
            setGaEventSent(true);
        }
    }

    useEffect(() => {
        initGA();
    }, []);

    function onSubmit() {
        if (validForms.length !== TOTAL_FORMS) {
            return;
        }
        try {
            console.log("got application with contracts info", application);
            const cachedApplication = JSON.parse(
                localStorage.getItem(LOCAL_STORAGE_APPLICATION) as string
            );
            console.log(application, cachedApplication);
            const updatedApplication = { ...cachedApplication, ...application };
            console.log("got updated application to cache", updatedApplication);
            localStorage.setItem(LOCAL_STORAGE_APPLICATION, JSON.stringify(updatedApplication));
            history.push("/choose-bank", { ...updatedApplication });
        } catch (e) {
            console.log("submit on preview failed?", e);
            history.push("/error");
        }
    }

    function handleChange(isValid, formName, form) {
        if (isValid) {
            setValidForms(add(validForms, formName));
        } else {
            setValidForms(remove(validForms, formName, (a, b) => a === b));
        }
        setApplication({ ...application, ...form });
        console.log(application);
    }

    function handleBlur(formName) {
        console.log("handle blur", formName);
        setCurrentForm("");
    }

    function handleFocus(formName) {
        console.log("handle focus", formName);
        setBlurredForms(add(blurredForms, formName));
        setCurrentForm(formName);
    }

    function autoFillForm() {
        console.log("auto filling form");
        const cachedApplication = JSON.parse(
            localStorage.getItem(LOCAL_STORAGE_APPLICATION) as string
        );
        history.push("/choose-bank", { ...cachedApplication });
    }

    return (
        <Layout
            header={
                <Header
                    logo={
                        <Logo
                            logoSrc={logo}
                            width={165}
                            height={35}
                            onClick={() => {
                                window.location.href = process.env
                                    .REACT_APP_MARKETING_PAGE_URL as string;
                            }}
                        />
                    }
                    title={
                        <Font styleConfig={{ root: props.styleConfig.headerTitle }}>
                            {PAGE_TITLE}
                        </Font>
                    }
                    mobileNavigation={
                        <SideContentPreview
                            blurredForms={blurredForms}
                            currentForm={currentForm}
                            totalForms={TOTAL_FORMS}
                            validForms={validForms}
                            onSubmit={onSubmit}
                            styleConfig={{
                                bodyTitle: props.styleConfig.bodyTitle,
                                sideTitle: props.styleConfig.sideTitle,
                                sideLink: props.styleConfig.sideLink,
                                buttonSubmit: props.styleConfig.buttonSubmit,
                                buttonInfo: props.styleConfig.buttonInfo,
                            }}
                        />
                    }
                    styleConfig={{
                        container: props.styleConfig.headerContainer,
                        content: props.styleConfig.headerContent,
                        burgerText: props.styleConfig.body,
                    }}
                />
            }
        >
            <FlexColumn>
                <MobileTitle>
                    <GridContainer>
                        <Grid width={"100%"}>
                            <GridContent alignItems="center">
                                <Font styleConfig={{ root: props.styleConfig.mobilePageTitle }}>
                                    {PAGE_TITLE}
                                </Font>
                            </GridContent>
                        </Grid>
                    </GridContainer>
                </MobileTitle>
                <GridContainer>
                    <Grid width={7 / 10}>
                        <GridContent padding={["0 8px 40px 8px"]}>
                            <Box styleConfig={{ root: props.styleConfig.breadcrumbBox }}>
                                <Breadcrumbs
                                    steps={BREADCRUMB_STEPS}
                                    step={BREADCRUMB_STEP_PREVIEW}
                                    styleConfig={{
                                        step: breadcrumbTitle(),
                                    }}
                                />
                            </Box>
                        </GridContent>
                    </Grid>
                    <Grid width={3 / 10} />
                </GridContainer>
                <GridContainer>
                    <Grid width={7 / 10}>
                        <FlexColumn height="100%">
                            <GridContent>
                                <Box styleConfig={{ root: props.styleConfig.box }}>
                                    {showAutoFillButtons && (
                                        <button onClick={autoFillForm}>Auto Fill</button>
                                    )}
                                    <ContractsForm
                                        currentForm={currentForm}
                                        validForms={validForms}
                                        blurredForms={blurredForms}
                                        application={application}
                                        onChange={(isValid, form) => {
                                            handleChange(isValid, FORM_CONTRACTS_NAME, form);
                                        }}
                                        onBlur={() => {
                                            handleBlur(FORM_CONTRACTS_NAME);
                                        }}
                                        onFocus={() => {
                                            handleFocus(FORM_CONTRACTS_NAME);
                                        }}
                                        styleConfig={{
                                            body: props.styleConfig.body,
                                            bodyLabel: props.styleConfig.bodyLabel,
                                            bodyTitle: props.styleConfig.bodyTitle,
                                            checkbox: props.styleConfig.checkbox,
                                            checkboxDisabled: props.styleConfig.checkboxDisabled,
                                            checkboxText: props.styleConfig.checkboxText,
                                            formError: props.styleConfig.formError,
                                            link: props.styleConfig.link,
                                            box: props.styleConfig.box,
                                            inlineBox: props.styleConfig.inlineBox,
                                        }}
                                    />
                                </Box>
                            </GridContent>
                            <GridContent>
                                <Box styleConfig={{ root: props.styleConfig.box }}>
                                    <WarningItem
                                        left={
                                            <WarningIcon>
                                                <Icon
                                                    icon={["fa", "exclamation-triangle"]}
                                                    size="2x"
                                                />
                                            </WarningIcon>
                                        }
                                        right={
                                            <Font
                                                styleConfig={{
                                                    root: props.styleConfig.bodyWarning,
                                                }}
                                            >
                                                Det här är en högkostnadskredit: Om du inte kan
                                                betala tillbaka hela skulden riskerar du en
                                                betalningsanmärkning. För stöd, vänd dig till
                                                budget- och skuldrådgivningen i din kommun.
                                                Kontaktuppgifter finns på hallåkonsument.se.
                                            </Font>
                                        }
                                    />
                                </Box>
                            </GridContent>
                            <GridContent>
                                <Box styleConfig={{ root: props.styleConfig.box }}>
                                    <ButtonField
                                        styleConfig={{
                                            root:
                                                validForms.length === TOTAL_FORMS
                                                    ? props.styleConfig.buttonSubmit
                                                    : props.styleConfig.buttonSubmitDisabled,
                                        }}
                                        onClick={() => {
                                            onSubmit();
                                        }}
                                    >
                                        <SubmitButtonIconContainer>
                                            <Icon icon={["fa", "arrow-circle-right"]} />
                                        </SubmitButtonIconContainer>
                                        <SubmitButtonTextContainer>
                                            Slutför ansökan
                                        </SubmitButtonTextContainer>
                                    </ButtonField>
                                    <ButtonField
                                        styleConfig={{ root: props.styleConfig.buttonCancel }}
                                        onClick={() => {
                                            window.location.href = process.env
                                                .REACT_APP_MARKETING_PAGE_URL as string;
                                        }}
                                    >
                                        Avsluta ansökan
                                    </ButtonField>
                                </Box>
                            </GridContent>
                        </FlexColumn>
                    </Grid>
                    <Grid width={3 / 10} position="sticky" top="0" height={[0, "564px"]}>
                        <SideBoxContainer>
                            <GridContent>
                                <Box styleConfig={{ root: props.styleConfig.sideBox }}>
                                    <SideContentPreview
                                        blurredForms={blurredForms}
                                        currentForm={currentForm}
                                        totalForms={TOTAL_FORMS}
                                        validForms={validForms}
                                        onSubmit={onSubmit}
                                        styleConfig={{
                                            bodyTitle: props.styleConfig.bodyTitle,
                                            sideTitle: props.styleConfig.sideTitle,
                                            sideLink: props.styleConfig.sideLink,
                                            buttonSubmit: props.styleConfig.buttonSubmit,
                                            buttonInfo: props.styleConfig.buttonInfo,
                                        }}
                                    />
                                </Box>
                            </GridContent>
                        </SideBoxContainer>
                    </Grid>
                </GridContainer>
            </FlexColumn>
        </Layout>
    );
}
