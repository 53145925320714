import React, { useEffect } from "react";
import { Login } from "@opr-finance/feature-luvittaja-login";
import { Layout } from "@opr-finance/layout-everyday-application";
import { Header } from "../components/Header";
import { Logo } from "@opr-finance/component-logo";
import { Font } from "@opr-finance/component-fonts";
import { SystemStyleObject } from "@styled-system/css";
import logo from "../images/logo.svg";
import { PAGE_TITLE } from "../constants";
import { sendGAData } from "../util";

export type LoginPageProps = {
    styleConfig: {
        headerTitle: SystemStyleObject;
        headerContainer: SystemStyleObject;
        headerContent: SystemStyleObject;
    };
};

function initGA() {
    const clientApplicationId = (window as any).clientApplicationId;
    sendGAData({
        clientApplicationId: clientApplicationId,
        event: "login",
    });
}

export function LoginPage(props: LoginPageProps) {
    useEffect(() => {
        initGA();
    }, []);
    return (
        <Layout
            header={
                <Header
                    logo={
                        <Logo
                            logoSrc={logo}
                            width={165}
                            height={35}
                            onClick={() => {
                                window.location.href = process.env
                                    .REACT_APP_MARKETING_PAGE_URL as string;
                            }}
                        />
                    }
                    title={
                        <Font styleConfig={{ root: props.styleConfig.headerTitle }}>
                            {PAGE_TITLE}
                        </Font>
                    }
                    styleConfig={{
                        container: props.styleConfig.headerContainer,
                        content: props.styleConfig.headerContent,
                    }}
                />
            }
        >
            <div>
                <Login />
                Handling login...
            </div>
        </Layout>
    );
}
