import React, { ReactNode } from "react";
import styled from "styled-components";
import {
    space,
    layout,
    flexbox,
    position,
    SpaceProps,
    LayoutProps,
    FlexboxProps,
    PositionProps,
} from "styled-system";
import { FunctionComponent } from "react";
import { AppThemeProps } from "@opr-finance/themes";
import { SystemStyleObject, css } from "@styled-system/css";

export type BaseBoxContainerProps = SpaceProps & LayoutProps & FlexboxProps & PositionProps;
export type BaseBoxContainerPropsWithTheme = BaseBoxContainerProps & AppThemeProps;

export const BaseBoxContainer: FunctionComponent<BaseBoxContainerProps> = styled.div<BaseBoxContainerProps>`
    background-color: ${(props: BaseBoxContainerPropsWithTheme) => props.theme.colors.background};
    box-shadow: ${(props: BaseBoxContainerPropsWithTheme) => props.theme.boxShadows.primary};
    border-radius: ${(props: BaseBoxContainerPropsWithTheme) => props.theme.radii.primary};
    color: ${(props: BaseBoxContainerPropsWithTheme) => props.theme.colors.primary};
    font-size: ${(props: BaseBoxContainerPropsWithTheme) => props.theme.fontSizes[2]};
    width: 100%;
    height: 100%;
    ${space}
    ${layout}
    ${flexbox}
    ${position}
`;

export type BaseBoxProps = {
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    children: ReactNode;
    styleConfig: SystemStyleObject;
};

export const BaseBox: FunctionComponent<BaseBoxProps> = styled.div<BaseBoxProps>`
    ${(props: BaseBoxProps) => {
        return css(props.styleConfig);
    }}
`;
