import { useLocation } from "react-router-dom";

import { T_EnableBankingBank } from "@opr-finance/feature-enable-banking";
import { LOCAL_STORAGE_APPLICATION, LOCAL_STORAGE_LFP_ERROR } from "./constants";
import { Application, EmploymentOptionsPlain } from "./types/general";

export function sendGAData(data) {
    console.log("send ga data", data);
    const dataLayer = (window as any).dataLayer || [];
    dataLayer.push(data);
}

function getCookie(cname) {
    const name = cname + "=";
    const document = (window as any).document;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function getTrackingData() {
    const clientApplicationId = (window as any).clientApplicationId;
    return {
        clientApplicationId: clientApplicationId,
        subsource: getCookie(process.env.REACT_APP_SUBSOURCE_COOKIE as string),
        source: getCookie(process.env.REACT_APP_CLICKCHANNEL),
        redirectId: getCookie(process.env.REACT_APP_REDIRECTAPIID),
        externalReference: clientApplicationId,
    };
}

export function applicationFromLocalStorage(): Application | null {
    try {
        const application: Application = JSON.parse(
            localStorage.getItem(LOCAL_STORAGE_APPLICATION) as string
        );
        console.log("got application from local", application);
        return application;
    } catch (e) {
        return null;
    }
}

export function initialApplication(): Application {
    const application: Application | null = applicationFromLocalStorage();
    const lfpError: string | null = localStorage.getItem(LOCAL_STORAGE_LFP_ERROR);

    console.log(
        "load initial application from local storage?",
        application && lfpError,
        application,
        lfpError
    );

    if (application && lfpError) {
        return application;
    }

    return {
        maritalStatus: "Välj",
        children: "Välj",
        housingForm: "Välj",
        applicantEmail: "",
        applicantPhone: "",
        reason: "Välj",
        reasonInfo: "",
        employementType: "Välj",
        startYear: "",
        startMonth: "",
        endYear: "",
        endMonth: "",
        monthlyIncomeGross: "0",
        livingExpenses: "0",
        loanExpenses: "0",
        bankAccount: "",
        isPep: "Välj",
        isGuarantor: "Välj",
        guarantorAmount: "0",
        allowBisnodeCheck: false,
        allowMarketing: true,
        allInformationIsCorrect: false,
        commonLoanTerms: false,
        termsAndCreditAgreement: false,
        sekki: false,
        payDirectly: true,
        allowAllContracts: false,
        highestLimitAccepted: false,
        ...getTrackingData(),
    };
}

export function hasLocalApplication(): boolean {
    const hasApplication: boolean = localStorage.getItem(LOCAL_STORAGE_APPLICATION) ? true : false;
    const hasLfpError: boolean = localStorage.getItem(LOCAL_STORAGE_LFP_ERROR) ? true : false;
    return hasApplication && hasLfpError;
}

export function isEndDateVisible(value: string): boolean {
    if (value === EmploymentOptionsPlain.TEMPORARY) return true;
    if (value === EmploymentOptionsPlain.PROJECT) return true;
    return false;
}

export function sortBanks(
    banks: T_EnableBankingBank[],
    sparbankenIsChosen: boolean,
    focused: boolean
) {
    const nonSparbankArray = banks
        .filter((bank) => !bank.name.toLowerCase().includes("sparbank"))
        .filter((bank) => !bank.name.toLowerCase().includes("ölands bank"));
    // .filter((bank) => !bank.name.toLowerCase().includes("swedbank"));

    const sparbankArray = banks.filter((bank) => {
        if (bank.name.toLowerCase().includes("sparbank")) return true;
        if (bank.name.toLowerCase().includes("ölands bank")) return true;
        // if (bank.name.toLowerCase().includes("swedbank")) return true;
        return false;
    });

    const sortedNonSparbankArray = nonSparbankArray.sort((a, b) => (a.name > b.name ? 1 : -1));
    const sortedSparbankArray = sparbankArray.sort((a, b) => (a.name > b.name ? 1 : -1));

    if (focused) return sortedNonSparbankArray.concat(sortedSparbankArray);

    if (sparbankenIsChosen) return sortedSparbankArray;

    return sortedNonSparbankArray;
}

export function setMatches(
    sortedBanks: T_EnableBankingBank[],
    searchText: string
): T_EnableBankingBank[] {
    let matches =
        sortedBanks &&
        sortedBanks.filter((bank) => {
            const regex = new RegExp(`^${searchText}`, "gi");
            return bank.name.match(regex);
        });
    if (searchText.length === 0) {
        matches = sortedBanks;
    }
    return matches;
}

export const mockApplication = {
    allInformationIsCorrect: true,
    allowAllContracts: true,
    allowBisnodeCheck: true,
    allowMarketing: true,
    applicantEmail: "test@mail.com",
    applicantPhone: "0451175987",
    children: "0",
    clientApplicationId: "7861627470487942",
    commonLoanTerms: true,
    employementType: "Tillsvidareanställd",
    endMonth: "",
    endYear: "",
    externalReference: "7861627470487942",
    highestLimitAccepted: true,
    housingForm: "Hyresrätt",
    isPep: "Nej",
    livingExpenses: "1234",
    loanExpenses: "1234",
    maritalStatus: "Singel",
    monthlyIncomeGross: "35000",
    payDirectly: true,
    reason: "Resa",
    reasonInfo: "",
    redirectId: "",
    sekki: false,
    source: "",
    startMonth: "01",
    startYear: "2020",
    subsource: "",
    termsAndCreditAgreement: true,
};
