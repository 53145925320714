export enum E_PageInitializerActionConstants {
    INIT_INITALIZER = "PAGE_INITIALIZER/INIT",
    INIT_PAGE = "PAGE_INITIALIZER/INIT_PAGE",
    INIT_PAGE_ACTION_SUCCESS = "PAGE_INITIALIZER/INIT_PAGE_ACTION_SUCCESS",
    INIT_PAGE_ERROR = "PAGE_INITIALIZER/INIT_PAGE_ERROR",
    INIT_PAGE_SUCCESS = "PAGE_INITIALIZER/INIT_PAGE_SUCCESS",
    INIT_PAGE_RESET_STATUS = "PAGE_INITIALIZER/INIT_PAGE_RESET_STATUS",
}

export type T_PageInitializerInitPayload = {
    pageToWatch: string;
    actionToComplete: Array<string>;
};

export type T_PageInitializerActionSuccessPayload = {
    pageId: string;
};

export type T_PageInitializerActionCompletePayload = {
    pageId: string;
    actionComplete: string;
};

export type T_PageInitializerReducerState = {
    [key: string]: {
        actionToComplete: Array<string>;
        actionCompleted: Array<string>;
        pageInitialized: boolean;
    };
};

export type T_FeaturePageInitializerState = {
    pageInitializer: T_PageInitializerReducerState;
};

export type T_PageInitializerProps = {
    id: string;
    successfulActions: Array<string>;
    pageInitAction: string;
    children: React.ReactNode;
};
