import React, { useRef } from "react";

import { FileInputFiedProps } from "./types";
import { StyledFileInput } from "./FileInput.style";
import { ButtonField } from "../Button/Button";

export function FileInput(props: FileInputFiedProps) {
    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const handleButtonClick = (e) => {
        if (hiddenFileInput.current !== null) hiddenFileInput.current.click();
    };

    const handleOnChange = (e) => {
        if (props.inputConfig.onChange) props.inputConfig.onChange(e);
    };
    const handleOnFocus = () => {
        if (props.inputConfig.onFocus) props.inputConfig.onFocus();
    };
    const handleOnBlur = () => {
        if (props.inputConfig.onBlur) props.inputConfig.onBlur();
    };

    return (
        <>
            <StyledFileInput
                styleConfig={props.styleConfig.default}
                ref={hiddenFileInput}
                multiple={props.inputConfig.multiple}
                accept={props.inputConfig.accept}
                onChange={(e) => handleOnChange(e)}
            />
            <ButtonField
                styleConfig={{ root: props.styleConfig.button }}
                onClick={(e) => handleButtonClick(e)}
                onBlur={() => handleOnBlur()}
                onFocus={() => handleOnFocus()}
            >
                {props.children}
            </ButtonField>
        </>
    );
}
