import React from "react";
import { Icon } from "@opr-finance/component-icon";
import styled from "styled-components";

const Container = styled.div`
    font-size: 20px;
    margin: 0 16px 0 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const EmptyContainer = styled.div`
    height: 100%;
    width: 37px;
`;

export type IsValidFormProps = {
    validForms: string[];
    blurredForms: string[];
    currentForm: string;
    name: string;
};

export function IsValidForm(props: IsValidFormProps) {
    if (props.validForms.includes(props.name)) {
        return (
            <Container>
                <Icon icon={["fa", "check"]} size="1x" color="#71789b" />
            </Container>
        );
    }
    if (!props.blurredForms.includes(props.name)) {
        return <EmptyContainer />;
    }
    if (props.currentForm === props.name) {
        return (
            <Container>
                <Icon icon={["fa", "pencil-alt"]} size="1x" color="#71789b" />
            </Container>
        );
    }
    return (
        <Container>
            <Icon icon={["fa", "ban"]} size="1x" color="#cc0000" />
        </Container>
    );
}
