import * as Leandev from "@opr-finance/api-definitions";
import { UserTokenData } from "@opr-finance/feature-luvittaja-login";
import { Application, EmploymentOptionsPlain, EmploymentOptions } from "./types/general";
import { isEndDateVisible } from "./util";

export function mapEmployementType(value: string) {
    switch (value) {
        case EmploymentOptionsPlain.FULL:
            return EmploymentOptions.FULL;
        case EmploymentOptionsPlain.NO_WORK:
            return EmploymentOptions.NO_WORK;
        case EmploymentOptionsPlain.TEMPORARY:
            return EmploymentOptions.TEMPORARY;
        case EmploymentOptionsPlain.PROJECT:
            return EmploymentOptions.PROJECT;
        case EmploymentOptionsPlain.STUDENT:
            return EmploymentOptions.STUDENT;
        case EmploymentOptionsPlain.SELF_EMPLOYED:
            return EmploymentOptions.SELF_EMPLOYED;
        case EmploymentOptionsPlain.RETIRED:
            return EmploymentOptions.RETIRED;
        case EmploymentOptionsPlain.TRIAL:
            return EmploymentOptions.TRIAL;
        default:
            return EmploymentOptions.NO_WORK;
    }
}

export function mapMaritalStatus(value: string) {
    switch (value) {
        case "Singel":
            return "SINGLE";
        case "Änka/Änkling":
            return "WIDOWED";
        case "Skild":
            return "DIVORCED";
        case "Gift":
            return "MARRIED";
        case "Sambo":
            return "PARTNER";
        default:
            return "SINGLE";
    }
}

export function mapHousingForm(value: string) {
    switch (value) {
        case "Villa":
            return "HOUSE";
        case "Bostadsrätt":
            return "OWNED";
        case "Hyresrätt":
            return "RENTED";
        case "Inneboende":
            return "LIVEIN";
        default:
            return "RENTED";
    }
}

export function handlePhoneNumber(value: string): string {
    const countryCode = "+46";
    console.log("got phonenumber to handle", value);
    if (!value) return value;
    const phoneNumber = value.substring(1);
    return `${countryCode}${phoneNumber}`;
}

export function handleReason(reason: string, reasonInfo: string): string {
    if (reason === "Annat, specificera") {
        return `${reason}: ${reasonInfo}`;
    }
    return reason;
}

export function mapApplicationToLeandevRequest(
    data: Application,
    tokenData: UserTokenData
): Leandev.LoanApplicationRequest {
    const names = tokenData.name.split(" ");
    const firstName = names.slice(0, names.length - 1).join(" ");
    const lastName = names[names.length - 1];
    const phone = handlePhoneNumber(data.applicantPhone);
    const credits = parseInt(data.loanExpenses.replace(/\s+/g, ""));
    const propertyCost = parseInt(data.livingExpenses.replace(/\s+/g, "")) + credits;
    const monthlyIncome = parseInt(data.monthlyIncomeGross.replace(/\s+/g, ""));
    const employedAt = `${data.startYear}-${data.startMonth}-01`;
    const employerEndDate = `${data.endYear}-${data.endMonth}-01`;

    const application: Leandev.LoanApplicationRequest = {
        appliedProductID: process.env.REACT_APP_PRODUCT_ID as string,
        externalReference: "",
        desiredLoan: 25000,
        desiredPaybackTime: 1,
        statementChannel: "ELECTRONIC_EMAIL_NOTIFICATION",
        credits: [
            {
                sum: 0,
                rate: 0,
                paybackTime: 0,
                bank: "customer expenses",
                monthlyCost: credits,
            },
        ],
        applicants: {
            main: {
                personalID: tokenData.ssn,
                givenName: firstName,
                surName: lastName,
                email: data.applicantEmail,
                phonePrimary: phone,
                propertyType: mapHousingForm(data.housingForm),
                propertyCost,
                maritalStatus: mapMaritalStatus(data.maritalStatus),
                children: parseInt(data.children),
                employer: {
                    employer: "No employer name",
                    employerEndDate: undefined,
                    employmentType: mapEmployementType(data.employementType),
                    salary: monthlyIncome,
                    referencePhone: "+468225115",
                    employedAt,
                    ...(isEndDateVisible(data.employementType) && { employerEndDate }),
                },
                selfAssessedPEP: data.isPep === "PEP" ? "PEP" : "NOT_PEP",
                selfAssessedPepDescription: data.isPep ? "PEP" : undefined,
            },
        },
        disbursementAccount: {
            type: "SE-CLEARING",
            number: data.bankAccount,
        },
        campaigns: {},
        extras: {
            IP: "",
            propertyCost: data.livingExpenses.toString(),
            creditsMonthlyCost: data.loanExpenses.toString(),
            monthlyIncome: data.monthlyIncomeGross.toString(),
            propertyType: data.housingForm,
            maritalStatus: data.maritalStatus,
            clientApplicationId: data.clientApplicationId,
            originalPhoneNumber: data.applicantPhone,
            allowMarketing: data.allowMarketing ? "Yes" : "No",
            highestlimitaccepted: data.highestLimitAccepted ? "Yes" : "No",
            purposeOfCredit: handleReason(data.reason, data.reasonInfo),
            subsource: data.subsource,
            source: data.source,
            redirectId: data.redirectId,
            applicationChannel: "OPR",
            manualWithdrawalWish: data.payDirectly ? "Yes" : "no",
            applicationStage: "INITIAL",
            isGuarantor: data.isGuarantor,
            guarantorAmount: data.guarantorAmount,
        },
    };

    console.log("application mapping result", application, data, tokenData);

    return application;
}
