/**
 * Library for all icons used in library
 * Only icons explicitly imported will be used in application(s)
 *
 */
import { library, IconDefinition } from "@fortawesome/fontawesome-svg-core";

/* PRO ICONS */
/* LIGHT ICONS, PREFIX: fal ? */
// import {} from "@fortawesome/pro-light-svg-icons";

/* REGULAR ICONS, PREFIX: far */
import {
    faAddressBook,
    faClock,
    faMoneyBillAlt,
    faQuestionCircle,
} from "@fortawesome/pro-regular-svg-icons";
/* SOLID ICONS, PREFIX: fas */
import {
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faArrowCircleRight,
    faAt,
    faBan,
    faBars,
    faCaretDown,
    faCaretLeft,
    faCaretRight,
    faCaretUp,
    faCheck,
    faCheckSquare,
    faCircle,
    faCircleNotch,
    faCommentAltDots,
    faComments,
    faEllipsisH,
    faEnvelope,
    faExclamationCircle,
    faExclamationTriangle,
    faFileAlt,
    faFileCheck,
    faHome,
    faHomeLg,
    faListAlt,
    faMinus,
    faPen,
    faPencilAlt,
    faPhoneAlt,
    faPlus,
    faQuestion,
    faSave,
    faSearch,
    faSignOutAlt,
    faSyncAlt,
    faThumbsUp,
    faTimes,
    faTrashAlt,
    faUser,
} from "@fortawesome/pro-solid-svg-icons";
/* PRO ICONS */

const allIcons: IconDefinition[] = [
    faAddressBook,
    faAngleDoubleRight,
    faAt,
    faCaretDown,
    faCaretLeft,
    faCaretRight,
    faCaretUp,
    faCircleNotch,
    faClock,
    faComments,
    faEllipsisH,
    faEnvelope,
    faHome,
    faMoneyBillAlt,
    faPencilAlt,
    faPhoneAlt,
    faQuestion,
    faSave,
    faSignOutAlt,
    faSyncAlt,
    faThumbsUp,
    faTimes,
    faUser,
    faCheck,
];

const everydayplusIcons: IconDefinition[] = [
    faAddressBook,
    faAngleDoubleRight,
    faCaretDown,
    faCaretLeft,
    faCaretRight,
    faCaretUp,
    faCircleNotch,
    faClock,
    faComments,
    faEllipsisH,
    faEnvelope,
    faHome,
    faMoneyBillAlt,
    faPencilAlt,
    faPhoneAlt,
    faQuestion,
    faQuestionCircle,
    faSave,
    faSignOutAlt,
    faSyncAlt,
    faThumbsUp,
    faTimes,
    faUser,
    faPlus,
    faMinus,
];

const flexIcons: IconDefinition[] = [
    faAddressBook,
    faAngleDoubleRight,
    faCaretDown,
    faCaretLeft,
    faCaretRight,
    faCaretUp,
    faCircleNotch,
    faClock,
    faComments,
    faEllipsisH,
    faEnvelope,
    faHome,
    faMoneyBillAlt,
    faPencilAlt,
    faPen,
    faPhoneAlt,
    faQuestion,
    faQuestionCircle,
    faSave,
    faSignOutAlt,
    faSyncAlt,
    faThumbsUp,
    faTimes,
    faUser,
    faPlus,
    faMinus,
    faCheck,
];

const fixzaIcons: IconDefinition[] = [
    faAddressBook,
    faAngleDoubleRight,
    faCaretDown,
    faCaretLeft,
    faCaretRight,
    faCaretUp,
    faCircleNotch,
    faClock,
    faComments,
    faEllipsisH,
    faEnvelope,
    faHome,
    faMoneyBillAlt,
    faPencilAlt,
    faPhoneAlt,
    faQuestion,
    faQuestionCircle,
    faSave,
    faSignOutAlt,
    faSyncAlt,
    faThumbsUp,
    faTimes,
    faUser,
    faPlus,
    faMinus,
    faCheck,
    faBan,
    faTimes,
    faArrowCircleRight,
    faExclamationCircle,
    faExclamationTriangle,
    faBars,
    faSearch,
];

const applicationFinlandYritysluottoIcons: IconDefinition[] = [
    faBan,
    faCaretDown,
    faCheck,
    faCircleNotch,
    faQuestionCircle,
    faPencilAlt,
    faTrashAlt,
];

const flexOnlineIcons: IconDefinition[] = [
    faAddressBook,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAt,
    faCaretDown,
    faCaretLeft,
    faCaretRight,
    faCaretUp,
    faCheck,
    faCheckSquare,
    faCircle,
    faCircleNotch,
    faClock,
    faCommentAltDots,
    faComments,
    faEllipsisH,
    faEnvelope,
    faFileAlt,
    faFileCheck,
    faHomeLg,
    faListAlt,
    faMinus,
    faMoneyBillAlt,
    faPencilAlt,
    faPhoneAlt,
    faPlus,
    faQuestion,
    faQuestionCircle,
    faSave,
    faSignOutAlt,
    faSyncAlt,
    faThumbsUp,
    faTimes,
    faUser,
];

export const iconLibrary = {
    initAll: () => library.add(...allIcons),
    initEverydayplus: () => library.add(...everydayplusIcons),
    initAdmin: () => library.add(...allIcons),
    initFlex: () => library.add(...flexIcons),
    initFixza: () => library.add(...fixzaIcons),
    initYritysluottoFi: () => library.add(...applicationFinlandYritysluottoIcons),
    initFlexOnline: () => library.add(...flexOnlineIcons),
};
