import React, { FunctionComponent, ReactNode } from "react";
import styled from "styled-components";
import { border, color, layout, typography, variant, space } from "styled-system";
import { AppTheme, AppThemeProps } from "@opr-finance/themes";
import { LinkProps } from "./types";
import { css, SystemStyleObject } from "@styled-system/css";

const variants = (theme: AppTheme) => ({
    basic: {
        fontFamily: theme.fonts.basic,
        color: theme.colors.text,
        fontWeight: "normal",
        fontSize: theme.fontSizes[1],
        margin: "0",
        padding: "0",
        ":visited": {
            color: theme.colors.text,
        },
        ":hover": {
            color: theme.colors.primaryHover,
        },
    },
    light: {
        fontFamily: theme.fonts.basic,
        color: theme.colors.tertiaryHover,
        fontWeight: "normal",
        fontSize: theme.fontSizes[2],
        textDecoration: "underline",
        margin: "0",
        marginTop: "8px",
        padding: "0",
        ":visited": {
            color: theme.colors.text,
        },
        ":hover": {
            color: theme.colors.text,
            cursor: "pointer",
        },
        svg: {
            marginLeft: "8px",
        },
    },
    primary: {
        fontFamily: theme.fonts.basic,
        color: theme.colors.text,
        fontWeight: "normal",
        fontSize: theme.fontSizes[1],
        textDecoration: "underline",
        margin: "0",
        padding: "0",
        ":visited": {
            color: theme.colors.text,
        },
        ":hover": {
            color: theme.colors.primaryHover,
            cursor: "pointer",
        },
        svg: {
            marginLeft: "8px",
        },
    },
    secondary: {
        fontFamily: theme.fonts.basic,
        color: theme.colors.green,
        fontWeight: "normal",
        fontSize: theme.fontSizes[1],
        textDecoration: "underline",
        margin: "0",
        padding: "0",
        ":visited": {
            color: theme.colors.text,
        },
        ":hover": {
            color: theme.colors.primaryHover,
            cursor: "pointer",
        },
        svg: {
            marginLeft: "8px",
        },
    },
    tertiary: {
        fontFamily: theme.fonts.basic,
        color: theme.colors.orange,
        fontWeight: "normal",
        fontSize: theme.fontSizes[1],
        textDecoration: "underline",
        margin: "0",
        padding: "0",
        ":visited": {
            color: theme.colors.text,
        },
        ":hover": {
            color: theme.colors.primaryHover,
            cursor: "pointer",
        },
        svg: {
            marginLeft: "8px",
        },
    },
});

export const LinkTo: FunctionComponent<LinkProps> = styled.a<LinkProps>`
    ${(props: AppThemeProps) =>
        variant({
            variants: variants(props.theme),
        })}
    ${border}
    ${color}
    ${space}
    ${layout}
    ${typography}
`;

export type DefaultLinkProps = {
    activeClass?: string;
    to?: string;
    href?: string;
    target?: string;
    title?: string;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
    children: ReactNode;
    styleConfig: {
        root: SystemStyleObject;
    };
};

export const StyledLink = styled.a<DefaultLinkProps>`
    ${(props: DefaultLinkProps) => {
        return css(props.styleConfig.root);
    }}
    cursor: pointer;
`;

export function Link(props: DefaultLinkProps) {
    return <StyledLink {...props}>{props.children}</StyledLink>;
}
