import styled from "styled-components";
import {
    border,
    space,
    layout,
    flexbox,
    position,
    SpaceProps,
    LayoutProps,
    FlexboxProps,
    PositionProps,
    BorderProps,
} from "styled-system";
import { FunctionComponent } from "react";
import { AppThemeProps } from "@opr-finance/themes";

export type BaseGridProps = SpaceProps & LayoutProps & FlexboxProps & PositionProps & BorderProps;
export type BaseGridPropsWithTheme = BaseGridProps & AppThemeProps;

export const BaseContainer: FunctionComponent<BaseGridProps> = styled.div<BaseGridPropsWithTheme>`
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    @media (max-width: 786px) {
        flex-direction: column;
        width: 100%;
    }
    @media (min-width: 786px) and (max-width: 1080px) {
        flex-direction: column;
        width: 100%;
    }
    max-width: ${(props: BaseGridPropsWithTheme) => {
        return props.theme.grid.maxWidth;
    }};
    ${space}
    ${layout}
    ${flexbox}
    ${position}
    ${border}
`;

export const BaseGrid: FunctionComponent<BaseGridProps> = styled.div<BaseGridPropsWithTheme>`
    display: flex;
    flex-direction: row;
    @media (max-width: 786px) {
        flex-direction: column;
        width: 100%;
    }
    @media (min-width: 786px) and (max-width: 1080px) {
        flex-direction: column;
        width: 100%;
    }
    max-width: ${(props: BaseGridPropsWithTheme) => {
        return props.theme.grid.maxWidth;
    }};
    ${space}
    ${layout}
    ${flexbox}
    ${position}
    ${border}
`;

export const BaseGridContent: FunctionComponent<BaseGridProps> = styled.div<BaseGridPropsWithTheme>`
    display: flex;
    flex-direction: row;
    @media (max-width: 786px) {
        flex-direction: column;
        width: 100%;
    }
    @media (min-width: 786px) and (max-width: 1080px) {
        flex-direction: column;
        width: 100%;
    }
    max-width: ${(props: BaseGridPropsWithTheme) => {
        return props.theme.grid.maxWidth;
    }};
    padding: ${(props: BaseGridPropsWithTheme) => {
        return props.theme.grid.gap ? props.theme.grid.gap : "8px";
    }};
    ${space}
    ${layout}
    ${flexbox}
    ${position}
    ${border}
`;
