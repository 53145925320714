import { FunctionComponent } from "react";
import { css } from "@styled-system/css";
import styled from "styled-components";

import { RootElementProps, EmptyRootElementProps } from "./types";

export const RootElement: FunctionComponent<RootElementProps> = styled.div<RootElementProps>`
    ${(props: RootElementProps) => css(props.styleConfig)};
`;

export const EmptyRootElement: FunctionComponent<EmptyRootElementProps> = styled.div<EmptyRootElementProps>`
    ${(props: EmptyRootElementProps) => css(props.styleConfig)};
`;
