export function getCookie(cname) {
    const name = cname + "=";
    const document = (window as any).document;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function getGAClientId() {
    var id = "";
    if ((window as any).Cookiebot?.consent?.marketing) {
        (window as any).ga?.getAll().forEach(function (tracker) {
            if (tracker) {
                //console.log("tracker name: " +tracker.get("name") +" tracker trackingId: " + tracker.get("trackingId"));
                id = tracker.get("clientId");
                //console.log(id);
            } else {
                //console.log("no tracker yet");
            }
        });
    }
    //console.log("we have google client id: " + id);
    return { gaClientId: id };
}

export function getCookieConsents() {
    return {
        allowMarketingConsent: (window as any).Cookiebot?.consent?.marketing,
        allowStatisticsConsent: (window as any).Cookiebot?.consent?.statistics,
        allowPreferencesConsent: (window as any).Cookiebot?.consent?.preferences,
    };
}

export function getClientApplicationId() {
    //console.log("do we have the cookiebot data?");
    //console.log(getCookieConsents());
    var clientApplicationId = "";
    if (sessionStorage.getItem("clientApplicationId") === null) {
        clientApplicationId = Math.random().toString().substring(2, 5) + new Date().getTime();
        sessionStorage.clientApplicationId = clientApplicationId;
    } else {
        clientApplicationId = sessionStorage.clientApplicationId;
    }
    return clientApplicationId;
}

export function getSourceInfo(
    redirectIdCookieName: string | null,
    sourceCookieName: string | null,
    subSourceCookieName: string | null
) {
    return {
        clientApplicationId: getClientApplicationId(),
        subsource: getCookie(subSourceCookieName),
        source: getCookie(sourceCookieName),
        redirectId: getCookie(redirectIdCookieName),
        externalReference: getClientApplicationId(),
    };
}

export function getTrackingData(
    redirectApiIdCookieName,
    clickChannelCookieName,
    subsourceCookieName
) {
    const data = {
        ...getSourceInfo(redirectApiIdCookieName, clickChannelCookieName, subsourceCookieName),
        ...getCookieConsents(),
        ...getGAClientId(),
    };
    //console.log("tracking data is: ");
    //console.log(data);
    return data;
}

export function sendGAEvent(eventName) {
    const dataLayer = (window as any).dataLayer || [];
    dataLayer.push({
        event: eventName,
    });
}

export function sendGAConversion(amount) {
    var amountCleaned = "" + amount;
    const amountCleanedNumber = +amountCleaned.replace(/[\D]/g, "") / 1;
    const dataLayer = (window as any).dataLayer || [];
    const clientApplicationId = getClientApplicationId();
    dataLayer.push({
        clientApplicationId: clientApplicationId,
        transactionId: clientApplicationId,
        transactionTotal: amountCleanedNumber,
    });
    sendGAEvent("sent");
}
