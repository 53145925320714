import React, { useState, useEffect, FunctionComponent, ReactNode } from "react";
import { InputStyleProps } from "@opr-finance/utils";
import styled from "styled-components";
import { css, SystemStyleObject } from "@styled-system/css";
import { Font } from "@opr-finance/component-fonts";

export type CheckboxConfigProps = {
    onClick: (checked: boolean) => void;
    styleConfig: InputStyleProps;
    children: ReactNode;
};

export type RootCheckboxProps = InputStyleProps & CheckboxConfigProps;

export const Root: FunctionComponent<RootCheckboxProps> = styled.div<RootCheckboxProps>`
    ${(props: RootCheckboxProps) => {
        return css(props.styleConfig);
    }}
`;

export type CheckboxProps = {
    key?: string;
    checked: boolean;
    checkedIcon: ReactNode;
    onClick: (checked: boolean) => void;
    validationError?: boolean;
    validationErrorMessage?: string;
    validationErrorIcon?: ReactNode;
    styleConfig: {
        root: InputStyleProps;
        checked: InputStyleProps;
        validationError?: InputStyleProps;
        validationMessage?: SystemStyleObject;
    };
};

export function CheckboxField(props: CheckboxProps) {
    const validationEnabled: boolean = props.validationError !== undefined;
    const isValid: boolean = validationEnabled && props.validationError !== true;

    if (validationEnabled) {
        const styleConfig =
            !isValid && props.styleConfig.validationError
                ? props.styleConfig.validationError
                : props.styleConfig.root;

        return (
            <Root key={props.key} onClick={props.onClick} styleConfig={styleConfig}>
                {props.checked && props.checkedIcon && props.checkedIcon}
            </Root>
        );
    }

    return (
        <Root key={props.key} onClick={props.onClick} styleConfig={props.styleConfig.root}>
            {props.checked && props.checkedIcon && props.checkedIcon}
        </Root>
    );
}
