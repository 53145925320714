import styled from "styled-components";
import { flexbox, layout, space, FlexboxProps, LayoutProps, SpaceProps } from "styled-system";
import { FunctionComponent } from "react";
import { AppThemeProps } from "@opr-finance/themes";

export type BaseLogoProps = FlexboxProps & LayoutProps & SpaceProps;
export type BaseLogoPropsWithTheme = BaseLogoProps & AppThemeProps;

export const BaseLogo: FunctionComponent<BaseLogoProps> = styled.div<BaseLogoProps>`
    display: flex;
    ${flexbox}
    ${layout}
    ${space}
    cursor: pointer;
    img {
        width: 100%;
    }
`;
