import { takeEvery, put, call, select, delay } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";
import { generateKey } from "@opr-finance/utils/src/key";
import { setDaysValid, createDataObject } from "../utils";

import {
    E_EnableBankingActionConstants,
    enableBankingActions,
    getBanksList,
    sendToEnableBanking,
    T_FeatureEnableBankingState,
    T_EnableBankingBank,
} from "../";
import { T_DataToEb } from "../types/types";

export function* watchFetchBankListTrigger() {
    yield takeEvery(
        E_EnableBankingActionConstants.ENABLE_BANKING_GET_BANKS_TRIGGER,
        handleFetchBankListTrigger
    );
}

export function* handleFetchBankListTrigger() {
    try {
        const { mockApiCalls, gwUrl } = yield select(
            (state: T_FeatureEnableBankingState) => state.enableBanking.config
        );

        const data: T_EnableBankingBank[] = yield call(getBanksList, { mockApiCalls, gwUrl });

        yield put(enableBankingActions.enableBankingGetBanksSuccess(data));
    } catch (error) {
        yield put(enableBankingActions.enableBankingGetBanksError());
    }
}

export function* watchSendToEnableBankingTrigger() {
    yield takeEvery(
        E_EnableBankingActionConstants.SEND_DATA_TO_ENABLE_BANKING_TRIGGER,
        handleSendToEnableBankingTrigger
    );
}

export function* handleSendToEnableBankingTrigger(
    action: ActionType<typeof enableBankingActions.sendDataToEnableBankingTrigger>
) {
    try {
        const mock: boolean = process.env.REACT_APP_MOCK === "1" ? true : false;
        const uuid = generateKey();
        const data: T_DataToEb = createDataObject(uuid, action);

        yield localStorage.setItem("applicationUuid", uuid);
        const response = yield call(sendToEnableBanking, data);

        if (response.code) {
            console.log(
                `Enable Banking: An error of type ${response.code} occurred with message '${response.message}'`
            );
            yield put(enableBankingActions.sendDataToEnableBankingError(response));
        } else {
            console.log(response);
            yield put(enableBankingActions.sendDataToEnableBankingSuccess(response));
        }
    } catch (e) {
        console.log("an error occured");
    }
}
