import { userLoginActions } from "@opr-finance/feature-luvittaja-login";
import { takeEvery, put } from "redux-saga/effects";
import { AppActionConstants } from "../actions/actions";

export function* watchPreviewPageTrigger() {
    yield takeEvery(AppActionConstants.PREVIEW_PAGE_TRIGGER, handlePreviewPageTrigger);
}

export function* handlePreviewPageTrigger() {
    try {
        const mock: boolean = process.env.REACT_APP_MOCK === "1" ? true : false;

        yield put(
            userLoginActions.loginInitializer({
                mockLogin: mock,
                loginUrl: process.env.REACT_APP_LOGIN_URL as string,
                verifyUrl: process.env.REACT_APP_LOGIN_VERIFY_URL as string,
                errorUrl: process.env.REACT_APP_LOGIN_ERROR_URL as string,
                successUrl: process.env.REACT_APP_LOGIN_SUCCESS_URL as string,
                logoutUrl: process.env.REACT_APP_LOGOUT_URL as string,
            })
        );

        yield put(userLoginActions.loginCheckStatus());
    } catch (e) {
        console.log("preview trigger failed");
    }
}
