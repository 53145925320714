import React, { useEffect, useState } from "react";
import { useForm, CheckboxField } from "@opr-finance/component-forms";
import { Font } from "@opr-finance/component-fonts";
import styled from "styled-components";
import { Application, AppState, ContractsFormData } from "../../types/general";
import { FontStyleProps, InputStyleProps } from "@opr-finance/utils";
import { Icon } from "@opr-finance/component-icon";
import { ModalDialog } from "@opr-finance/component-modal-dialog";
import { SystemStyleObject } from "@styled-system/css";
import { Sekki } from "../contracts/Sekki";
import { TermsAndConditions } from "../contracts/TermsAndConditions";
import { CreditAgreement } from "../contracts/CreditAgreement";
import { Box } from "@opr-finance/component-box-container";
import { FormContainer } from "../FormContainer";
import { FormItem } from "../FormItem";
import { FORM_CONTRACTS_LABEL, FORM_CONTRACTS_NAME } from "../../constants";
import * as yup from "yup";
import exclam from "../../images/exclam.svg";
import { hasLocalApplication } from "../../util";
import { useSelector } from "react-redux";
import { UserTokenData } from "@opr-finance/feature-luvittaja-login";

const Checkboxes = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
`;

const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 16px 0;
`;

export type ContactsFormProps = {
    currentForm: string;
    blurredForms: string[];
    validForms: string[];
    application: Application;
    onChange: (isValid: boolean, form: ContractsFormData) => void;
    onBlur: () => void;
    onFocus: () => void;
    styleConfig: {
        body: FontStyleProps;
        bodyLabel: SystemStyleObject;
        bodyTitle: FontStyleProps;
        checkbox: InputStyleProps;
        checkboxDisabled: InputStyleProps;
        checkboxText: FontStyleProps;
        formError: FontStyleProps;
        link: SystemStyleObject;
        box: SystemStyleObject;
        inlineBox: SystemStyleObject;
    };
};

export function ContractsForm(props: ContactsFormProps) {
    const schema = yup.object().shape({
        commonLoanTerms: yup
            .boolean()
            .oneOf([true], "Pakko hyväksyy kaikki")
            .required("Pakko hyväksyy kaikki"),
        termsAndCreditAgreement: yup
            .boolean()
            .oneOf([true], "Pakko hyväksyy kaikki")
            .required("Pakko hyväksyy kaikki"),
        sekki: yup
            .boolean()
            .oneOf([true], "Pakko hyväksyy kaikki")
            .required("Pakko hyväksyy kaikki"),
        allowAllContracts: yup
            .boolean()
            .oneOf([true], "Pakko hyväksyy kaikki")
            .required("Pakko hyväksyy kaikki"),
    });

    const { form, processChange, processSubmit } = useForm<ContractsFormData>({
        initial: {
            commonLoanTerms: props.application.commonLoanTerms,
            termsAndCreditAgreement: props.application.termsAndCreditAgreement,
            sekki: props.application.sekki,
            payDirectly: props.application.payDirectly,
            allowAllContracts: props.application.allowAllContracts,
        },
        schema,
        onChange: (data) => {
            props.onChange(true, data);
        },
        onError: (data) => {
            props.onChange(false, data);
        },
        onBlur: () => {
            props.onBlur();
        },
        onFocus: () => {
            props.onFocus();
        },
        styleConfig: props.styleConfig,
    });

    const [isCommonLoanTermsOpen, setIsCommonLoanTermsOpen] = useState<boolean>(false);
    const [isSekkiOpen, setIsSekkiOpen] = useState<boolean>(false);
    const [isTermsOpen, setIsTermsOpen] = useState<boolean>(false);
    const tokenData = useSelector((state: AppState) => {
        return state.user.tokenData;
    });

    const commonLoansHasBeenRead = () => {
        setIsCommonLoanTermsOpen(false);
        processChange({
            field: "commonLoanTerms",
            value: handleAcceptTerms(form.data.commonLoanTerms),
            validate: true,
            touched: true,
        });
    };

    const sekkiHasBeenRead = () => {
        setIsSekkiOpen(false);
        processChange({
            field: "sekki",
            value: handleAcceptTerms(form.data.sekki),
            validate: true,
            touched: true,
        });
    };

    const termsHasBeenRead = () => {
        setIsTermsOpen(false);
        processChange({
            field: "termsAndCreditAgreement",
            value: handleAcceptTerms(form.data.termsAndCreditAgreement),
            validate: true,
            touched: true,
        });
    };

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isCommonLoanTermsOpen) {
                if (e.target.classList.contains("ReactModal__Overlay")) {
                    commonLoansHasBeenRead();
                }
            } else if (isSekkiOpen) {
                if (e.target.classList.contains("ReactModal__Overlay")) {
                    sekkiHasBeenRead();
                }
            } else if (isTermsOpen) {
                if (e.target.classList.contains("ReactModal__Overlay")) {
                    termsHasBeenRead();
                }
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isCommonLoanTermsOpen, isSekkiOpen, isTermsOpen]);

    useEffect(() => {
        if (!hasLocalApplication()) return;
        processSubmit();
    }, []);

    const handleAcceptTerms = (value: boolean): boolean => {
        if (!value) return !value;
        return value;
    };

    return (
        <FormContainer
            title={FORM_CONTRACTS_LABEL}
            blurredForms={props.blurredForms}
            validForms={props.validForms}
            currentForm={props.currentForm}
            name={FORM_CONTRACTS_NAME}
            styleConfig={{
                title: props.styleConfig.bodyTitle,
            }}>
            <ModalDialog
                isOpen={isCommonLoanTermsOpen}
                onClick={(e) => {
                    commonLoansHasBeenRead();
                }}
                dialogVariant="default">
                <TermsAndConditions />
            </ModalDialog>
            <ModalDialog
                isOpen={isTermsOpen}
                onClick={() => {
                    termsHasBeenRead();
                }}
                dialogVariant="default">
                <CreditAgreement
                    application={props.application}
                    tokenData={tokenData as UserTokenData}
                />
            </ModalDialog>
            <ModalDialog
                isOpen={isSekkiOpen}
                onClick={() => {
                    sekkiHasBeenRead();
                }}
                dialogVariant="default">
                <Sekki />
            </ModalDialog>
            <Box styleConfig={{ root: props.styleConfig.inlineBox }}>
                <FormItem
                    left={<img src={exclam} alt="" />}
                    right={
                        <Font styleConfig={{ root: props.styleConfig.body }}>
                            Du måste öppna villkoren (genom att klicka på länkarna) för att
                            acceptera dem
                        </Font>
                    }
                />
            </Box>
            <Checkboxes>
                <div>
                    <CheckboxContainer>
                        <FormItem
                            left={
                                <CheckboxField
                                    checked={form.data.termsAndCreditAgreement}
                                    checkedIcon={<Icon icon={["fa", "check"]} color="#94b93a" />}
                                    onClick={() => {}}
                                    styleConfig={{
                                        root: props.styleConfig.checkboxDisabled,
                                        checked: {},
                                    }}
                                />
                            }
                            right={
                                <Font
                                    styleConfig={{ root: props.styleConfig.link }}
                                    onClick={() => {
                                        setIsTermsOpen(true);
                                    }}>
                                    {`Kreditavtal * ${
                                        !form.data.termsAndCreditAgreement
                                            ? `(Vänligen klicka HÄR för att öppna och godkänna avtalet)`
                                            : ""
                                    }`}
                                </Font>
                            }
                        />
                    </CheckboxContainer>
                </div>
                <div>
                    <CheckboxContainer>
                        <FormItem
                            left={
                                <CheckboxField
                                    checked={form.data.commonLoanTerms}
                                    checkedIcon={<Icon icon={["fa", "check"]} color="#94b93a" />}
                                    onClick={() => {}}
                                    styleConfig={{
                                        root: props.styleConfig.checkboxDisabled,
                                        checked: {},
                                    }}
                                />
                            }
                            right={
                                <Font
                                    styleConfig={{ root: props.styleConfig.link }}
                                    onClick={() => {
                                        setIsCommonLoanTermsOpen(true);
                                    }}>
                                    {`Allmänna Villkor * ${
                                        !form.data.commonLoanTerms
                                            ? `(Vänligen klicka HÄR för att öppna och godkänna avtalet)`
                                            : ""
                                    }`}
                                </Font>
                            }
                        />
                    </CheckboxContainer>
                </div>
                <div>
                    <CheckboxContainer>
                        <FormItem
                            left={
                                <CheckboxField
                                    checked={form.data.sekki}
                                    checkedIcon={<Icon icon={["fa", "check"]} color="#94b93a" />}
                                    onClick={() => {}}
                                    styleConfig={{
                                        root: props.styleConfig.checkboxDisabled,
                                        checked: {},
                                    }}
                                />
                            }
                            right={
                                <Font
                                    styleConfig={{ root: props.styleConfig.link }}
                                    onClick={() => {
                                        setIsSekkiOpen(true);
                                    }}>
                                    {`Standardiserad Europeisk Konsumentkreditinformation * ${
                                        !form.data.sekki
                                            ? `(Vänligen klicka HÄR för att öppna och godkänna avtalet)`
                                            : ""
                                    }`}
                                </Font>
                            }
                        />
                    </CheckboxContainer>
                </div>
                <Box styleConfig={{ root: props.styleConfig.inlineBox }}>
                    <CheckboxContainer>
                        <FormItem
                            left={
                                <CheckboxField
                                    checked={form.data.payDirectly}
                                    checkedIcon={<Icon icon={["fa", "times"]} color="#747578" />}
                                    onClick={() => {
                                        processChange({
                                            field: "payDirectly",
                                            value: !form.data.payDirectly,
                                            validate: true,
                                            touched: true,
                                        });
                                    }}
                                    styleConfig={{
                                        root: props.styleConfig.checkbox,
                                        checked: {},
                                    }}
                                />
                            }
                            right={
                                <Font styleConfig={{ root: props.styleConfig.body }}>
                                    Vid godkänd kredit önskar jag få beviljat kreditbelopp utbetalt
                                    till mitt angivna bankkonto.
                                </Font>
                            }
                        />
                    </CheckboxContainer>
                </Box>
                <Box styleConfig={{ root: props.styleConfig.inlineBox }}>
                    <CheckboxContainer>
                        <FormItem
                            left={
                                <CheckboxField
                                    checked={form.data.allowAllContracts}
                                    checkedIcon={<Icon icon={["fa", "times"]} color="#747578" />}
                                    onClick={() => {
                                        processChange({
                                            field: "allowAllContracts",
                                            value: !form.data.allowAllContracts,
                                            validate: true,
                                            touched: true,
                                        });
                                    }}
                                    styleConfig={{
                                        root: props.styleConfig.checkbox,
                                        checked: {},
                                    }}
                                />
                            }
                            right={
                                <Font styleConfig={{ root: props.styleConfig.body }}>
                                    Jag bekräftar att information om gällande räntesats, effektiv
                                    ränta och avgifter har lämnats till mig innan detta avtal
                                    träffats och att jag har tagit del av de allmänna- och
                                    autogirovillkoren. Genom att skicka in min ansökan godkänner jag
                                    avtalet samt förbinder mig att, om kredit beviljas, solidariskt
                                    betala kreditbeloppet jämte ränta, avgifter och kostnader till
                                    kreditgivaren. *
                                </Font>
                            }
                        />
                    </CheckboxContainer>
                </Box>
            </Checkboxes>
        </FormContainer>
    );
}
