import { FeatureInitializerState } from "@opr-finance/feature-initializer";
import { FeatureUserLoginState } from "@opr-finance/feature-luvittaja-login";
import { T_FeatureEnableBankingState } from "@opr-finance/feature-enable-banking";

export type AppState = FeatureInitializerState &
    FeatureUserLoginState &
    T_FeatureEnableBankingState;

export type EmployementFormData = {
    employementType: string;
    startYear: string;
    startMonth: string;
    endYear: string;
    endMonth: string;
    monthlyIncomeGross: string;
    livingExpenses: string;
    loanExpenses: string;
};

export type BankAccountFormData = {
    bankAccount: string;
};

export type LivingFormData = {
    maritalStatus: string;
    children: string;
    housingForm: string;
};

export type ContactFormData = {
    applicantEmail: string;
    applicantPhone: string;
};

export type PepFormData = {
    isPep: string;
    reason: string;
    reasonInfo: string;
};

export type GuarantorFormData = {
    isGuarantor: string;
    guarantorAmount: string;
};

export type PermitsFormData = {
    allowBisnodeCheck: boolean;
    allowMarketing: boolean;
    allInformationIsCorrect: boolean;
    highestLimitAccepted: boolean;
};

export type ContractsFormData = {
    commonLoanTerms: boolean;
    termsAndCreditAgreement: boolean;
    sekki: boolean;
    payDirectly: boolean;
    allowAllContracts: boolean;
};

export type TrackingData = {
    clientApplicationId: string;
    subsource: string;
    source: string;
    redirectId: string;
    externalReference: string;
};

export type Application = ContactFormData &
    LivingFormData &
    EmployementFormData &
    BankAccountFormData &
    PepFormData &
    GuarantorFormData &
    PermitsFormData &
    ContractsFormData &
    TrackingData;

export enum EmploymentOptionsPlain {
    FULL = "Tillsvidareanställd",
    TRIAL = "Provanställd",
    RETIRED = "Pensionerad",
    PROJECT = "Projektanställd",
    SELF_EMPLOYED = "Egenföretagare",
    NO_WORK = "Arbetslös",
    STUDENT = "Student",
    TEMPORARY = "Vikarie",
}

export enum EmploymentOptions {
    FULL = "FULL",
    TRIAL = "TRIAL",
    RETIRED = "RETIRED",
    PROJECT = "PROJECT",
    SELF_EMPLOYED = "SELF_EMPLOYED",
    NO_WORK = "NO_WORK",
    STUDENT = "STUDENT",
    TEMPORARY = "TEMPORARY",
}

export type QueryParams = {
    state: string;
    code: string;
    error: string;
};
