import { getYear, getMonth, getDate, parseISO } from "date-fns";

const formatNumber = (num: number): string => (num < 10 ? `0${num}` : `${num}`);

export const dateFormatter = (date: string) => {
    const d = new Date(date);

    const day = formatNumber(getDate(d));
    const month = formatNumber(getMonth(d) + 1);
    const year = `${getYear(d)}`;

    const dmy = [day, month, year].join("-");
    const ymd = [year, month, day].join("-");

    return { dmy, ymd };
};
