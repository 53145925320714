import React, { FunctionComponent, ReactNode } from "react";
import styled from "styled-components";
import { css, SystemStyleObject } from "@styled-system/css";
import { InputStyleProps } from "@opr-finance/utils";

export type ButtonFieldProps = {
    children: ReactNode;
    styleConfig: {
        root: SystemStyleObject;
    };
} & ButtonFieldConfigProps;

type ButtonFieldConfigProps = {
    type?: string;
    name?: string;
    onClick?: (e: React.ChangeEvent<HTMLButtonElement>) => void;
    onBlur?: () => void;
    onFocus?: () => void;
    children: ReactNode;
    styleConfig: SystemStyleObject;
};

type ButtonFieldRootProps = ButtonFieldConfigProps & InputStyleProps;

const Button: FunctionComponent<ButtonFieldRootProps> = styled.button<ButtonFieldRootProps>`
    ${(props: ButtonFieldRootProps) => {
        return css(props.styleConfig);
    }}
`;

export function ButtonField(props: ButtonFieldProps) {
    return (
        <Button {...props} styleConfig={props.styleConfig.root}>
            {props.children}
        </Button>
    );
}
