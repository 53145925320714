import React from "react";
import { SystemStyleObject } from "@styled-system/css";

import { Font } from "@opr-finance/component-fonts";

import { StyledContainer, StyledLabel, StyledField } from "./LabeledField.styled";
import { LabeledFieldProps, OrderEnum } from "./types";

const getOrderStyle = (order?: `${OrderEnum}`): SystemStyleObject => {
    if (!order) return { display: "flex" };
    if (order === "row-reverse") {
        return {
            display: "flex",
            flexDirection: order,
            alignItems: "flex-start",
            justifyContent: "flex-end",
        };
    }
    return {
        display: "flex",
        flexDirection: order,
    };
};

export function LabeledField(props: LabeledFieldProps) {
    const defaultStyles: SystemStyleObject = getOrderStyle(props?.order);

    if (props.errorMessage !== undefined && !props.isValid) {
        return (
            <StyledContainer styleConfig={{ root: { ...props.styleConfig?.container } }}>
                <StyledContainer styleConfig={{ root: { ...defaultStyles } }}>
                    <StyledLabel styleConfig={{ root: { ...props.styleConfig?.label } }}>
                        {props.label}
                    </StyledLabel>
                    <StyledField styleConfig={{ root: { ...props.styleConfig?.field } }}>
                        {props.field}
                    </StyledField>
                </StyledContainer>
                <Font
                    styleConfig={{
                        root: props.styleConfig?.errorMsg,
                    }}
                >
                    {props.errorMessage}
                </Font>
            </StyledContainer>
        );
    }
    return (
        <StyledContainer
            styleConfig={{ root: { ...defaultStyles, ...props.styleConfig?.container } }}
        >
            <StyledLabel styleConfig={{ root: { ...props.styleConfig?.label } }}>
                {props.label}
            </StyledLabel>
            <StyledField styleConfig={{ root: { ...props.styleConfig?.field } }}>
                {props.field}
            </StyledField>
        </StyledContainer>
    );
}
