import React from "react";
import { Layout } from "@opr-finance/layout-everyday-application";
import { FlexColumn, Grid, GridContainer, GridContent } from "@opr-finance/component-grid";
import { Logo } from "@opr-finance/component-logo";
import { Header } from "../components/Header";

import logo from "../images/logo.svg";
import { SystemStyleObject } from "@styled-system/css";
import { FontStyleProps } from "@opr-finance/utils";
import { Font } from "@opr-finance/component-fonts";
import { MobileTitle } from "./ApplicationPage";
import { Box } from "@opr-finance/component-box-container";

export type StartPageProps = {
    styleConfig: {
        headerContainer: SystemStyleObject;
        headerContent: SystemStyleObject;
        headerTitle: SystemStyleObject;
        body: FontStyleProps;
        mobilePageTitle: SystemStyleObject;
        box: SystemStyleObject;
        bodyLabel: SystemStyleObject;
    };
};

export function StartPage(props: StartPageProps) {
    return (
        <Layout
            header={
                <Header
                    logo={
                        <Logo
                            logoSrc={logo}
                            width={165}
                            height={35}
                            onClick={() => {
                                window.location.href = process.env
                                    .REACT_APP_MARKETING_PAGE_URL as string;
                            }}
                        />
                    }
                    title={<Font styleConfig={{ root: props.styleConfig.headerTitle }}>Start</Font>}
                    mobileNavigation={{}}
                    styleConfig={{
                        container: props.styleConfig.headerContainer,
                        content: props.styleConfig.headerContent,
                        burgerText: props.styleConfig.body,
                    }}
                />
            }
        >
            <FlexColumn>
                <MobileTitle>
                    <GridContainer>
                        <Grid width={"100%"}>
                            <GridContent alignItems="center">
                                <Font styleConfig={{ root: props.styleConfig.mobilePageTitle }}>
                                    Start
                                </Font>
                            </GridContent>
                        </Grid>
                    </GridContainer>
                </MobileTitle>
                <GridContainer>
                    <Grid width={"100%"}>
                        <GridContent padding={["0 8px 40px 8px"]}>
                            <Box styleConfig={{ root: props.styleConfig.box }}>
                                <Font styleConfig={{ root: props.styleConfig.bodyLabel }}>
                                    Just nu tar vi inte emot ansökningar på EverydayPlus. Vi ber om
                                    ursäkt för detta och hoppas öppna upp ansökningsflödet så fort
                                    som möjligt. Vi uppdaterar er när ansökningsflödet är öppen
                                    igen.
                                </Font>
                            </Box>
                        </GridContent>
                    </Grid>
                </GridContainer>
            </FlexColumn>
        </Layout>
    );
}
