type CheckWithdrawEligibility = {
    availableCreditLimit: number | undefined;
    accountState: string | undefined;
    min: number;
    unpaidAmount: number | undefined;
    overdueDays: number | undefined;
};

type CheckTopupEligibility = {
    creditLimit: number;
    accountState: string | undefined;
    overdueDays: number | undefined;
    paidInvoicesCount: number;
};

export const checkWithdrawEligibility = (data: CheckWithdrawEligibility): boolean => {
    if (
        data.accountState === undefined &&
        data.overdueDays === undefined &&
        data.unpaidAmount === undefined &&
        data.availableCreditLimit === undefined
    )
        return false;
    if (data.availableCreditLimit && data.availableCreditLimit < data.min) return false;
    if (data.accountState !== "OPEN") {
        if (data.accountState !== "PENDING") return false;
    }
    if ((data.unpaidAmount && data.unpaidAmount > 0) || (data.overdueDays && data.overdueDays > 7))
        return false;
    return true;
};

export const checkTopupEligibilityFi = (data: CheckTopupEligibility): boolean => {
    if (
        data.creditLimit === undefined &&
        data.accountState === undefined &&
        data.overdueDays === undefined &&
        data.paidInvoicesCount === undefined
    )
        return false;
    if (data.creditLimit === 20000) return false;
    if (data.accountState !== "OPEN") return false;
    if (data.overdueDays && data.overdueDays > 7) return false;
    if (data.paidInvoicesCount < 1) return false;
    return true;
};

export const checkTopupEligibilitySe = (data: CheckTopupEligibility): boolean => {
    if (
        data.creditLimit === undefined &&
        data.accountState === undefined &&
        data.overdueDays === undefined &&
        data.paidInvoicesCount === undefined
    )
        return false;
    if (data.creditLimit === 200000) return false;
    if (data.accountState !== "OPEN") return false;
    if (data.overdueDays && data.overdueDays > 5) return false;
    if (data.paidInvoicesCount < 3) return false;
    return true;
};

export const checkTopupEligibilityNl = (data: CheckTopupEligibility): boolean => {
    if (
        data.creditLimit === undefined &&
        data.accountState === undefined &&
        data.overdueDays === undefined &&
        data.paidInvoicesCount === undefined
    )
        return false;
    if (data.creditLimit === 40000) return false;
    if (data.accountState !== "OPEN") return false;
    if (data.overdueDays && data.overdueDays > 5) return false;
    if (data.paidInvoicesCount < 3) return false;
    return true;
};
