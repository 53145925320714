import React, { FunctionComponent, ReactNode } from "react";
import { BaseContainer, BaseGrid, BaseGridContent, BaseGridProps } from "./Grid.styled";

export type GridProps = BaseGridProps & {
    children?: ReactNode;
};

export type GridContentProps = BaseGridProps & {
    gap?: string;
    children?: ReactNode;
};

export const GridContainer: FunctionComponent<GridProps> = (props) => {
    return <BaseContainer {...props}>{props.children}</BaseContainer>;
};

export const FullGridContainer: FunctionComponent<GridProps> = (props) => {
    return (
        <BaseContainer maxWidth="100%" {...props}>
            {props.children}
        </BaseContainer>
    );
};

export const FlexColumn: FunctionComponent<GridProps> = (props) => {
    return (
        <BaseGrid flexDirection={"column"} width={"100%"} {...props}>
            {props.children}
        </BaseGrid>
    );
};

export const FlexRow: FunctionComponent<GridProps> = (props) => {
    return (
        <BaseGrid
            display={"flex"}
            flexDirection={{
                default: "row",
                tablet: "column",
                mobile: "column",
            }}
            width={"100%"}
            height={"100%"}
            {...props}>
            {props.children}
        </BaseGrid>
    );
};

export const Grid: FunctionComponent<GridProps> = (props) => {
    return (
        <BaseGrid
            display={"flex"}
            width={props.width ? props.width : "100%"}
            height={"100%"}
            {...props}>
            {props.children}
        </BaseGrid>
    );
};

export const GridContent: FunctionComponent<GridContentProps> = (props) => {
    return (
        <BaseGridContent
            display={"flex"}
            height={"100%"}
            width={props.width ? props.width : "100%"}
            {...props}>
            {props.children}
        </BaseGridContent>
    );
};

export const GridPageTitle: FunctionComponent<GridProps> = (props) => {
    return (
        <BaseContainer padding={"24px 22px"} {...props}>
            {props.children}
        </BaseContainer>
    );
};
