import { takeEvery, put, call, select } from "redux-saga/effects";
import { FeatureInitializerState, InitializerReducerState } from "../types/initializer";
import { initializerActions } from "../actions/initializer";

export function* watchInitializers() {
    yield takeEvery("*", handleInitializer);
}

export function* handleInitializer(action: any): Generator {
    try {
        const initializers = (yield select((state: FeatureInitializerState) => {
            return state.initializer;
        })) as InitializerReducerState;

        if (initializers.initializers.includes(action.type)) {
            yield put(
                initializerActions.completeInitializer({
                    action: action.type,
                })
            );

            const updatedInitializers = (yield select((state: FeatureInitializerState) => {
                return state.initializer;
            })) as InitializerReducerState;

            if (
                updatedInitializers.initialized.length === updatedInitializers.initializers.length
            ) {
                yield put(initializerActions.initApplicationSuccess());
            }
        }
    } catch (e) {
        console.log("initializer handler failed", e);
    }
}
