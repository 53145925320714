import { LoanApplicationRequest } from "@opr-finance/api-definitions";
import { ActionType, createAction } from "typesafe-actions";
import { Application } from "../types/general";

export enum AppActionConstants {
    APPLICATION_PAGE_TRIGGER = "APPLICATION_PAGE_TRIGGER",
    LOGIN_PAGE_TRIGGER = "LOGIN_PAGE_TRIGGERR",
    COMPLETED_PAGE_TRIGGER = "COMPLETED_PAGE_TRIGGER",
    UPDATE_APPLICATION = "UPDATE_APPLICATION",
    SEND_APPLICATION_TRIGGER = "SEND_APPLICATION_TRIGGER",
    PREVIEW_PAGE_TRIGGER = "PREVIEW_PAGE_TRIGGER",
    SENDING_PAGE_TRIGGER = "SENDING_PAGE_TRIGGER",
    CHOOSE_BANK_TRIGGER = "CHOOSE_BANK_TRIGGER",
    SEND_DATA_TO_ENABLE_BANKING_TRIGGER = "SEND_DATA_TO_ENABLE_BANKING_TRIGGER",
    SEND_DATA_TO_ENABLE_BANKING_SUCCESS = "SEND_DATA_TO_ENABLE_BANKING_SUCESS",
}

export const appActions = {
    applicationPageTrigger: createAction(AppActionConstants.APPLICATION_PAGE_TRIGGER)(),
    chooseBankTrigger: createAction(AppActionConstants.CHOOSE_BANK_TRIGGER)(),
    loginPageTrigger: createAction(AppActionConstants.LOGIN_PAGE_TRIGGER)(),
    completedPageTrigger: createAction(AppActionConstants.COMPLETED_PAGE_TRIGGER)(),
    updateApplication: createAction(AppActionConstants.UPDATE_APPLICATION)<Application>(),
    sendApplicationTrigger: createAction(
        AppActionConstants.SEND_APPLICATION_TRIGGER
    )<Application>(),
    previewPageTrigger: createAction(AppActionConstants.PREVIEW_PAGE_TRIGGER)(),
    sendingPageTrigger: createAction(AppActionConstants.SENDING_PAGE_TRIGGER)<{ code: string }>(),
};

export type AppAction = ActionType<typeof appActions>;
