import { httpFaker, WithAuthToken, WithMockApiCalls, WithGwUrl } from "@opr-finance/utils";

export type VerifyTokenRequest = {
    url: string;
} & WithAuthToken &
    WithMockApiCalls;

export async function loginVerifyToken(data: VerifyTokenRequest): Promise<boolean> {
    const method = "POST";
    const headers = {
        "content-type": "application/json",
        Authorization: data.token as string,
    };
    console.log("data for verify request", data);

    if (data.mockApiCalls) {
        console.log("do fake request!");
        return httpFaker<VerifyTokenRequest, boolean>(data.url, method, data, true);
    } else {
        console.log("do real request!", data, headers, method);
        const result: Response = await fetch(data.url, {
            method,
            headers,
        });

        return result.status === 200;
    }
}
