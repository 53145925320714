import { ActionType } from "typesafe-actions";
import { takeEvery, put, call } from "redux-saga/effects";
import { userLoginActions } from "@opr-finance/feature-luvittaja-login";
import { AppActionConstants, appActions } from "../actions/actions";
import { sendCodeAndUuid } from "@opr-finance/feature-enable-banking/src/api/index.api";
import { enableBankingActions } from "@opr-finance/feature-enable-banking";

export function* handleSendingPageTrigger(
    action: ActionType<typeof appActions.sendingPageTrigger>
) {
    try {
        const response = yield call(() => sendCodeAndUuid(action.payload));
        yield put(enableBankingActions.earlyReturnFromFrendsTrigger(response));
    } catch (e: any) {
        let errorMessage = "An error ocurred";
        console.log(`${errorMessage}: ${e.message}`);
    }
}

export function* watchSendingPageTrigger() {
    yield takeEvery(AppActionConstants.SENDING_PAGE_TRIGGER, handleSendingPageTrigger);
}
