import React from "react";
import defaultLoader from "./loader.svg";

export type LoaderProps = {
    isLoading: boolean;
    loader?: string;
};

export const Loader = (props: LoaderProps) => {
    if (!props.isLoading) {
        return null;
    }
    return <img src={props.loader ? props.loader : defaultLoader} alt="" />;
};
