import React, { ReactNode, FunctionComponent } from "react";
import { FontStyleProps } from "@opr-finance/utils";
import { space, color, layout, typography, flex } from "styled-system";
import { css, SystemStyleObject } from "@styled-system/css";
import styled from "styled-components";

export type FontProps = {
    children: ReactNode;
    onClick?: () => void;
    key?: string;
    styleConfig?: {
        root?: SystemStyleObject;
    };
    as?: "p" | "span";
};

type RootProps = {
    children: ReactNode;
    onClick?: () => void;
    styleConfig?: SystemStyleObject;
};

const Root: FunctionComponent<RootProps> = styled.span<RootProps>`
    ${(props: RootProps) => {
        return css(props.styleConfig);
    }}
`;

const RootP: FunctionComponent<RootProps> = styled.p<RootProps>`
    ${(props: RootProps) => {
        return css(props.styleConfig);
    }}
`;

export function Font(props: FontProps) {
    if (props.as === "p") {
        return (
            <RootP {...props} styleConfig={props.styleConfig?.root}>
                {props.children}
            </RootP>
        );
    }
    return (
        <Root {...props} styleConfig={props.styleConfig?.root}>
            {props.children}
        </Root>
    );
}
