import React from "react";
import { Font } from "@opr-finance/component-fonts";

import { StyledFieldSet, FormContainer } from "./FieldSet.styled";
import { FieldSetProps } from "./types";

export function FieldSet(props: FieldSetProps) {
    return (
        <FormContainer id={props.id} styleConfig={{ container: props.styleConfig.container }}>
            <StyledFieldSet styleConfig={{ form: props.styleConfig.form }}>
                {props.title && (
                    <Font styleConfig={{ root: props.styleConfig.title }}>{props.title}</Font>
                )}
                {props.children}
            </StyledFieldSet>
        </FormContainer>
    );
}
