import { takeEvery, put } from "redux-saga/effects";
import { enableBankingActions } from "@opr-finance/feature-enable-banking";
import { userLoginActions } from "@opr-finance/feature-luvittaja-login";
import { AppActionConstants } from "../actions/actions";

export function* watchChooseBankPageTrigger() {
    yield takeEvery(AppActionConstants.CHOOSE_BANK_TRIGGER, handleChooseBankPageTrigger);
}

export function* handleChooseBankPageTrigger() {
    try {
        const mock: boolean = process.env.REACT_APP_MOCK === "1" ? true : false;

        yield put(
            userLoginActions.loginInitializer({
                mockLogin: mock,
                loginUrl: process.env.REACT_APP_LOGIN_URL as string,
                verifyUrl: process.env.REACT_APP_LOGIN_VERIFY_URL as string,
                errorUrl: process.env.REACT_APP_LOGIN_ERROR_URL as string,
                successUrl: process.env.REACT_APP_LOGIN_SUCCESS_URL as string,
                logoutUrl: process.env.REACT_APP_LOGOUT_URL as string,
            })
        );

        yield put(userLoginActions.loginCheckStatus());

        yield put(
            enableBankingActions.enableBankingInitializer({
                mockApiCalls: mock,
                gwUrl: process.env.REACT_APP_FRENDS_URL as string,
            })
        );

        yield put(enableBankingActions.enableBankingGetBanksTrigger());
    } catch (e) {
        console.log("Choose bank trigger failed");
    }
}
