import { takeEvery } from "redux-saga/effects";
import { AppActionConstants } from "../actions/actions";

export function* watchSendApplicationTrigger() {
    yield takeEvery(AppActionConstants.SEND_APPLICATION_TRIGGER, handleSendApplicationTrigger);
}

export function* handleSendApplicationTrigger() {
    try {
        yield console.log("send application trigger");
    } catch (e) {
        console.log("send application trigger failed");
    }
}
