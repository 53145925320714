import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Grid, GridContainer, GridContent, FlexColumn } from "@opr-finance/component-grid";
import { FontStyleProps, InputStyleProps, add, remove } from "@opr-finance/utils";
import { Font } from "@opr-finance/component-fonts";
import { Box } from "@opr-finance/component-box-container";
import { SystemStyleObject } from "@styled-system/css";
import { userLoginActions } from "@opr-finance/feature-luvittaja-login";
import { ButtonField } from "@opr-finance/component-forms";
import { Icon } from "@opr-finance/component-icon";
import { breadcrumbTitle } from "@opr-finance/theme-everyday-application";
import { Logo } from "@opr-finance/component-logo";
import { Layout } from "@opr-finance/layout-everyday-application";

import styled from "styled-components";
import { ContactForm } from "../components/forms/ContactForm";
import { Application } from "../types/general";
import { initialApplication, sendGAData, mockApplication } from "../util";
import { LivingForm } from "../components/forms/LivingForm";
import { EmployementForm } from "../components/forms/EmployementForm";
import { PepForm } from "../components/forms/PepForm";
import { PermitsForm } from "../components/forms/PermitsForm";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { WarningItem } from "../components/WarningItem";
import { Header } from "../components/Header";
import logo from "../images/logo.svg";
import {
    APPLICATION_PAGE_FORMS,
    BREADCRUMB_STEPS,
    BREADCRUMB_STEP_APPLICATION,
    FORM_CONTACT_NAME,
    FORM_EMPLOYEMENT_NAME,
    FORM_LIVING_NAME,
    FORM_PEP_NAME,
    FORM_GUARANTOR_NAME,
    FORM_PERMITS_NAME,
    LOCAL_STORAGE_APPLICATION,
    PAGE_TITLE,
} from "../constants";
import { SideContentApplication } from "../components/SideContentApplication";
import { GuarantorForm } from "../components/forms/GuarantorForm";

const SubmitButtonIconContainer = styled.div`
    padding: 0 4px 0 0;
`;

const SubmitButtonTextContainer = styled.div``;

const WarningIcon = styled.div`
    width: 40px;
    height: 34px;
    color: red;
`;

const SideBoxContainer = styled.div`
    display: flex;
    visibility: visible;
    @media (max-width: 786px) {
        display: none;
        visibility: none;
    }
`;

export const MobileTitle = styled.div`
    display: none;
    visibility: none;
    @media (max-width: 786px) {
        display: flex;
        visibility: visible;
    }
`;

export type PipelinePageProps = {
    styleConfig: {
        headerContainer: SystemStyleObject;
        headerContent: SystemStyleObject;
        headerTitle: SystemStyleObject;
        body: FontStyleProps;
        bodyLabel: SystemStyleObject;
        bodyWarning: SystemStyleObject;
        bodyTitle: FontStyleProps;
        pageTitle: FontStyleProps;
        textField: InputStyleProps;
        select: InputStyleProps;
        checkbox: InputStyleProps;
        checkboxDisabled: InputStyleProps;
        checkboxText: FontStyleProps;
        button: InputStyleProps;
        buttonText: FontStyleProps;
        formError: FontStyleProps;
        link: FontStyleProps;
        inlineBox: SystemStyleObject;
        box: SystemStyleObject;
        sideBox: SystemStyleObject;
        sideTitle: SystemStyleObject;
        sideLink: SystemStyleObject;
        breadcrumbTitle: SystemStyleObject;
        breadcrumbBox: SystemStyleObject;
        buttonInfo: SystemStyleObject;
        buttonCancel: SystemStyleObject;
        buttonSubmit: SystemStyleObject;
        buttonSubmitDisabled: SystemStyleObject;
        mobilePageTitle: SystemStyleObject;
        textFieldsContainer: SystemStyleObject;
        multipleTextField: SystemStyleObject;
    };
};

export function ApplicationPage(props: PipelinePageProps) {
    const dispatch = useDispatch();
    const [gaEventSent, setGaEventSent] = useState<boolean>(false);
    const [validForms, setValidForms] = useState<string[]>([]);
    const [application, setApplication] = useState<Application>(initialApplication());
    const [currentForm, setCurrentForm] = useState<string>("");
    const [blurredForms, setBlurredForms] = useState<string[]>([""]);

    const showAutoFillButtons =
        (process.env.REACT_APP_SHOW_AUTO_FILL_BUTTONS as string) === "1" ? true : false;

    function initGA() {
        if (!gaEventSent) {
            const clientApplicationId = (window as any).clientApplicationId;
            sendGAData({
                clientApplicationId: clientApplicationId,
                transactionId: clientApplicationId,
                event: "start",
            });
            setGaEventSent(true);
        }
    }

    useEffect(() => {
        initGA();
    }, []);

    function onSubmit() {
        if (validForms.length === APPLICATION_PAGE_FORMS) {
            console.log("got application to cache", application);
            const cachedApplication = JSON.stringify(application);
            localStorage.setItem(LOCAL_STORAGE_APPLICATION, cachedApplication);
            console.log("cachedApplication: ", cachedApplication);
            dispatch(userLoginActions.loginStart());
        }
    }

    function handleChange(isValid, formName, form) {
        if (isValid) {
            setValidForms(add(validForms, formName));
        } else {
            setValidForms(remove(validForms, formName, (a, b) => a === b));
        }
        setApplication({ ...application, ...form });
        console.log(application);
    }

    function handleBlur(formName) {
        console.log("handle blur", formName);
        setCurrentForm("");
    }

    function handleFocus(formName) {
        console.log("handle focus", formName);
        setBlurredForms(add(blurredForms, formName));
        setCurrentForm(formName);
    }

    function autoFillForm() {
        console.log("auto filling form");
        const mockApplicationJson = JSON.stringify(mockApplication);
        localStorage.setItem(LOCAL_STORAGE_APPLICATION, mockApplicationJson);
        dispatch(userLoginActions.loginStart());
    }

    return (
        <Layout
            header={
                <Header
                    logo={
                        <Logo
                            logoSrc={logo}
                            width={165}
                            height={35}
                            onClick={() => {
                                window.location.href = process.env
                                    .REACT_APP_MARKETING_PAGE_URL as string;
                            }}
                        />
                    }
                    title={
                        <Font styleConfig={{ root: props.styleConfig.headerTitle }}>
                            {PAGE_TITLE}
                        </Font>
                    }
                    mobileNavigation={
                        <SideContentApplication
                            blurredForms={blurredForms}
                            currentForm={currentForm}
                            totalForms={APPLICATION_PAGE_FORMS}
                            validForms={validForms}
                            onSubmit={onSubmit}
                            styleConfig={{
                                bodyTitle: props.styleConfig.bodyTitle,
                                sideTitle: props.styleConfig.sideTitle,
                                sideLink: props.styleConfig.sideLink,
                                buttonSubmit: props.styleConfig.buttonSubmit,
                                buttonInfo: props.styleConfig.buttonInfo,
                            }}
                        />
                    }
                    styleConfig={{
                        container: props.styleConfig.headerContainer,
                        content: props.styleConfig.headerContent,
                        burgerText: props.styleConfig.body,
                    }}
                />
            }
        >
            <FlexColumn>
                <MobileTitle>
                    <GridContainer>
                        <Grid width={"100%"}>
                            <GridContent alignItems="center">
                                <Font styleConfig={{ root: props.styleConfig.mobilePageTitle }}>
                                    {PAGE_TITLE}
                                </Font>
                            </GridContent>
                        </Grid>
                    </GridContainer>
                </MobileTitle>
                <GridContainer>
                    <Grid width={7 / 10}>
                        <GridContent padding={["0 8px 40px 8px"]}>
                            <Box styleConfig={{ root: props.styleConfig.breadcrumbBox }}>
                                <Breadcrumbs
                                    steps={BREADCRUMB_STEPS}
                                    step={BREADCRUMB_STEP_APPLICATION}
                                    styleConfig={{
                                        step: breadcrumbTitle(),
                                    }}
                                />
                            </Box>
                        </GridContent>
                    </Grid>
                    <Grid width={3 / 10} />
                </GridContainer>
                <GridContainer>
                    <Grid width={7 / 10}>
                        <FlexColumn height="100%">
                            <GridContent>
                                <Box styleConfig={{ root: props.styleConfig.box }}>
                                    {showAutoFillButtons && (
                                        <button onClick={autoFillForm}>Auto fill</button>
                                    )}
                                    <ContactForm
                                        currentForm={currentForm}
                                        validForms={validForms}
                                        blurredForms={blurredForms}
                                        application={application}
                                        onChange={(isValid, form) => {
                                            handleChange(isValid, FORM_CONTACT_NAME, form);
                                        }}
                                        onBlur={() => {
                                            handleBlur(FORM_CONTACT_NAME);
                                        }}
                                        onFocus={() => {
                                            handleFocus(FORM_CONTACT_NAME);
                                        }}
                                        styleConfig={{
                                            body: props.styleConfig.body,
                                            bodyLabel: props.styleConfig.bodyLabel,
                                            bodyTitle: props.styleConfig.bodyTitle,
                                            textField: props.styleConfig.textField,
                                            formError: props.styleConfig.formError,
                                        }}
                                    />
                                </Box>
                            </GridContent>
                            <GridContent>
                                <Box styleConfig={{ root: props.styleConfig.box }}>
                                    <LivingForm
                                        currentForm={currentForm}
                                        validForms={validForms}
                                        blurredForms={blurredForms}
                                        application={application}
                                        onChange={(isValid, form) => {
                                            handleChange(isValid, FORM_LIVING_NAME, form);
                                        }}
                                        onBlur={() => {
                                            handleBlur(FORM_LIVING_NAME);
                                        }}
                                        onFocus={() => {
                                            handleFocus(FORM_LIVING_NAME);
                                        }}
                                        styleConfig={{
                                            body: props.styleConfig.body,
                                            bodyLabel: props.styleConfig.bodyLabel,
                                            bodyTitle: props.styleConfig.bodyTitle,
                                            textField: props.styleConfig.textField,
                                            select: props.styleConfig.select,
                                            formError: props.styleConfig.formError,
                                        }}
                                    />
                                </Box>
                            </GridContent>
                            <GridContent>
                                <Box styleConfig={{ root: props.styleConfig.box }}>
                                    <EmployementForm
                                        currentForm={currentForm}
                                        validForms={validForms}
                                        blurredForms={blurredForms}
                                        application={application}
                                        onChange={(isValid, form) => {
                                            handleChange(isValid, FORM_EMPLOYEMENT_NAME, form);
                                        }}
                                        onBlur={() => {
                                            handleBlur(FORM_EMPLOYEMENT_NAME);
                                        }}
                                        onFocus={() => {
                                            handleFocus(FORM_EMPLOYEMENT_NAME);
                                        }}
                                        styleConfig={{
                                            body: props.styleConfig.body,
                                            bodyLabel: props.styleConfig.bodyLabel,
                                            bodyTitle: props.styleConfig.bodyTitle,
                                            select: props.styleConfig.select,
                                            textField: props.styleConfig.textField,
                                            formError: props.styleConfig.formError,
                                            textFieldsContainer:
                                                props.styleConfig.textFieldsContainer,
                                            multipleTextField: props.styleConfig.multipleTextField,
                                        }}
                                    />
                                </Box>
                            </GridContent>
                            {/* <GridContent>
                                <Box styleConfig={{ root: props.styleConfig.box }}>
                                    <BankAccountForm
                                        application={application}
                                        currentForm={currentForm}
                                        validForms={validForms}
                                        blurredForms={blurredForms}
                                        onChange={(isValid, form) => {
                                            handleChange(isValid, FORM_BANK_ACCOUNT_NAME, form);
                                        }}
                                        onBlur={() => {
                                            handleBlur(FORM_BANK_ACCOUNT_NAME);
                                        }}
                                        onFocus={() => {
                                            handleFocus(FORM_BANK_ACCOUNT_NAME);
                                        }}
                                        styleConfig={{
                                            body: props.styleConfig.body,
                                            bodyLabel: props.styleConfig.bodyLabel,
                                            bodyTitle: props.styleConfig.bodyTitle,
                                            textField: props.styleConfig.textField,
                                            formError: props.styleConfig.formError,
                                        }}
                                    />
                                </Box>
                            </GridContent> */}
                            <GridContent>
                                <Box styleConfig={{ root: props.styleConfig.box }}>
                                    <PepForm
                                        currentForm={currentForm}
                                        validForms={validForms}
                                        blurredForms={blurredForms}
                                        application={application}
                                        onChange={(isValid, form) => {
                                            handleChange(isValid, FORM_PEP_NAME, form);
                                        }}
                                        onBlur={() => {
                                            handleBlur(FORM_PEP_NAME);
                                        }}
                                        onFocus={() => {
                                            handleFocus(FORM_PEP_NAME);
                                        }}
                                        styleConfig={{
                                            body: props.styleConfig.body,
                                            bodyLabel: props.styleConfig.bodyLabel,
                                            bodyTitle: props.styleConfig.bodyTitle,
                                            checkbox: props.styleConfig.checkbox,
                                            checkboxText: props.styleConfig.checkboxText,
                                            textField: props.styleConfig.textField,
                                            formError: props.styleConfig.formError,
                                            select: props.styleConfig.select,
                                        }}
                                    />
                                </Box>
                            </GridContent>
                            <GridContent>
                                <Box styleConfig={{ root: props.styleConfig.box }}>
                                    <GuarantorForm
                                        currentForm={currentForm}
                                        validForms={validForms}
                                        blurredForms={blurredForms}
                                        application={application}
                                        onChange={(isValid, form) => {
                                            handleChange(isValid, FORM_GUARANTOR_NAME, form);
                                        }}
                                        onBlur={() => {
                                            handleBlur(FORM_GUARANTOR_NAME);
                                        }}
                                        onFocus={() => {
                                            handleFocus(FORM_GUARANTOR_NAME);
                                        }}
                                        styleConfig={{
                                            body: props.styleConfig.body,
                                            bodyLabel: props.styleConfig.bodyLabel,
                                            bodyTitle: props.styleConfig.bodyTitle,
                                            checkbox: props.styleConfig.checkbox,
                                            checkboxText: props.styleConfig.checkboxText,
                                            textField: props.styleConfig.textField,
                                            formError: props.styleConfig.formError,
                                            select: props.styleConfig.select,
                                        }}
                                    />
                                </Box>
                            </GridContent>
                            <GridContent>
                                <Box styleConfig={{ root: props.styleConfig.box }}>
                                    <PermitsForm
                                        currentForm={currentForm}
                                        validForms={validForms}
                                        blurredForms={blurredForms}
                                        application={application}
                                        onChange={(isValid, form) => {
                                            handleChange(isValid, FORM_PERMITS_NAME, form);
                                        }}
                                        onBlur={() => {
                                            handleBlur(FORM_PERMITS_NAME);
                                        }}
                                        onFocus={() => {
                                            handleFocus(FORM_PERMITS_NAME);
                                        }}
                                        styleConfig={{
                                            body: props.styleConfig.body,
                                            bodyLabel: props.styleConfig.bodyLabel,
                                            bodyTitle: props.styleConfig.bodyTitle,
                                            checkbox: props.styleConfig.checkbox,
                                            checkboxText: props.styleConfig.checkboxText,
                                            formError: props.styleConfig.formError,
                                            inlineBox: props.styleConfig.inlineBox,
                                        }}
                                    />
                                </Box>
                            </GridContent>
                            <GridContent>
                                <Box styleConfig={{ root: props.styleConfig.box }}>
                                    <WarningItem
                                        left={
                                            <WarningIcon>
                                                <Icon
                                                    icon={["fa", "exclamation-triangle"]}
                                                    size="2x"
                                                />
                                            </WarningIcon>
                                        }
                                        right={
                                            <Font
                                                styleConfig={{
                                                    root: props.styleConfig.bodyWarning,
                                                }}
                                            >
                                                Det här är en högkostnadskredit: Om du inte kan
                                                betala tillbaka hela skulden riskerar du en
                                                betalningsanmärkning. För stöd, vänd dig till
                                                budget- och skuldrådgivningen i din kommun.
                                                Kontaktuppgifter finns på hallåkonsument.se.
                                            </Font>
                                        }
                                    />
                                </Box>
                            </GridContent>
                            <GridContent>
                                <Box styleConfig={{ root: props.styleConfig.box }}>
                                    <ButtonField
                                        styleConfig={{
                                            root:
                                                validForms.length === APPLICATION_PAGE_FORMS
                                                    ? props.styleConfig.buttonSubmit
                                                    : props.styleConfig.buttonSubmitDisabled,
                                        }}
                                        onClick={() => {
                                            onSubmit();
                                        }}
                                    >
                                        <SubmitButtonIconContainer>
                                            <Icon icon={["fa", "arrow-circle-right"]} />
                                        </SubmitButtonIconContainer>
                                        <SubmitButtonTextContainer>
                                            Fortsätt med BankID
                                        </SubmitButtonTextContainer>
                                    </ButtonField>
                                    <ButtonField
                                        styleConfig={{ root: props.styleConfig.buttonCancel }}
                                        onClick={() => {
                                            window.location.href = process.env
                                                .REACT_APP_MARKETING_PAGE_URL as string;
                                        }}
                                    >
                                        Avsluta ansökan
                                    </ButtonField>
                                </Box>
                            </GridContent>
                        </FlexColumn>
                    </Grid>
                    <Grid width={3 / 10} position="sticky" top="0" height={[0, "520px"]}>
                        <SideBoxContainer>
                            <GridContent>
                                <Box styleConfig={{ root: props.styleConfig.sideBox }}>
                                    <SideContentApplication
                                        blurredForms={blurredForms}
                                        currentForm={currentForm}
                                        totalForms={APPLICATION_PAGE_FORMS}
                                        validForms={validForms}
                                        onSubmit={onSubmit}
                                        styleConfig={{
                                            bodyTitle: props.styleConfig.bodyTitle,
                                            sideTitle: props.styleConfig.sideTitle,
                                            sideLink: props.styleConfig.sideLink,
                                            buttonSubmit: props.styleConfig.buttonSubmit,
                                            buttonInfo: props.styleConfig.buttonInfo,
                                        }}
                                    />
                                </Box>
                            </GridContent>
                        </SideBoxContainer>
                    </Grid>
                </GridContainer>
            </FlexColumn>
        </Layout>
    );
}
