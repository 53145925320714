import { takeEvery, put, select, takeLatest } from "redux-saga/effects";
import { T_FeaturePageInitializerState, T_PageInitializerReducerState } from "../types";
import { pageInitializerActions } from "../actions";

export function* watchPageInitializers() {
    yield takeLatest("*", handlePageInitializer);
}

export function* handlePageInitializer(action: any): Generator {
    try {
        const pageInitializers = (yield select(
            (state: T_FeaturePageInitializerState) => state.pageInitializer
        )) as T_PageInitializerReducerState;

        const pageId = (yield select(
            (state: T_FeaturePageInitializerState) => state.pageInitializer.activePage
        )) as string;

        if (pageId) {
            const { actionToComplete, actionCompleted, pageInitialized } = pageInitializers[pageId];

            if (pageInitialized) return;

            if (actionToComplete.includes(action.type)) {
                yield put(
                    pageInitializerActions.completePageInitializerAction({
                        pageId,
                        actionComplete: action.type,
                    })
                );
            }

            const isArrayEqual =
                actionToComplete.sort().toString() === actionCompleted.sort().toString();

            if (actionCompleted.length > 0 && isArrayEqual) {
                yield put(pageInitializerActions.initPageSuccess({ pageId }));
            }
        }
    } catch (e) {
        console.log(e);
    }
}

/* export function* handlePageActionSuccess(action: any): Generator {} */
