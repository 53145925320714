import React, { ReactNode } from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
`;

const Left = styled.div`
    width: 40px;
    height: auto;
    margin: 16px 8px 0 0;
    margin-bottom: auto;
    padding: 0;
`;

const Right = styled.div`
    height: 100%;
    padding: 0;
    margin: 0;
    padding: 0;
`;

export type WarningItemProps = {
    left: ReactNode;
    right: ReactNode;
};

export function WarningItem(props: WarningItemProps) {
    return (
        <Container>
            <Left>{props.left}</Left>
            <Right>{props.right}</Right>
        </Container>
    );
}
