import React, { ReactNode } from "react";
import { Grid, GridContainer, GridContent, FullGridContainer } from "@opr-finance/component-grid";
import { Box } from "@opr-finance/component-box-container";
import { BaseLayout, GlobalStyles } from "./Layout.styled";
import { SystemStyleObject } from "@styled-system/css";
import { Font } from "@opr-finance/component-fonts";
import styled from "styled-components";

export type LayoutProps = {
    children: ReactNode;
    header: ReactNode;
};

export function Layout(props: LayoutProps) {
    return (
        <BaseLayout>
            <GlobalStyles />
            <FullGridContainer marginBottom={"40px"} marginTop={["0", "16px"]}>
                {props.header}
            </FullGridContainer>
            <GridContainer marginTop={["60px", "0"]}>{props.children}</GridContainer>
        </BaseLayout>
    );
}
