import { T_DataToEb } from "./types/types";

const redirect_url = process.env.REACT_APP_EB_REDIRECT_URL as string;

export function setDaysValid(daysValid) {
    return new Date(new Date().getTime() + daysValid * 24 * 60 * 60 * 1000).toISOString();
}

export function createDataObject(uuid, action): T_DataToEb {
    return {
        applicationData: action.payload.applicationData,
        uuid,
        auth: {
            access: {
                valid_until: setDaysValid(10),
            },
            aspsp: {
                name: action.payload.bankName,
                country: action.payload.bankCountry,
            },
            state: uuid,
            redirect_url,
            psu_type: "personal",
            language: "sv",
        },
    };
}
