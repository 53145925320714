import { createReducer } from "typesafe-actions";
import { produce } from "immer";
import { LoaderReducerState } from "../types/loader";
import { LoaderAction, loaderActions } from "../actions/loader";

const initialState: LoaderReducerState = {
    loading: true,
};

export const loaderReducer = createReducer<LoaderReducerState, LoaderAction>(
    initialState
).handleAction(loaderActions.loaderTrigger, (state, action) => {
    return produce(state, (draftState) => {
        draftState.loading = action.payload.loading;
    });
});
