export const PAGE_TITLE = "Låneansökan";

export const BREADCRUMB_STEP_APPLICATION = "Ansökan";
export const BREADCRUMB_STEP_AUTHENTICATION = "Verifiering";
export const BREADCRUMB_STEP_PREVIEW = "Avtal";
export const BREADCRUMB_STEP_CHOOSE_BANK = "Välj bank";
export const BREADCRUMB_STEP_THANK_YOU = "Slutförd ansökan";

export const BREADCRUMB_STEPS = [
    BREADCRUMB_STEP_APPLICATION,
    BREADCRUMB_STEP_AUTHENTICATION,
    BREADCRUMB_STEP_PREVIEW,
    BREADCRUMB_STEP_CHOOSE_BANK,
    BREADCRUMB_STEP_THANK_YOU,
];

export const TOTAL_FORMS = 7;
export const APPLICATION_PAGE_FORMS = 6;

export const FORM_CONTACT_NAME = "contact";
export const FORM_CONTACT_LABEL = "Dina uppgifter";

export const FORM_LIVING_NAME = "living";
export const FORM_LIVING_LABEL = "Ditt hushåll";

export const FORM_EMPLOYEMENT_NAME = "employement";
export const FORM_EMPLOYEMENT_LABEL = "Dina inkomster och utgifter";

export const FORM_PEP_NAME = "pep";
export const FORM_PEP_LABEL = "PEP & Syfte med lånet";

export const FORM_GUARANTOR_NAME = "guarantor";
export const FORM_GUARANTOR_LABEL = "Borgensåtaganden";

export const FORM_PERMITS_NAME = "permits";
export const FORM_PERMITS_LABEL = "Villkor";

export const FORM_CONTRACTS_NAME = "contract";
export const FORM_CONTRACTS_LABEL = "Avtal";

export const LOCAL_STORAGE_LFP_ERROR = "lfpError";
export const LOCAL_STORAGE_APPLICATION = "application";
export const LOCAL_STORAGE_USER_TOKEN = "user_token";
