import React, { useState, useEffect, useMemo, useRef } from "react";
import { SystemStyleObject } from "@styled-system/css";
import { add, remove, pickOff } from "@opr-finance/utils";
import { GenericError } from "./Generic/Error";
import { SelectOption } from "./Select/Select";
import { type } from "os";

export { ButtonField } from "./Button/Button";
export { CheckboxField } from "./Checkbox/Checkbox";
export { FieldSet } from "./FieldSet/FieldSet";
export { FileInput } from "./FileInput/FileInput";
export { LabeledField } from "./LabeledField/LabeledField";
export { OrderEnum } from "./LabeledField/types";
export { SelectField } from "./Select/Select";
export { TextField } from "./TextInput/TextInput.styled";
export { TextInput } from "./TextInput/TextInput";
export { DatePickerInput } from "./TextInput/DatePickerInput";

export type { T_DatePickerInputProps, T_DatePickerInputStyleProps } from "./TextInput/types";
export type { BaseStyleProps, ContainerProps, LabeledFieldProps } from "./LabeledField/types";
export type { FieldSetProps, DefaultFormProps } from "./FieldSet/types";
export type {
    FileInputFiedProps,
    StyledFileInputProps,
    FileInputFieldStyleProps,
    FileInputFieldConfigProps,
} from "./FileInput/types";
export type { SelectOption } from "./Select/Select";

export type FormError = {
    field: string;
    error: string;
};

export function getFieldErrors(errors: FormError[], field: string): string[] {
    return errors
        .filter((error) => {
            return error.field === field;
        })
        .map((e) => e.error);
}

export function isTouched(fields: string[], field: string): boolean {
    let value = false;
    fields.forEach((fieldName) => {
        if (fieldName === field) {
            value = true;
        }
    });
    return value;
}

export function option(value: string, text?: string): SelectOption {
    if (!text) text = value;
    return {
        label: text,
        value,
    };
}

export function getOption(options: SelectOption[], value: string): SelectOption {
    return options.reduce((accumulator: SelectOption, current: SelectOption) => {
        if (accumulator !== null && accumulator.value === value) return accumulator;
        return current;
    });
}

export function getOptionWithDefault(
    options: SelectOption[],
    value: string,
    defaultValue: SelectOption
): SelectOption {
    return options.find((option) => option.value === value) || defaultValue;
}

export type UseFormProps<T> = {
    initial: T;
    schema: any;
    onFocus?: (data: T) => void;
    onBlur?: (data: T) => void;
    onChange?: (data: T) => void;
    onError?: (data: T) => void;
    onSubmit?: (data: T) => void;
    styleConfig: {
        body?: SystemStyleObject;
        bodyTitle?: SystemStyleObject;
        textField?: SystemStyleObject;
        select?: SystemStyleObject;
        checkbox?: SystemStyleObject;
        checkboxText?: SystemStyleObject;
        button?: SystemStyleObject;
        buttonText?: SystemStyleObject;
        formError?: SystemStyleObject;
    };
};

export type ProcessFormProps<T> = {
    isSubmit?: boolean;
    validate?: boolean;
    field: keyof T;
    value: any;
    touched?: boolean;
    blurred?: boolean;
};

export type Data<T> = {
    validate: boolean;
    valid: boolean;
    submit: boolean;
    data: T;
    touched: string[];
    blurred: string[];
    errors: FormError[];
};

export type ErrorProps<T> = {
    field: keyof T;
};

export type TextFieldProps = {
    field: string;
    label: string;
};

export function useForm<T>(props: UseFormProps<T>) {
    const [form, setForm] = useState<Data<T>>({
        validate: false,
        valid: false,
        submit: false,
        data: props.initial,
        touched: [],
        blurred: [],
        errors: [],
    });

    useEffect(() => {
        if (form.validate === true) {
            handleForm();
        }
    }, [form.validate]);

    useEffect(() => {
        if (form.valid) {
            props.onChange?.(form.data);
        }
    }, [form.valid]);

    useEffect(() => {
        if (form.errors.length > 0) {
            props.onError?.(form.data);
        }
    }, [form.errors]);

    useEffect(() => {
        if (props.onFocus && form.touched.length > 0) {
            props.onFocus(form.data);
        }
    }, [form.touched]);

    useEffect(() => {
        if (props.onBlur && form.blurred.length > 0) {
            props.onBlur(form.data);
        }
    }, [form.blurred]);

    useEffect(() => {
        if (form.submit === true) {
            handleForm();
        }
    }, [form.submit]);

    async function initForm(newData: T) {
        setForm({
            validate: false,
            valid: false,
            submit: false,
            data: { ...form.data, ...newData },
            touched: [],
            blurred: [],
            errors: [],
        });
    }

    async function processReset() {
        setForm({
            validate: false,
            valid: false,
            submit: false,
            data: props.initial,
            touched: [],
            blurred: [],
            errors: [],
        });
        if (props.onChange) {
            props.onChange(form.data);
        }
    }

    async function handleForm() {
        try {
            await props.schema.validate(form.data, {
                abortEarly: false,
                strict: true,
                stripUnknown: true,
                recursive: true,
            });
            if (props.onSubmit && form.submit) {
                props.onSubmit(form.data);
            }

            if (form.submit) {
                setForm({
                    ...form,
                    validate: false,
                    valid: true,
                    errors: [],
                    submit: false,
                    touched: Object.keys(form.data),
                    blurred: Object.keys(form.data),
                });
                if (props.onChange) {
                    props.onChange(form.data);
                }
            } else {
                setForm({
                    ...form,
                    validate: false,
                    valid: true,
                    errors: [],
                    submit: false,
                });
                if (props.onChange) {
                    props.onChange(form.data);
                }
            }
        } catch (e: any) {
            if (!e.inner) {
                //console.log("got weird form error?", e);
                return;
            }
            const errors: FormError[] = e.inner.map((error) => {
                // !NOTE: solution to check only blurred field
                /* const fieldFound = form.blurred.find((field) => field === error.path);

                if (fieldFound)
                    return {
                        field: error.path,lear
                        error: error.errors[0],
                    };
                if (!fieldFound) return {}; */
                return {
                    field: error.path,
                    error: error.errors[0],
                };
            });
            //console.log("got errors from form", form, errors);
            if (form.submit) {
                setForm({
                    ...form,
                    validate: false,
                    valid: false,
                    errors,
                    submit: false,
                    touched: Object.keys(form.data),
                    blurred: Object.keys(form.data),
                });
            } else {
                setForm({
                    ...form,
                    validate: false,
                    valid: false,
                    errors,
                    submit: false,
                });
            }
        }
    }

    async function processFocus(field: string) {
        //console.log("%c process focus ", "color: #ffff00", field);
        const newTouched = remove<string>(form.touched, field, (a, b) => a === b);
        newTouched.push(field);
        setForm({
            ...form,
            touched: newTouched,
        });
    }

    async function processBlur(field: string) {
        // console.log("%c process blur ", "color: #ff0000", field);
        const newBlurred = remove<string>(form.blurred, field, (a, b) => a === b);
        newBlurred.push(field);
        setForm({
            ...form,
            validate: true,
            errors: pickOff(form.errors, (error: any) => error.field === field),
            blurred: newBlurred,
        });
    }

    async function processSubmit() {
        setForm({
            ...form,
            submit: true,
        });
    }

    async function processChange(processFormProps: ProcessFormProps<T>) {
        const { validate, field, value, touched, blurred, isSubmit } = processFormProps;

        const newData = {
            [field]: value,
        };

        let doValidation: boolean = false;
        let doAddTouched: boolean = false;
        let doAddBlurred: boolean = false;

        if (processFormProps.hasOwnProperty("validate") && validate === true) {
            doValidation = true;
        }

        if (processFormProps.hasOwnProperty("touched") && touched === true) {
            doAddTouched = true;
        }

        if (processFormProps.hasOwnProperty("blurred") && blurred === true) {
            doAddBlurred = true;
        }

        //console.log("%c process change", "color: #00ff00", form, field);

        setForm({
            ...form,
            data: { ...form.data, ...newData },
            validate: doValidation,
            touched: doAddTouched ? add(form.touched, field as string) : form.touched,
            blurred: doAddBlurred ? add(form.blurred, field as string) : form.blurred,
            errors: form.errors,
        });

        return;
    }

    function getValidationError(field: string): boolean {
        const errorFound = form.errors.findIndex((error) => error.field === field);
        const fieldBlurred = form.blurred.findIndex((blurred) => blurred === field);

        if (errorFound !== -1 && fieldBlurred !== -1) return true;
        return false;
    }

    function getValidationErrorMessage(field: string): string {
        const errorFound = form.errors.findIndex((error) => error.field === field);
        if (errorFound === -1) return "";
        return form.errors[errorFound].error;
    }

    function Error<T>(errorProps: ErrorProps<T>) {
        return (
            <GenericError
                errors={form.errors}
                touched={form.blurred}
                field={errorProps.field}
                styleConfig={{
                    root: props.styleConfig.formError,
                }}
            />
        );
    }

    return {
        Error,
        getValidationError,
        getValidationErrorMessage,
        initForm,
        processChange,
        processFocus,
        processBlur,
        processSubmit,
        form,
        processReset,
    };
}
