export function add<T>(list: T[], value: T): T[] {
    const cleaned: T[] = remove<T>(list, value, (a: T, b: T) => a === b);
    return [...cleaned, value];
}

export function remove<T>(list: T[], value: T, comparer: (listValue: T, value: T) => boolean): T[] {
    return list.filter((listValue: T) => {
        if (comparer(listValue, value)) {
            return false;
        } else {
            return true;
        }
    });
}

export function pickOff<T>(list: T[], comparer: (listValue: T) => boolean): T[] {
    return list.filter((listValue: T) => {
        if (comparer(listValue)) {
            return false;
        } else {
            return true;
        }
    });
}

export function pickOne<T>(list: T[], comparer: (listValue: T) => boolean): T {
    return list.reduce((accumulator: T, current: T) => {
        if (accumulator && comparer(accumulator)) {
            return accumulator;
        }
        return current;
    });
}
