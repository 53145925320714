import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { css } from "@styled-system/css";

import { StyledFieldSetProps, FormContainerProps } from "./types";

/**
 * Act as container for one part of form giving an id to it
 */
export const FormContainer: FunctionComponent<FormContainerProps> = styled.div<FormContainerProps>`
    ${(props: FormContainerProps) => props.styleConfig && css(props.styleConfig.container)};
`;

/**
 * Wraps one or several compoents into one form layer
 */
export const StyledFieldSet: FunctionComponent<StyledFieldSetProps> = styled.div<StyledFieldSetProps>`
    ${(props: StyledFieldSetProps) => props.styleConfig && css(props.styleConfig.form)};
`;
