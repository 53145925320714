import { addCentury } from "./addCentury";

export function extractBirthdateFromSsn(ssn: string | null): string {
    if (!ssn) {
        return "";
    }
    const dob = ssn.match(/.{1,2}/g);
    const century = ssn && ssn[6] === "-" ? 19 : 20;
    const dateOfBirth = `${century && century}${dob && dob[2]}-${dob && dob[1]}-${dob && dob[0]}`;
    return dateOfBirth;
}

export function extractBirthdateFromSsnSwe(ssn: string | null): string {
    if (!ssn) {
        return "";
    }
    let ssnWithoutDash: string;
    let year: string;
    let month: string;
    let day: string;

    if (ssn.includes("-")) {
        ssnWithoutDash = ssn.split("-").join("");
    } else {
        ssnWithoutDash = ssn;
    }
    if (ssnWithoutDash.length === 10) {
        year = addCentury(ssnWithoutDash).substring(0, 4);
        month = ssnWithoutDash.substring(2, 4);
        day = ssnWithoutDash.substring(4, 6);
    } else if (ssnWithoutDash.length === 12) {
        year = ssnWithoutDash.substring(0, 4);
        month = ssnWithoutDash.substring(4, 6);
        day = ssnWithoutDash.substring(6, 8);
    } else {
        return "";
    }
    return `${year}-${month}-${day}`;
}
