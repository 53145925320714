import React, { ReactNode } from "react";
import {
    BaseBox,
    BaseBoxProps,
    BaseBoxContainer,
    BaseBoxContainerProps,
} from "./BoxContainer.styled";
import { SystemStyleObject } from "@styled-system/css";

export type BoxContainerProps = {
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    children: ReactNode;
};

export const BoxContainer = (props: BoxContainerProps & BaseBoxContainerProps) => {
    return <BaseBoxContainer {...props}>{props.children}</BaseBoxContainer>;
};

export type BoxProps = {
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    children: ReactNode;
    styleConfig: {
        root: SystemStyleObject;
    };
};

export const Box = (props: BoxProps) => {
    return (
        <BaseBox onClick={props.onClick} styleConfig={props.styleConfig.root}>
            {props.children}
        </BaseBox>
    );
};
