import React from "react";

import { Box } from "@opr-finance/component-box-container";
import { Image, ImageContainer } from "@opr-finance/component-image";
import { T_EnableBankingBank } from "@opr-finance/feature-enable-banking";
import { SystemStyleObject } from "@styled-system/css";
import { Icon } from "@opr-finance/component-icon";
import styled from "styled-components";
import { Font } from "@opr-finance/component-fonts";

type T_FilteredBanksProps = {
    filteredBanks: T_EnableBankingBank[];
    onClickHandler: (name, country) => void;
    onClickHandlerSparbankerna: () => void;
    sparbankenIsChosen: boolean;
    focused: boolean;
    styleConfig: {
        banksContent: SystemStyleObject;
        banksImage: SystemStyleObject;
        bankName: SystemStyleObject;
        imageContainer: SystemStyleObject;
    };
};

const ArrowWrapper = styled.div`
    margin-left: 10px;
    margin-top: 10px;
`;

export const FilteredBanks = (props: T_FilteredBanksProps) => {
    return (
        <>
            {props.filteredBanks.map((bank) => (
                <Box
                    key={bank.name}
                    onClick={() => props.onClickHandler(bank.name, bank.country)}
                    styleConfig={{
                        root: props.styleConfig.banksContent,
                    }}
                >
                    <ImageContainer styleConfig={props.styleConfig.imageContainer}>
                        <Image
                            styleConfig={{
                                root: props.styleConfig.banksImage,
                            }}
                            imageAlt={bank.name}
                            imageSrc={bank.logo}
                        />
                    </ImageContainer>
                    <Font styleConfig={{ root: props.styleConfig.bankName }}>{bank.name}</Font>
                </Box>
            ))}
            {!props.sparbankenIsChosen && !props.focused && (
                <Box
                    key={"sparbankerna"}
                    onClick={() => props.onClickHandlerSparbankerna()}
                    styleConfig={{
                        root: props.styleConfig.banksContent,
                    }}
                >
                    Sparbankerna
                    <ArrowWrapper>
                        Se alla <Icon icon={["fa", "angle-double-right"]} />
                    </ArrowWrapper>
                </Box>
            )}
        </>
    );
};
