import { InitializerActionConstants } from "../types/initializer";
import { ActionType, createAction } from "typesafe-actions";

export type InitApplicationPayload = {
    initializers: string[];
};

export type InitCompleteInitializerPayload = {
    action: string;
};

export const initializerActions = {
    initApplication: createAction(
        InitializerActionConstants.INIT_APPLICATION
    )<InitApplicationPayload>(),
    completeInitializer: createAction(
        InitializerActionConstants.INIT_COMPLETE_INITIALIZER
    )<InitCompleteInitializerPayload>(),
    initApplicationSuccess: createAction(InitializerActionConstants.INIT_APPLICATION_SUCCESS)(),
};

export type InitializerAction = ActionType<typeof initializerActions>;
