import { SystemStyleObject, css } from "@styled-system/css";
import React, { FunctionComponent, ReactNode } from "react";
import styled from "styled-components";

type BaseImgProps = {
    styleConfig: SystemStyleObject;
    src: string;
    alt: string;
};

export type ImageProps = {
    imageAlt: string;
    imageSrc: string;
    styleConfig: {
        root: SystemStyleObject;
    };
};

const BaseImg: FunctionComponent<BaseImgProps> = styled.img<BaseImgProps>`
    ${(props: BaseImgProps) => {
        return css(props.styleConfig);
    }}
`;

export const Image = (props: ImageProps) => {
    return (
        <BaseImg styleConfig={props.styleConfig.root} src={props.imageSrc} alt={props.imageAlt} />
    );
};
