import { Font } from "@opr-finance/component-fonts";
import { ButtonField } from "@opr-finance/component-forms";
import { FlexColumn } from "@opr-finance/component-grid";
import { SystemStyleObject } from "@styled-system/css";
import React from "react";
import styled from "styled-components";
import {
    FORM_CONTACT_NAME,
    FORM_EMPLOYEMENT_LABEL,
    FORM_LIVING_NAME,
    FORM_CONTACT_LABEL,
    FORM_LIVING_LABEL,
    FORM_EMPLOYEMENT_NAME,
    FORM_PEP_NAME,
    FORM_PEP_LABEL,
    FORM_GUARANTOR_NAME,
    FORM_GUARANTOR_LABEL,
    FORM_PERMITS_NAME,
    FORM_PERMITS_LABEL,
    FORM_CONTRACTS_NAME,
    FORM_CONTRACTS_LABEL,
} from "../constants";
import { IsValidForm } from "./IsValidForm";
import { SideItem } from "./SideItem";
import { SideTitle } from "./SideTitle";

const TitleContainer = styled.div`
    display: flex;
    width: 200px;
`;

export type SideContentProps = {
    totalForms: number;
    currentForm: string;
    blurredForms: string[];
    validForms: string[];
    onSubmit: () => void;
    styleConfig: {
        bodyTitle: SystemStyleObject;
        sideTitle: SystemStyleObject;
        sideLink: SystemStyleObject;
        buttonSubmit: SystemStyleObject;
        buttonInfo: SystemStyleObject;
    };
};

export function SideContentPreview(props: SideContentProps) {
    return (
        <FlexColumn>
            <TitleContainer>
                <Font styleConfig={{ root: props.styleConfig.bodyTitle }}>
                    Avsnittet om låneansökan: {props.validForms.length} / {props.totalForms}
                </Font>
            </TitleContainer>
            <div>
                <Font styleConfig={{ root: props.styleConfig.sideTitle }}>
                    <SideItem
                        left={
                            <IsValidForm
                                currentForm={props.currentForm}
                                blurredForms={props.blurredForms}
                                validForms={props.validForms}
                                name={FORM_CONTACT_NAME}
                            />
                        }
                        right={
                            <SideTitle
                                form={FORM_CONTACT_NAME}
                                styleConfig={{
                                    link: props.styleConfig.sideLink,
                                }}
                            >
                                {FORM_CONTACT_LABEL}
                            </SideTitle>
                        }
                    />
                </Font>
            </div>
            <div>
                <Font styleConfig={{ root: props.styleConfig.sideTitle }}>
                    <SideItem
                        left={
                            <IsValidForm
                                currentForm={props.currentForm}
                                blurredForms={props.blurredForms}
                                validForms={props.validForms}
                                name={FORM_LIVING_NAME}
                            />
                        }
                        right={
                            <SideTitle
                                form={FORM_LIVING_NAME}
                                styleConfig={{
                                    link: props.styleConfig.sideLink,
                                }}
                            >
                                {FORM_LIVING_LABEL}
                            </SideTitle>
                        }
                    />
                </Font>
            </div>
            <div>
                <Font styleConfig={{ root: props.styleConfig.sideTitle }}>
                    <SideItem
                        left={
                            <IsValidForm
                                currentForm={props.currentForm}
                                blurredForms={props.blurredForms}
                                validForms={props.validForms}
                                name={FORM_EMPLOYEMENT_NAME}
                            />
                        }
                        right={
                            <SideTitle
                                form={FORM_EMPLOYEMENT_NAME}
                                styleConfig={{
                                    link: props.styleConfig.sideLink,
                                }}
                            >
                                {FORM_EMPLOYEMENT_LABEL}
                            </SideTitle>
                        }
                    />
                </Font>
            </div>
            <div>
                <Font styleConfig={{ root: props.styleConfig.sideTitle }}>
                    <SideItem
                        left={
                            <IsValidForm
                                currentForm={props.currentForm}
                                blurredForms={props.blurredForms}
                                validForms={props.validForms}
                                name={FORM_PEP_NAME}
                            />
                        }
                        right={
                            <SideTitle
                                form={FORM_PEP_NAME}
                                styleConfig={{
                                    link: props.styleConfig.sideLink,
                                }}
                            >
                                {FORM_PEP_LABEL}
                            </SideTitle>
                        }
                    />
                </Font>
            </div>
            <div>
                <Font styleConfig={{ root: props.styleConfig.sideTitle }}>
                    <SideItem
                        left={
                            <IsValidForm
                                currentForm={props.currentForm}
                                blurredForms={props.blurredForms}
                                validForms={props.validForms}
                                name={FORM_GUARANTOR_NAME}
                            />
                        }
                        right={
                            <SideTitle
                                form={FORM_GUARANTOR_NAME}
                                styleConfig={{
                                    link: props.styleConfig.sideLink,
                                }}
                            >
                                {FORM_GUARANTOR_LABEL}
                            </SideTitle>
                        }
                    />
                </Font>
            </div>
            <div>
                <Font styleConfig={{ root: props.styleConfig.sideTitle }}>
                    <SideItem
                        left={
                            <IsValidForm
                                currentForm={props.currentForm}
                                blurredForms={props.blurredForms}
                                validForms={props.validForms}
                                name={FORM_PERMITS_NAME}
                            />
                        }
                        right={
                            <SideTitle
                                form={FORM_PERMITS_NAME}
                                styleConfig={{
                                    link: props.styleConfig.sideLink,
                                }}
                            >
                                {FORM_PERMITS_LABEL}
                            </SideTitle>
                        }
                    />
                </Font>
            </div>
            <div>
                <Font styleConfig={{ root: props.styleConfig.sideTitle }}>
                    <SideItem
                        left={
                            <IsValidForm
                                currentForm={props.currentForm}
                                blurredForms={props.blurredForms}
                                validForms={props.validForms}
                                name={FORM_CONTRACTS_NAME}
                            />
                        }
                        right={
                            <SideTitle
                                form={FORM_CONTRACTS_NAME}
                                styleConfig={{
                                    link: props.styleConfig.sideLink,
                                }}
                            >
                                {FORM_CONTRACTS_LABEL}
                            </SideTitle>
                        }
                    />
                </Font>
            </div>
            <div>
                {props.validForms.length === props.totalForms && (
                    <ButtonField
                        styleConfig={{ root: props.styleConfig.buttonSubmit }}
                        onClick={() => {
                            props.onSubmit();
                        }}
                    >
                        Slutför ansökan
                    </ButtonField>
                )}
                {props.validForms.length !== props.totalForms && (
                    <ButtonField styleConfig={{ root: props.styleConfig.buttonInfo }}>
                        Fyll i saknad information för att fortsätta
                    </ButtonField>
                )}
            </div>
        </FlexColumn>
    );
}
