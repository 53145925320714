import React, { useEffect } from "react";
import {
    useForm,
    TextField,
    SelectOption,
    SelectField,
    option,
    getOption,
} from "@opr-finance/component-forms";
import { Font } from "@opr-finance/component-fonts";
import { Application, GuarantorFormData } from "../../types/general";
import * as yup from "yup";
import { FontStyleProps, InputStyleProps, pickOff } from "@opr-finance/utils";
import { Icon } from "@opr-finance/component-icon";
import { FormContainer } from "../FormContainer";
import { hasLocalApplication } from "../../util";
import { SystemStyleObject } from "@styled-system/css";
import { FieldItem } from "../FieldItem";
import { IsValidField } from "../IsValidField";
import { FORM_GUARANTOR_LABEL, FORM_GUARANTOR_NAME } from "../../constants";
import { IsValidTextField } from "../IsValidTextField";

export type GuarantorFormProps = {
    currentForm: string;
    blurredForms: string[];
    validForms: string[];
    application: Application;
    onChange: (isValid: boolean, data: GuarantorFormData) => void;
    onBlur: () => void;
    onFocus: () => void;
    styleConfig: {
        body: FontStyleProps;
        bodyLabel: SystemStyleObject;
        bodyTitle: FontStyleProps;
        checkbox: InputStyleProps;
        checkboxText: FontStyleProps;
        select: SystemStyleObject;
        formError: FontStyleProps;
        textField: InputStyleProps;
    };
};

const isMoreThanZero = (value: string): boolean => {
    if (!value) return true;
    const intValue = parseInt(value);
    return intValue > 0;
};

const isNumber = (value: string) => {
    if (!value) return true;
    const regexp = new RegExp("^\\d+$");
    return regexp.test(value);
};

export function GuarantorForm(props: GuarantorFormProps) {
    const options: SelectOption[] = [option("Välj"), option("Ja"), option("Nej")];

    const validOptions: string[] = pickOff(
        options,
        (option: SelectOption) => option.value === "Välj"
    ).map((option: SelectOption) => {
        return option.value;
    });

    const schema = yup.object().shape({
        isGuarantor: yup.string().oneOf(validOptions, "pakollinen kenttä"),
        guarantorAmount: yup.string().when("isGuarantor", {
            is: "Ja",
            then: yup
                .string()
                .test("guarantorAmount", "has to be higher than zero", isMoreThanZero)
                .test("guarantorAmount", "has to be only numbers", isNumber),
        }),
    });

    const { form, processChange, processFocus, processBlur, processSubmit } =
        useForm<GuarantorFormData>({
            initial: {
                isGuarantor: props.application.isGuarantor,
                guarantorAmount: props.application.guarantorAmount,
            },
            schema,
            onChange: (data) => {
                props.onChange(true, data);
            },
            onError: (data) => {
                props.onChange(false, data);
            },
            onFocus: () => {
                props.onFocus();
            },
            onBlur: () => {
                props.onBlur();
            },
            styleConfig: props.styleConfig,
        });

    useEffect(() => {
        if (!hasLocalApplication()) return;
        processSubmit();
    }, [processSubmit]);

    return (
        <FormContainer
            title={FORM_GUARANTOR_LABEL}
            blurredForms={props.blurredForms}
            validForms={props.validForms}
            currentForm={props.currentForm}
            name={FORM_GUARANTOR_NAME}
            tooltip={
                <>
                    Borgensåtagande innebär att en person ställer en garanti för någon annan. Du är
                    garant för att t ex ett lån eller en skuld ska återbetalas.
                </>
            }
            styleConfig={{
                title: props.styleConfig.bodyTitle,
            }}
        >
            <Font styleConfig={{ root: props.styleConfig.body }}>
                Har du signerat några personliga borgensåtaganden?  *
            </Font>
            <FieldItem
                left={
                    <SelectField
                        name="isGuarantor"
                        value={getOption(options, form.data.isGuarantor)}
                        icon={<Icon icon={["fa", "caret-down"]} size="1x" />}
                        inputConfig={{
                            select: {
                                value: form.data.isGuarantor,
                                onChange: (value) => {
                                    processChange({
                                        field: "isGuarantor",
                                        value: value?.value,
                                        validate: true,
                                        touched: true,
                                    });
                                },
                            },
                        }}
                        options={options}
                        styleConfig={{
                            select: props.styleConfig.select,
                        }}
                    />
                }
                right={<IsValidField form={form} field="isGuarantor" />}
            />
            {form.data.isGuarantor === "Ja" && (
                <>
                    <Font styleConfig={{ root: props.styleConfig.bodyLabel }}>
                        Vad uppgår det totala beloppet på de lån som du har signerat ett personligt
                        borgensåtagande till? *
                    </Font>
                    <FieldItem
                        left={
                            <TextField
                                inputConfig={{
                                    name: "guarantorAmount",
                                    value: form.data.guarantorAmount,
                                    placeholder: "",
                                    onFocus: () => {
                                        processFocus("guarantorAmount");
                                    },
                                    onChange: (e) => {
                                        processChange({
                                            field: "guarantorAmount",
                                            value: e.target.value,
                                        });
                                    },
                                    onBlur: () => {
                                        processBlur("guarantorAmount");
                                    },
                                }}
                                styleConfig={{ root: props.styleConfig.textField }}
                            />
                        }
                        right={<IsValidTextField form={form} field="guarantorAmount" />}
                    />
                </>
            )}
        </FormContainer>
    );
}
