import styled from "styled-components";
import {
    variant,
    layout,
    LayoutProps,
    space,
    SpaceProps,
    typography,
    TypographyProps,
} from "styled-system";
import { AppTheme, AppThemeProps } from "@opr-finance/themes";

export { Font } from "./Font";
export type { FontProps } from "./Font";

export type TextProps = {
    variant:
        | "body"
        | "bodyTitle"
        | "bold"
        | "contactPageBody"
        | "contactPageTitle"
        | "footerBody"
        | "footerTitle"
        | "formError"
        | "iconWrapper"
        | "largeButtonTitle"
        | "loanModalUserBody"
        | "loanModalUserTitle"
        | "mobileNavigationIconLink"
        | "mobileNavigationLinksDescription"
        | "mobileNavigationMoreLink"
        | "mobileNavigationTitle"
        | "pageTitle"
        | "pageTitleCentered"
        | "recentNewsTitle"
        | "small"
        | "smaller"
        | "smallerBold"
        | "userPageBody"
        | "userPageTitle";
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    listItem?: boolean;
} & LayoutProps &
    SpaceProps &
    TypographyProps;

export type TextAppThemeProps = AppThemeProps & TextProps;

const variants = (theme: AppTheme) => {
    // Defaults
    const txtBold = {
        fontFamily: theme.fonts.basic,
        color: theme.colors.text,
        fontWeight: "bold",
        margin: "0",
        padding: "0",
    };

    const txtNormal = {
        fontFamily: theme.fonts.basic,
        color: theme.colors.text,
        fontWeight: "normal",
        margin: `${theme.fontMargins[0]}`,
        padding: "0",
        lineHeight: "1.5",
    };

    return {
        body: {
            ...txtNormal,
            fontSize: theme.fontSizes[2],
        },
        bodyTitle: {
            ...txtBold,
            fontSize: theme.fontSizes[4],
            padding: `${theme.fontPaddings[5]} ${theme.fontPaddings[5]} ${theme.fontPaddings[3]} ${theme.fontPaddings[5]}`, //"24px 24px 19px 24px",
        },
        bold: {
            ...txtBold,
            fontSize: theme.fontSizes[2],
        },
        contactPageBody: {
            ...txtNormal,
            fontSize: theme.fontSizes[2],
            margin: `${theme.fontMargins[0]} ${theme.fontMargins[0]} ${theme.fontMargins[0]} ${theme.fontMargins[5]}`, //"0 0 0 24px",
            lineHeight: 1.5,
            ":last-of-type": {
                marginBottom: "20px",
            },
        },
        contactPageTitle: {
            ...txtBold,
            margin: `${theme.fontMargins[0]} ${theme.fontMargins[0]} ${theme.fontMargins[0]} ${theme.fontMargins[5]}`,
            fontSize: theme.fontSizes[2],
            lineHeight: 1.5,
        },
        footerBody: {
            ...txtNormal,
            fontSize: theme.footer.fontSizes[1],
            lineHeight: "1.5",
        },
        footerTitle: {
            ...txtBold,
            fontSize: theme.fontSizes[2],
            color: theme.colors.blue?.[2],
            marginBottom: [theme.fontMargins[5], theme.fontMargins[6]], // ["24px", "32px"],
        },
        formError: {
            ...txtNormal,
            color: theme.colors.error,
        },
        iconWrapper: {
            ...txtNormal,
            minWidth: "24px",
            alignItems: "center",
            display: "inline-flex",
            fontSize: theme.footer.fontSizes[0],
            lineHeight: "1.5",
        },
        largeButtonTitle: {
            ...txtBold,
            fontSize: theme.fontSizes[3],
            textAlign: "center",
        },
        loanModalUserBody: {
            ...txtNormal,
            fontSize: theme.fontSizes[1],
            padding: `${theme.fontPaddings[0]} ${theme.fontPaddings[0]} ${theme.fontPaddings[4]} ${theme.fontPaddings[5]}`, // "0 0 20px 24px",
        },
        loanModalUserTitle: {
            ...txtBold,
            fontSize: theme.fontSizes[1],
            padding: `${theme.fontPaddings[0]} ${theme.fontPaddings[0]} ${theme.fontPaddings[1]} ${theme.fontPaddings[5]}`, //"0 0 8px 24px",
        },
        mobileNavigationIconLink: {
            ...txtNormal,
            fontSize: theme.fontSizes[0],
            color: "#3e5058",
        },
        mobileNavigationLinksDescription: {
            ...txtNormal,
            fontSize: theme.fontSizes[1],
        },
        mobileNavigationMoreLink: {
            ...txtNormal,
            fontSize: theme.fontSizes[2],
        },
        mobileNavigationTitle: {
            ...txtBold,
            fontSize: theme.fontSizes[3],
        },
        pageTitle: {
            ...txtBold,
            fontSize: theme.fontSizes[6],
            padding: `${theme.fontPaddings[5]} ${theme.fontPaddings[4]}`, // "24px 22px",
        },
        pageTitleCentered: {
            ...txtBold,
            fontSize: theme.fontSizes[6], //32px
            padding: `${theme.fontPaddings[5]} ${theme.fontPaddings[4]}`, // 24px 22px
            textAlign: "center",
        },
        recentNewsTitle: {
            ...txtBold,
            fontSize: theme.fontSizes[3],
        },
        small: {
            ...txtNormal,
            fontSize: theme.fontSizes[0],
        },
        smaller: {
            ...txtNormal,
            fontSize: theme.fontSizes[1],
        },
        smallerBold: {
            ...txtBold,
            fontSize: theme.fontSizes[1],
        },
        userPageBody: {
            ...txtNormal,
            fontSize: theme.fontSizes[2],
            padding: `${theme.fontPaddings[0]} ${theme.fontPaddings[0]} ${theme.fontPaddings[3]} ${theme.fontPaddings[5]}`, // "0 0 20px 24px",
        },
        userPageTitle: {
            ...txtBold,
            fontSize: theme.fontSizes[2],
            padding: `${theme.fontPaddings[0]} ${theme.fontPaddings[0]} ${theme.fontPaddings[3]} ${theme.fontPaddings[5]}`,
        },
    };
};

export const Text = styled.p<TextProps>`
    ${(props: TextAppThemeProps) =>
        variant({
            variants: variants(props.theme),
        })}
    ${layout}
    ${space}
    ${typography}
    ${(props: TextAppThemeProps) =>
        props.listItem &&
        `
        &::before {
        content: "\\2022";
        color: ${props.theme.colors.tertiaryHover};
        margin-right: 8px;
    `}
`;

export const InnerText = styled.span<TextProps>`
    ${(props: AppThemeProps) =>
        variant({
            variants: variants(props.theme),
        })}
    ${layout}
    ${space}
    ${typography}
`;
