import { combineReducers } from "redux";
import { AppState } from "./types/general";
import { initializerReducer } from "@opr-finance/feature-initializer";
import { userReducer } from "@opr-finance/feature-luvittaja-login";
import { enableBankingReducer } from "@opr-finance/feature-enable-banking";

export const rootReducer = combineReducers<AppState>({
    initializer: initializerReducer,
    user: userReducer,
    enableBanking: enableBankingReducer,
});
