import { userLoginActions } from "@opr-finance/feature-luvittaja-login";
import { takeEvery, put } from "redux-saga/effects";
import { AppActionConstants } from "../actions/actions";

export function* watchApplicationPageTrigger() {
    yield takeEvery(AppActionConstants.APPLICATION_PAGE_TRIGGER, handleApplicationPageTrigger);
}

export function* handleApplicationPageTrigger() {
    try {
        const mock: boolean = process.env.REACT_APP_MOCK === "1" ? true : false;

        yield put(
            userLoginActions.loginInitializer({
                mockLogin: mock,
                loginUrl: process.env.REACT_APP_LOGIN_URL as string,
                verifyUrl: process.env.REACT_APP_LOGIN_VERIFY_URL as string,
                errorUrl: process.env.REACT_APP_LOGIN_ERROR_URL as string,
                successUrl: process.env.REACT_APP_LOGIN_SUCCESS_URL as string,
                logoutUrl: process.env.REACT_APP_LOGOUT_URL as string,
            })
        );
    } catch (e) {
        console.log("send application trigger failed");
    }
}
