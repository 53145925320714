export async function httpFaker<A, B>(url: string, method: string, data: A, response: B): Promise<B> {
    return new Promise((resolve) => {
        setTimeout(
            () => {
                console.log("MOCK: " + url, method, data, response);
                resolve(response);
            },
            100
        );
    });
}
