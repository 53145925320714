import { PhoneNumberUtil } from "google-libphonenumber";
const phoneUtil = PhoneNumberUtil.getInstance();

export function isValidPhoneNumberSe(value: string): boolean {
    if (!value) return true;
    const number = phoneUtil.parse(value, "SE");

    return number && phoneUtil.isValidNumberForRegion(number, "SE");
}

export function isValidSwedishBankAccount(value: string): boolean {
    if (!value) return false;
    const expr = /^[0-9]+[-]+[0-9]+$/;
    const reg = new RegExp(expr);
    const result = reg.exec(value);
    return result ? true : false;
}
