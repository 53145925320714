import { FinnishBusinessIds } from "finnish-business-ids";
import FinnishBankUtils from "finnish-bank-utils";
import { PhoneNumberUtil } from "google-libphonenumber";
const phoneUtil = PhoneNumberUtil.getInstance();

export function isValidFinnishCompanyId(value: string): boolean {
    if (!value) return true;
    return FinnishBusinessIds.isValidBusinessId(value);
}

export function isValidFinnishIban(value: string): boolean {
    if (!value) return true;
    return FinnishBankUtils.isValidFinnishIBAN(value);
}

export function isValidPhoneNumberFi(value: string): boolean {
    if (!value) return true;
    const number = phoneUtil.parse(value, "FI");

    return number && phoneUtil.isValidNumberForRegion(number, "FI");
}
