import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export function useQuery<T>() {
    const query = new URLSearchParams(useLocation<T>().search);
    let params: T | {} = {};
    query.forEach((v, k) => {
        params[k] = v;
    });

    return params as T;
}
