import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { userLoginActions } from "../actions/login";

export function Login() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(userLoginActions.loginComplete());
    }, []);

    return null;
}
