import React, { FunctionComponent } from "react";
import { BaseLogo, BaseLogoProps } from "./Logo.styled";

export type LogoProps = {
    logoSrc: string;
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
} & BaseLogoProps;

export const Logo: FunctionComponent<LogoProps> = ({ logoSrc, ...rest }) => {
    return (
        <BaseLogo {...rest}>
            <img src={logoSrc} alt="Logo" />
        </BaseLogo>
    );
};
