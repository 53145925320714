export enum LoaderActionConstants {
    LOADER_TRIGGER = "LOADER/TRIGGER",
}

export type LoaderPayload = {
    loading: boolean;
};

export type LoaderReducerState = {
    loading: boolean;
};

export type FeatureLoaderState = {
    loader: LoaderReducerState;
};
