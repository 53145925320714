export enum InitializerActionConstants {
    INIT_APPLICATION = "INITIALIZER/INIT_APPLICATION",
    INIT_COMPLETE_INITIALIZER = "INITIALIZER/INIT_COMPLETE_INITIALIZER",
    INIT_APPLICATION_SUCCESS = "INITIALIZER/INIT_APPLICATION_SUCCESS",
}

export type InitializerReducerState = {
    initializers: string[];
    initialized: string[];
};

export type FeatureInitializerState = {
    initializer: InitializerReducerState;
};

export type EqualsFunctionProps = {
    arrayA: Array<string>;
    arrayB: Array<string>;
};
