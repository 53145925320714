import css, { SystemStyleObject } from "@styled-system/css";
import styled from "styled-components";
import React, { ReactNode, useState } from "react";
import { Icon } from "@opr-finance/component-icon";
import { Font } from "@opr-finance/component-fonts";

export type HeaderProps = {
    logo: ReactNode;
    title: ReactNode;
    mobileNavigation?: ReactNode;
    styleConfig: {
        container: SystemStyleObject;
        content: SystemStyleObject;
        burgerText?: SystemStyleObject;
    };
};

const Container = styled.div<{
    styleConfig: SystemStyleObject;
}>`
    ${(props) => {
        return css(props.styleConfig);
    }}
    z-index: 3000000;
    @media (max-width: 786px) {
        position: fixed;
    }
`;

const Content = styled.div<{
    styleConfig: SystemStyleObject;
}>`
    ${(props) => {
        return css(props.styleConfig);
    }}
`;

const LogoContainer = styled.div``;

const TitleContainer = styled.div`
    display: flex;
    visibility: visible;
    @media (max-width: 786px) {
        display: none;
        visibility: hidden;
    }
`;

const BurgerContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    visibility: visible;
    margin: 0;
    cursor: pointer;
    @media (min-width: 786px) {
        display: none;
        visibility: hidden;
    }
    @media (max-width: 786px) {
        margin: 0 26px 0 0;
    }
`;

const BurgerIconContainer = styled.div`
    display: flex;
    padding: 0;
    margin: 0 0 6px 8px;
    cursor: pointer;
`;

const MobileNavigation = styled.div`
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000000;
`;

const MobileNavigationContent = styled.div`
    display: flex;
    height: 100%;
    max-width: 300px;
    width: 100%;
    background-color: #fff;
    padding: 70px 32px 24px 32px;
    box-shadow: 1px 0 6px 0 rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to top, rgba(235, 240, 251, 0.4), rgba(215, 210, 245, 0));
`;

const MobileNavigationBackground = styled.div`
    display: flex;
    flex: 1;
    height: 100%;
    background-color: #000;
    opacity: 0.6;
    z-index: 1000000;
    cursor: pointer;
`;

export function Header(props: HeaderProps) {
    const [isMobileNavOpen, setIsMobileNavOpen] = useState<boolean>(false);
    return (
        <>
            <Container styleConfig={props.styleConfig.container}>
                <Content styleConfig={props.styleConfig.content}>
                    <LogoContainer>{props.logo}</LogoContainer>
                    <TitleContainer>{props.title}</TitleContainer>
                    {props.mobileNavigation && (
                        <BurgerContainer
                            onClick={() => {
                                setIsMobileNavOpen(!isMobileNavOpen);
                            }}
                        >
                            <Font
                                styleConfig={{
                                    root: props.styleConfig.burgerText
                                        ? props.styleConfig.burgerText
                                        : {},
                                }}
                            >
                                Menu
                            </Font>
                            <BurgerIconContainer>
                                <Icon icon={["fas", "bars"]} color="#0c445c" />
                            </BurgerIconContainer>
                        </BurgerContainer>
                    )}
                </Content>
            </Container>
            {isMobileNavOpen && props.mobileNavigation && (
                <MobileNavigation>
                    <MobileNavigationContent>{props.mobileNavigation}</MobileNavigationContent>
                    <MobileNavigationBackground
                        onClick={(e) => {
                            e.preventDefault();
                            setIsMobileNavOpen(false);
                        }}
                    />
                </MobileNavigation>
            )}
        </>
    );
}
