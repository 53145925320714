import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { css } from "@styled-system/css";

import {
    FileInputFieldConfigProps,
    FileInputFieldStyleProps,
    FileInputFiedProps,
    StyledFileInputProps,
} from "./types";

export const StyledFileInput: FunctionComponent<StyledFileInputProps> = styled.input.attrs<StyledFileInputProps>(
    (props) => ({ type: "file" })
)`
    ${(props: StyledFileInputProps) => {
        return css(props.styleConfig);
    }};
`;
