import React from "react";

import { Box } from "@opr-finance/component-box-container";
import { Font } from "@opr-finance/component-fonts";
import { FlexColumn, Grid, GridContainer, GridContent } from "@opr-finance/component-grid";
import { Logo } from "@opr-finance/component-logo";
import { Layout } from "@opr-finance/layout-everyday-application";
import { SystemStyleObject } from "@styled-system/css";
import { Link } from "@opr-finance/component-link-to";

import { Header } from "../components/Header";
import { PAGE_TITLE } from "../constants";
import logo from "../images/logo.svg";

export type SubmitErrorPageProps = {
    styleConfig: {
        headerTitle: SystemStyleObject;
        headerContainer: SystemStyleObject;
        headerContent: SystemStyleObject;
        pageTitle: SystemStyleObject;
        bodyTitle: SystemStyleObject;
        body: SystemStyleObject;
        link: SystemStyleObject;
        breadcrumbTitle: SystemStyleObject;
        breadcrumbBox: SystemStyleObject;
        box: SystemStyleObject;
    };
};

export function SubmitErrorPage(props: SubmitErrorPageProps) {
    return (
        <Layout
            header={
                <Header
                    logo={
                        <Logo
                            logoSrc={logo}
                            width={165}
                            height={35}
                            onClick={() => {
                                window.location.href = process.env
                                    .REACT_APP_MARKETING_PAGE_URL as string;
                            }}
                        />
                    }
                    title={
                        <Font styleConfig={{ root: props.styleConfig.headerTitle }}>
                            {PAGE_TITLE}
                        </Font>
                    }
                    styleConfig={{
                        container: props.styleConfig.headerContainer,
                        content: props.styleConfig.headerContent,
                        burgerText: props.styleConfig.body,
                    }}
                />
            }
        >
            <FlexColumn>
                <GridContainer>
                    <Grid width={7 / 10}>
                        <GridContent>
                            <Box styleConfig={{ root: props.styleConfig.box }}>
                                <Font styleConfig={{ root: props.styleConfig.bodyTitle }}>
                                    Felaktigt kontonummer!
                                </Font>
                                <Font styleConfig={{ root: props.styleConfig.body }}>
                                    Du har angivit ett felaktigt kontonummer eller använt fel format
                                    när du angivit ditt kontonummer. Börjar ditt clearingnummer på 8
                                    ska du ange totalt 15 siffror. Den sista siffran i
                                    clearingnummer ska du skriva tillsammans med kontonumret. Tryck{" "}
                                    <Link
                                        href="/application"
                                        styleConfig={{ root: props.styleConfig.link }}
                                    >
                                        här
                                    </Link>{" "}
                                    för att testa på nytt.
                                </Font>
                            </Box>
                        </GridContent>
                    </Grid>
                </GridContainer>
            </FlexColumn>
        </Layout>
    );
}
