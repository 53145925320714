import { createReducer } from "typesafe-actions";
import { produce } from "immer";
import { PageInitializerAction, pageInitializerActions } from "../actions";
import { T_PageInitializerReducerState } from "../types";

export const initialState = {
    // startpage: {
    //     actionToComplete: [],
    //     actionCompleted: [],
    //     pageInitialized: false,
    // },
};

const addIfUnique = (arrayOfActions: Array<string>, actionToAdd: string): Array<string> => {
    if (arrayOfActions.indexOf(actionToAdd) === -1) return [...arrayOfActions, actionToAdd];
    return arrayOfActions;
};

export const pageInitializerReducer = createReducer<
    T_PageInitializerReducerState,
    PageInitializerAction
>(initialState)
    .handleAction(pageInitializerActions.initPageToPageInitializer, (state, action) => {
        return produce(state, (draftState) => {
            draftState = Object.assign(draftState, {
                [action.payload.pageToWatch]: {
                    actionCompleted: [],
                    actionToComplete: action.payload.actionToComplete,
                    pageInitialized: false,
                },
                activePage: action.payload.pageToWatch,
            });
        });
    })
    .handleAction(pageInitializerActions.completePageInitializerAction, (state, action) => {
        return produce(state, (draftState) => {
            const { pageId } = action.payload;
            draftState[pageId].actionCompleted = addIfUnique(
                draftState[pageId].actionCompleted,
                action.payload.actionComplete
            );
        });
    })
    .handleAction(pageInitializerActions.initPageSuccess, (state, action) => {
        return produce(state, (draftState) => {
            const { pageId } = action.payload;
            draftState[pageId].actionCompleted = [];
            draftState[pageId].pageInitialized = true;
        });
    })
    .handleAction(pageInitializerActions.resetPageInitializer, (state, action) => {
        return produce(state, (draftState) => {
            const { pageId } = action.payload;
            draftState[pageId].actionCompleted = [];
            draftState[pageId].pageInitialized = false;
        });
    });
