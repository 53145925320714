import React, { ReactNode } from "react";
import styled from "styled-components";
import { Link } from "@opr-finance/component-link-to";
import { SystemStyleObject } from "@styled-system/css";

const Container = styled.div`
    display: flex;
    padding: 2px 0 0 0;
    align-items: center;
    justify-content: center;
`;

export type SideTitleProps = {
    children: ReactNode;
    form: string;
    styleConfig: {
        link: SystemStyleObject;
    };
};

export function SideTitle(props: SideTitleProps) {
    return (
        <Container>
            <Link href={`#form-${props.form}`} styleConfig={{ root: props.styleConfig.link }}>
                {props.children}
            </Link>
        </Container>
    );
}
