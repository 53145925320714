import { createReducer } from "typesafe-actions";
import { produce } from "immer";

import { enableBankingActions, EnableBankingAction } from "../actions/index.actions";
import { T_EnableBankingInitializerState } from "../";

export const initialState: T_EnableBankingInitializerState = {
    config: {
        mockApiCalls: false,
        gwUrl: "",
        enableBankingError: false,
        earlyReturnFromFrends: {
            statusCode: null,
            status: "",
            message: "",
        },
    },
    entities: [],
};

export const enableBankingReducer = createReducer<
    T_EnableBankingInitializerState,
    EnableBankingAction
>(initialState)
    .handleAction(enableBankingActions.enableBankingInitializer, (state, action) => {
        return produce(state, (draftState) => {
            draftState.config.mockApiCalls = action.payload.mockApiCalls;
            draftState.config.gwUrl = action.payload.gwUrl;
        });
    })
    .handleAction(enableBankingActions.enableBankingGetBanksSuccess, (state, action) => {
        return produce(state, (draftState) => {
            draftState.entities = action.payload;
        });
    })
    .handleAction(enableBankingActions.sendDataToEnableBankingSuccess, (state, action) => {
        return produce(state, (draftState) => {
            draftState.config = action.payload;
            draftState.config.enableBankingError = false;
        });
    })
    .handleAction(enableBankingActions.sendDataToEnableBankingError, (state, action) => {
        return produce(state, (draftState) => {
            draftState.config.enableBankingError = true;
            draftState.config = action.payload;
        });
    })
    .handleAction(enableBankingActions.earlyReturnFromFrendsTrigger, (state, action) => {
        return produce(state, (draftState) => {
            draftState.config.earlyReturnFromFrends = action.payload;
        });
    });
