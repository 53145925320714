import * as Leandev from "@opr-finance/api-definitions";

type CredentialType = {
    name: string;
    title: string;
    required: boolean;
    description: string;
    template: string;
};

type AuthMethodTypes = {
    name: string;
    title: string;
    psu_type: string;
    credentials: CredentialType[];
    approach: string;
};

export type T_EnableBankingBank = {
    name: string;
    country: string;
    logo: string;
    psu_types: string[];
    auth_methods: AuthMethodTypes[];
    sandbox?: {
        users: [
            {
                username: string;
                password: string;
                otp: string;
            }
        ];
    };
};

export type T_DataToEb = {
    applicationData: Leandev.LoanApplicationRequest;
    bankName?: string;
    uuid: string;
    auth: {
        access: {
            valid_until: string;
        };
        aspsp: {
            name: string;
            country: string;
        };
        state: string;
        redirect_url: string;
        psu_type: string;
        language: string;
    };
};

export enum E_EnableBankingActionConstants {
    ENABLE_BANKING_INITIALIZER = "ENABLE/BANKING/INITIALIZER",
    ENABLE_BANKING_GET_BANKS_TRIGGER = "ENABLE/BANKING/GET/BANKS/TRIGGER",
    ENABLE_BANKING_GET_BANKS_SUCCESS = "ENABLE/BANKING/GET/BANKS/SUCCESS",
    ENABLE_BANKING_GET_BANKS_ERROR = "ENABLE/BANKING/GET/BANKS/ERROR",
    SEND_DATA_TO_ENABLE_BANKING_TRIGGER = "SEND_DATA_TO_ENABLE_BANKING_TRIGGER",
    SEND_DATA_TO_ENABLE_BANKING_SUCCESS = "SEND_DATA_TO_ENABLE_BANKING_SUCESS",
    SEND_DATA_TO_ENABLE_BANKING_ERROR = "SEND_DATA_TO_ENABLE_BANKING_ERROR",
    SEND_CODE_TO_FRENDS_TRIGGER = "SEND_CODE_TO_FRENDS_TRIGGER",
    SEND_APPLICATION_COMPLETED = "SEND_APPLICATION_COMPLETED",
    EARLY_RETURN_FROM_FRENDS_TRIGGER = "EARLY_RETURN_FROM_FRENDS_TRIGGER",
}

export type T_EarlyReturnFromFrends = {
    statusCode: number | null;
    status: string;
    message: string;
};

export type T_EnableBankingInitializerPayload = {
    mockApiCalls: boolean;
    gwUrl: string;
    url?: string;
    authorization_id?: string;
    enableBankingError?: boolean;
    earlyReturnFromFrends?: T_EarlyReturnFromFrends;
};

export type T_EnableBankingInitializerState = {
    entities: T_EnableBankingBank[];
    config: T_EnableBankingInitializerPayload;
};

export type T_FeatureEnableBankingState = {
    enableBanking: T_EnableBankingInitializerState;
};

export type T_EnableBankingRequest = T_EnableBankingInitializerPayload;
