import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Root } from "@opr-finance/feature-initializer/src/components/AppInitializer.styled";
import { Loader } from "@opr-finance/component-loader";

import { AppState } from "../types/general";

export function Sending() {
    const history = useHistory();
    const earlyReturnFromFrends = useSelector(
        (state: AppState) => state.enableBanking.config.earlyReturnFromFrends
    );

    useEffect(() => {
        if (earlyReturnFromFrends?.statusCode) {
            history.push("/completed", earlyReturnFromFrends);
        }
    }, [earlyReturnFromFrends]);

    return (
        <Root>
            <Loader isLoading={true} />
        </Root>
    );
}
